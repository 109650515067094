import {
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
} from '@mui/material';
import React from 'react';

export interface ContentMultiOptionProps {
  list: Array<string>;
  maxLimit?: number;
  selectedOptions: Array<string>;
  onChange?: (value: Array<string>) => void;
}

export const ContentMultiOptions = ({
  list = [],
  maxLimit = 2,
  selectedOptions = [],
  onChange = () => {},
}: ContentMultiOptionProps) => {
  const [selected, setSelected] = React.useState<string[]>([]);
  const [showOtherField, setShowOtherField] = React.useState(false);
  const [otherValue, setOtherValue] = React.useState('');

  React.useEffect(() => {
    setSelected(selectedOptions);
    if (selectedOptions?.some(value => !list?.some(item => item === value))) {
      setShowOtherField(true);
      setOtherValue(
        selectedOptions?.find(value => !list?.some(item => item === value))!
      );
    } else {
      setShowOtherField(false);
      setOtherValue('');
    }
  }, [selectedOptions, list]);

  const handleChanges = React.useCallback(
    (value: string) => {
      let newValues: string[] = [];
      const otherOptionSelected =
        list.findIndex(iValue => iValue === value) === list.length - 1;
      setShowOtherField(otherOptionSelected);

      if (selected?.includes(value)) {
        newValues = selected?.filter(iValue => iValue !== value);
      } else {
        if (selected?.includes(otherValue) && otherOptionSelected) {
          setOtherValue('');
          setShowOtherField(false);
          newValues = selected?.filter(item => item !== otherValue);
          onChange(newValues);
        } else {
          newValues =
            selected?.length > maxLimit - 1
              ? [...selected.slice(1), value]
              : [...selected, value];
        }
      }

      setSelected(newValues);

      if (!otherOptionSelected) {
        onChange(newValues);
      }
    },
    [list, selected, otherValue, onChange, maxLimit]
  );

  const handleOtherFieldChanges = React.useCallback(
    (value: string) => {
      let newValues: string[] = [];
      if (selected?.includes(otherValue)) {
        newValues = selected?.filter(iValue => iValue !== otherValue);
        setOtherValue('');
        setShowOtherField(false);
      } else {
        newValues = selected?.map(iValue =>
          iValue === list[list.length - 1] ? value : iValue
        );
      }
      setSelected(newValues);
      onChange(newValues);
    },
    [selected, otherValue, onChange, list]
  );

  return (
    <FormControl fullWidth>
      <FormControl>
        {list.map((item, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={
                  selected?.includes(item) ||
                  (index === list.length - 1 && !!otherValue)
                }
                onClick={() => handleChanges(item)}
              />
            }
            label={item}
            sx={{
              fontStyle: 'italic',
              span: { fontWeight: 500, fontSize: 14 },
            }}
          />
        ))}
        {showOtherField && (
          <TextField
            value={otherValue}
            sx={{ py: 1, width: '100%' }}
            onChange={e => setOtherValue(e.target.value)}
            onBlur={() => handleOtherFieldChanges(otherValue)}
          />
        )}
      </FormControl>
    </FormControl>
  );
};
