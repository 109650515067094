import { SxProps, Theme } from '@mui/material';
import {
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
  useState,
  useCallback,
  useRef,
} from 'react';
import { useReactToPrint } from 'react-to-print';
import { Project } from '../@types';

type MainLayoutContextType = {
  title?: string;
  loading?: boolean;
  updatingProject?: boolean;
  bannerContainerSx?: SxProps<Theme>;
  bannerChildren?: ReactNode;
  pageContainerSx?: SxProps<Theme>;
  pageTitleSx?: SxProps<Theme>;
  pageContentRef?: any;
  sharedProject?: Project;
  updateContextValue: (
    payload: Partial<Omit<MainLayoutContextType, 'updateContextValue'>>
  ) => void;
  downloadPDF: () => void;
};

const defaultMainLayoutContextValue: MainLayoutContextType = {
  updateContextValue: () => {},
  downloadPDF: () => {},
};

export const MainLayoutContext = createContext<MainLayoutContextType>(
  defaultMainLayoutContextValue
);

type MainLayoutContextProviderProps = PropsWithChildren<{}>;

export const MainLayoutContextProvider = ({
  children,
}: MainLayoutContextProviderProps) => {
  const [contextValue, setContextValue] = useState<MainLayoutContextType>(
    defaultMainLayoutContextValue
  );

  const componentRef = useRef<any>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: contextValue.title,
    bodyClass: 'pdf-container',
    pageStyle:
      '@page { margin-top: 0mm; } @media print { body { -webkit-print-color-adjust: exact; } }',
  });

  const downloadPDF = useCallback(() => {
    handlePrint();
  }, [handlePrint]);

  const updateContextValue = (
    payload: Partial<Omit<MainLayoutContextType, 'updateContextValue'>>
  ) => setContextValue(prev => ({ ...prev, ...payload }));

  return (
    <MainLayoutContext.Provider
      value={{
        ...contextValue,
        updateContextValue,
        downloadPDF,
        pageContentRef: componentRef,
      }}
    >
      {children}
    </MainLayoutContext.Provider>
  );
};

export const useMainLayoutContext = () => {
  const contextValue = useContext(MainLayoutContext);

  return { ...contextValue };
};
