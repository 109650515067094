import React, { useEffect } from 'react';
import { Grid, useTheme } from '@mui/material';

import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';
import { ExampleView } from './ExampleView';
import { Description } from './Description';
import { ContentInput } from '../../../components';
import { useProjectContext } from '../../../contexts';

const descriptionItems = [
  {
    text: `Specify the `,
  },
  {
    text: 'Community Need.',
    tooltip: `These are the need that led your program to address the problem, based on a community need assessment or trusted sources of data like TABS.`,
  },
];

const exampleTooltip = `1. Youth use rates for electronic cigs and hookah are at X%. This is higher than state average of X%. X% of Colorado youth say it is easy to purchase tobacco illegally. The last date for local tobacco retail compliance checks was two years ago (date) and we had five illegal sales.\n‎ \n2. In our community, the rate of pre-diabetes for adults who are Black/African-American is x%, higher than  all other racial and ethnic groups. In the past year, only # of people who are Black/African-American completed a diabetes prevention program.`;

export const Step1CreateProjectTheoryDiagram2Page = () => {
  const theme = useTheme();
  const { updateContextValue } = useMainLayoutContext();
  const {
    project,
    projectActions: { updateProject },
  } = useProjectContext();

  useEffect(() => {
    updateContextValue({
      title: 'Describe Community Need and Data Sources',
      pageContainerSx: { maxWidth: theme.breakpoints.values.md },
    });

    return () => {
      updateContextValue({ title: undefined, pageContainerSx: undefined });
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container spacing={4} sx={{ alignItems: 'flex-start' }}>
      <Grid item xs={12} sm={7} md={8} lg={9} container spacing={4}>
        <Grid item xs={12}>
          <Description items={descriptionItems} />
        </Grid>

        <Grid item xs={12}>
          <ContentInput
            label={`2a. In four sentences or less, make your case stronger and more specific by referencing the community need that influences or impacts the problem.`}
            dialogTitle="Community Need"
            content={project?.community_needs}
            handleSubmit={value =>
              updateProject({ id: project?.id, community_needs: value })
            }
          />
        </Grid>

        <Grid item xs={12}>
          <ContentInput
            content={project?.community_need_specifics}
            label={`2b. List at least three data sources (such as a community need assessment, state, regional, or local data findings, etc.) you plan to use to make the case.`}
            dialogTitle="Community Need Specifics"
            handleSubmit={value =>
              updateProject({
                id: project?.id,
                community_need_specifics: value,
              })
            }
          />
        </Grid>
      </Grid>

      <Grid item xs={12} sm={5} md={4} lg={3}>
        <ExampleView tooltipTitle={exampleTooltip} />
      </Grid>
    </Grid>
  );
};
