import { Grid, SxProps, Typography, useTheme } from '@mui/material';
import React from 'react';

import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';
import { ContentInput, PDFButton } from '../../../components';
import { useProjectContext } from '../../../contexts';

const textStyle: SxProps = {
  textAlign: 'center',
  fontSize: { xs: 10, sm: 14 },
  fontWeight: 600,
};

const cellBorder: SxProps = {
  borderWidth: 2,
  borderColor: 'lightgray',
  borderStyle: 'solid',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const contentInputStyle: SxProps = {
  bgcolor: 'transparent',
  boxShadow: 'none',
  px: 1,
  height: '100%',
};

export const Step2ProjectFlowDiagramForProjectPage = () => {
  const theme = useTheme();
  const { updateContextValue, downloadPDF } = useMainLayoutContext();
  const {
    project,
    projectActions: { updateProject },
  } = useProjectContext();

  React.useEffect(() => {
    updateContextValue({
      title: `The Completed Project Flow Diagram ${
        project?.name || '<Project Name>'
      }`,
      pageContainerSx: { maxWidth: theme.breakpoints.values.md },
    });

    return () => {
      updateContextValue({ title: undefined, pageContainerSx: undefined });
    };
    // eslint-disable-next-line
  }, [project?.name]);

  return (
    <Grid container justifyContent="center">
      <Grid container item xs={12} sx={{ ...cellBorder }}>
        <Grid container item bgcolor="#8cc63f" sx={{ height: 60 }}>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <Typography sx={{ ...textStyle }}>Inputs</Typography>
          </Grid>
          <Grid item xs={4} sx={{ ...cellBorder }}>
            <Typography sx={{ ...textStyle }}>Outputs</Typography>
          </Grid>
          <Grid item xs={6} sx={{ ...cellBorder }}>
            <Typography sx={{ ...textStyle }}>Outcomes</Typography>
          </Grid>
        </Grid>
        <Grid container item bgcolor="#8cc63f" sx={{ height: 60 }}>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <Typography sx={{ ...textStyle }}>Resources</Typography>
          </Grid>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <Typography sx={{ ...textStyle }}>Activities</Typography>
          </Grid>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <Typography sx={{ ...textStyle }}>Participants</Typography>
          </Grid>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <Typography sx={{ ...textStyle }}>
              Short-term
              <br />
              (3 to 12 months)
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <Typography sx={{ ...textStyle }}>
              Intermediate
              <br />
              (12 to 24 months)
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <Typography sx={{ ...textStyle }}>
              Long-term
              <br />
              (24+ months)
            </Typography>
          </Grid>
        </Grid>
        <Grid container item bgcolor="white" sx={{ minHeight: 200 }}>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <ContentInput
              content={project?.resources}
              styles={{
                contentViewer: { container: contentInputStyle },
              }}
              handleSubmit={value =>
                updateProject({
                  id: project?.id,
                  resources: value,
                })
              }
            />
          </Grid>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <ContentInput
              content={project?.activities}
              styles={{
                contentViewer: { container: contentInputStyle },
              }}
              handleSubmit={value =>
                updateProject({
                  id: project?.id,
                  activities: value,
                })
              }
            />
          </Grid>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <ContentInput
              content={project?.participants}
              styles={{
                contentViewer: { container: contentInputStyle },
              }}
              handleSubmit={value =>
                updateProject({
                  id: project?.id,
                  participants: value,
                })
              }
            />
          </Grid>
          <Grid item xs={2} bgcolor="#f2c312" sx={{ ...cellBorder }}>
            <ContentInput
              content={project?.short_term_goal}
              editable={false}
              styles={{
                contentViewer: { container: contentInputStyle },
              }}
            />
          </Grid>
          <Grid item xs={2} bgcolor="#f2c312" sx={{ ...cellBorder }}>
            <ContentInput
              content={project?.intermediate_term_goal}
              editable={false}
              styles={{
                contentViewer: { container: contentInputStyle },
              }}
            />
          </Grid>
          <Grid item xs={2} bgcolor="#f2c312" sx={{ ...cellBorder }}>
            <ContentInput
              content={project?.long_term_goal}
              editable={false}
              styles={{
                contentViewer: { container: contentInputStyle },
              }}
            />
          </Grid>
        </Grid>
        <Grid container item bgcolor="#f2c312" sx={{ minHeight: 200 }}>
          <Grid
            container
            item
            xs={6}
            sx={{ ...cellBorder, pt: 2, alignItems: 'flex-start' }}
          >
            <Grid item xs={12}>
              <Typography sx={{ ...textStyle }}>
                Intervention Strategy(ies)
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ContentInput
                content={project?.intervention_strategy}
                editable={false}
                styles={{
                  contentViewer: { container: contentInputStyle },
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={6}
            sx={{ ...cellBorder, pt: 2, alignItems: 'flex-start' }}
          >
            <Grid item xs={12}>
              <Typography sx={{ ...textStyle }}>Influential Factors</Typography>
            </Grid>
            <Grid item xs={12}>
              <ContentInput
                content={project?.influential_factors}
                editable={false}
                styles={{
                  contentViewer: { container: contentInputStyle },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} textAlign="right" sx={{ mt: 2 }} className="pdf-hide">
        <PDFButton onClick={downloadPDF} />
      </Grid>
    </Grid>
  );
};
