import { toast } from 'react-toastify';

export const showSuccessMessage = (message: string) => {
  toast.success(message, {
    position: 'top-right',
    theme: 'colored',
    hideProgressBar: true,
    autoClose: 3000,
  });
};

export const showInfoMessage = (message: string) => {
  toast.info(message, {
    position: 'top-right',
    hideProgressBar: true,
    theme: 'dark',
    autoClose: 3000,
  });
};

export const showErrorMessage = (message: string) => {
  toast.error(message, {
    position: 'top-right',
    hideProgressBar: true,
    theme: 'dark',
    autoClose: 3000,
  });
};
