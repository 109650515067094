import React, { useEffect } from 'react';
import { Box, useTheme } from '@mui/material';
import Image from 'mui-image';

import step1ProjectTheoryDiagram1Png from '../../../assets/images/pngs/step1_project_theory_diagram_1.png';
import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';

export const Step1ProjectTheoryDiagram1Page = () => {
  const theme = useTheme();
  const { updateContextValue } = useMainLayoutContext();

  useEffect(() => {
    updateContextValue({
      title: 'Before You Begin the Journey',
      pageContainerSx: { maxWidth: theme.breakpoints.values.md },
    });

    return () => {
      updateContextValue({ title: undefined, pageContainerSx: undefined });
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Box sx={{ py: 6 }}>
      <Image
        src={step1ProjectTheoryDiagram1Png}
        style={{ objectFit: 'contain', maxWidth: 420 }}
        duration={500}
      />
    </Box>
  );
};
