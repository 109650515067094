import React, { useEffect } from 'react';
import { Box, Button, Grid, Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import homeBannerPng from '../assets/images/pngs/home_banner.png';
import { ROUTES } from '../consts';
import { useMainLayoutContext } from '../layouts/MainLayoutContext';
import { useAuth } from '../contexts';

const linkProps: any = {
  component: 'span',
  underline: 'hover',
  sx: { cursor: 'pointer', fontWeight: 'bold' },
};

export const HomePage = () => {
  const navigate = useNavigate();
  const { updateContextValue } = useMainLayoutContext();
  const {
    values: { loggedIn },
  } = useAuth();

  useEffect(() => {
    updateContextValue({
      bannerContainerSx: {
        backgroundImage: `url(${homeBannerPng})`,
        backgroundSize: 'cover',
        backgroundPosition: 'bottom center',
        height: 320,
      },
      bannerChildren: (
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: 36,
            color: 'white',
            fontWeight: 900,
            textShadow: '-2px 0 1px #000000',
            letterSpacing: 4,
          }}
        >
          PROJECT PLANNING
          <br />& EVALUATION
        </Typography>
      ),
    });

    return () => {
      updateContextValue({
        bannerContainerSx: undefined,
        bannerChildren: undefined,
      });
    };
    // eslint-disable-next-line
  }, []);

  if (loggedIn) {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography color="error" fontWeight={600} variant="h6">
            Let's Get Started!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Whether this is your first time visiting this module, or a repeat
            visit, the goal is to help you successfully navigate evaluation
            planning. Click on{' '}
            <Link
              {...linkProps}
              onClick={() =>
                navigate(
                  `/${ROUTES.PROJECTS}/${ROUTES.INTRO}/${ROUTES.MODULE_OBJECTIVES}`
                )
              }
            >
              CREATE NEW PROJECT
            </Link>{' '}
            in the navigation bar above to start an evaluation plan for a new
            project.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Click on{' '}
            <Link
              {...linkProps}
              onClick={() => navigate(`/${ROUTES.PROJECTS}`)}
            >
              MY PROJECTS
            </Link>{' '}
            to revisit a plan that you’ve already completed.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Along the way, remember to click on{' '}
            <Link
              {...linkProps}
              onClick={() => navigate(`/${ROUTES.TA_CONTACT}`)}
            >
              TA CONTACT
            </Link>{' '}
            to find out who to reach out to for assistance and support.
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography>
          Welcome to this online training module designed to help you plan a
          project and design an evaluation easily and successfully. The module
          will guide you in a 3-step process where you will:
        </Typography>

        <Box sx={{ mt: 2 }}>
          <Typography>{`1) Describe your project and its foundation.`}</Typography>
          <Typography>{`2) Organize your project’s approach and flow.`}</Typography>
          <Typography>{`3) Plan your project’s process and outcome evaluation.`}</Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={() => navigate(`/${ROUTES.AUTH}/${ROUTES.SIGNUP}`)}
        >
          Create an online profile to get started
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Typography sx={{ fontStyle: 'italic' }}>
          Already Registered?
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={() => navigate(`/${ROUTES.AUTH}/${ROUTES.LOGIN}`)}
        >
          Login Here
        </Button>
      </Grid>
    </Grid>
  );
};
