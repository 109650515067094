import React from 'react';
import { CssBaseline } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';

import {
  ThemeContextProvider,
  AuthContextProvider,
  SettingsContextProvider,
} from './contexts';
import { RootNavigator } from './navigators';

export const App = () => {
  return (
    <ThemeContextProvider>
      <BrowserRouter>
        <SettingsContextProvider>
          <AuthContextProvider>
            <CssBaseline />
            <RootNavigator />
            <ToastContainer />
          </AuthContextProvider>
        </SettingsContextProvider>
      </BrowserRouter>
    </ThemeContextProvider>
  );
};
