import React from 'react';
import { Button, Grid, TextField } from '@mui/material';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { HelpFormDto } from '../@types';
import { useForm } from 'react-hook-form';
import { useAuth } from '../contexts';

export const TechnicalIssueForm = () => {
  const resolver = classValidatorResolver(HelpFormDto);
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<HelpFormDto>({
    resolver,
    defaultValues: {
      name: user ? `${user?.first_name} ${user?.last_name}` : '',
      email: '',
      description: '',
    },
  });

  const onSubmit = React.useCallback((data: HelpFormDto) => {
    console.log(data);
  }, []);

  return (
    <Grid
      container
      spacing={4}
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Name"
          required
          error={!!errors.name}
          helperText={errors.name?.message}
          {...register('name')}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Email"
          required
          error={!!errors.email}
          helperText={errors.email?.message}
          {...register('email')}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Please describe the technical issue you are experiencing:"
          multiline
          required
          rows={5}
          error={!!errors.description}
          helperText={errors.description?.message}
          {...register('description')}
        />
      </Grid>

      <Grid item xs={12}>
        <Button variant="contained" type="submit">
          Submit
        </Button>
      </Grid>
    </Grid>
  );
};
