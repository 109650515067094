import { Parameters, Question } from '../@types';

export const userNameRegex =
  /^(?=.{3,20}$)(?:[a-zA-Z\d]+(?:(?:\.|-|_)[a-zA-Z\d])*)+$/;

export const mapParameters = (data: any = {}): Parameters => {
  const { items = {} } = data;
  const user_occupation =
    Object.keys(items?.user_occupation)?.map(key => ({
      id: Number(key),
      label: items?.user_occupation[key],
    })) || [];
  const user_skill_level =
    Object.keys(items?.user_skill_level)?.map(key => ({
      id: Number(key),
      label: items?.user_skill_level[key],
    })) || [];
  return {
    user_occupation,
    user_skill_level,
  };
};

export const mapQuestions = (data: any = {}): Question[] => {
  const { items = {} } = data;
  return (
    Object.values(items).map((item: any) => ({
      ...item,
      custom_data:
        Object.keys(item?.custom_data || {})
          ?.filter(key => !!item?.custom_data && !!item?.custom_data[key])
          .map((key: any) => ({ id: key, label: item?.custom_data[key] })) ||
        [],
    })) || []
  );
};
