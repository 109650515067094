import React from 'react';
import { LoadingView } from '../components';
import bodyBgJpeg from '../assets/images/jpegs/body_bg.jpeg';
import { Box, Container, Typography } from '@mui/material';
import { Header } from '../layouts/Header';
import { HEADER_HEIGHT } from '../layouts/consts';
import homeBannerPng from '../assets/images/pngs/home_banner.png';

export const InitialLoadingPage = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{
        backgroundImage: `url(${bodyBgJpeg})`,
        backgroundRepeat: 'repeat',
      }}
    >
      <Header />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mx: { xs: -2, sm: -3 },
          mt: `${HEADER_HEIGHT}px`,
          px: 2,
          bgcolor: '#4f77b2',
          backgroundImage: `url(${homeBannerPng})`,
          backgroundSize: 'cover',
          backgroundPosition: 'bottom center',
          height: 320,
        }}
      >
        <Container
          maxWidth="md"
          sx={{ p: { xs: 0 }, display: 'flex', alignItems: 'center' }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography
              sx={{
                textAlign: 'center',
                fontSize: 36,
                color: 'white',
                fontWeight: 900,
                textShadow: '-2px 0 1px #000000',
                letterSpacing: 4,
              }}
            >
              PROJECT PLANNING
              <br />& EVALUATION
            </Typography>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          height: `calc(100vh - ${HEADER_HEIGHT + 320}px)`,
        }}
      />
      <LoadingView
        visible
        size={40}
        styles={{
          container: { backgroundColor: '#00000040', zIndex: 999999999 },
          circle: { color: 'primary' },
        }}
      />
    </Container>
  );
};
