import React, { useState } from 'react';
import { Drawer, IconButton } from '@mui/material';
import { Menu } from '@mui/icons-material';

import { DrawerContent } from './DrawerContent';
import { navConfig } from './navConfig';
import { NAV } from './config';
import { useParams } from 'react-router-dom';

export const HamburgerMenu = () => {
  const { id: projectId } = useParams();
  const [visible, setVisible] = useState<boolean>(false);
  if (!projectId) {
    return null;
  }

  return (
    <>
      <IconButton
        edge="start"
        sx={{ color: 'white' }}
        onClick={() => setVisible(true)}
      >
        <Menu sx={{ width: 32, height: 32 }} />
      </IconButton>

      <Drawer
        anchor="left"
        open={visible}
        onClose={() => setVisible(false)}
        PaperProps={{ sx: { pt: 8, pb: 4, width: NAV.W_DASHBOARD } }}
      >
        <DrawerContent data={navConfig(Number(projectId))} />
      </Drawer>
    </>
  );
};
