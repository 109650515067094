import { SxProps } from '@mui/material';

export const plainInput: SxProps = {
  bgcolor: 'transparent',
  boxShadow: 'none',
  px: 1,
  height: '100%',
};

export const tableContentText: SxProps = {
  fontWeight: 600,
  fontSize: 12,
  p: 1,
};
