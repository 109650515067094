export const EVALUATION_QUESTIONS = [
  {
    highlight: 'Process',
    color: 'red',
    questionType: 'Resources',
    questionOptionField: 'question_resources',
    options: [
      {
        value: 0,
        label:
          'Do staff have the necessary skills to meet the needs of our participants?',
      },
      {
        value: 1,
        label: 'Will not evaluate as part of this project',
        bold: true,
      },
      {
        value: 2,
        label: 'Create my own question',
      },
    ],
  },
  {
    highlight: 'Process',
    color: 'red',
    questionType: 'Activities',
    questionOptionField: 'question_activities',
    options: [
      {
        value: 0,
        label: 'Are our activities relevant to our outcomes?',
      },
      {
        value: 1,
        label:
          'Are we conducting activities at the right location, on the right day, and the right time of day?',
      },
      {
        value: 2,
        label: 'Will not evaluate as part of this project',
        bold: true,
      },
      {
        value: 3,
        label: 'Create my own question',
      },
    ],
  },
  {
    highlight: 'Process',
    color: 'red',
    questionType: 'Participants',
    questionOptionField: 'question_participants',
    options: [
      {
        value: 0,
        label: 'Are we reaching the intended participants with our activities?',
      },
      {
        value: 1,
        label:
          'Are our actual participants the ones we should be reaching or engaging or targeting?',
      },
      {
        value: 2,
        label: 'Will not evaluate as part of this project',
        bold: true,
      },
      {
        value: 3,
        label: 'Create my own question',
      },
    ],
  },
  {
    highlight: 'Outcome',
    color: 'blue',
    questionType: 'Short-term',
    questionOptionField: 'question_short_term',
    options: [
      {
        value: 0,
        label:
          "Was there any change in participants' attitudes, beliefs, or knowledge as a results of the project?",
      },
      {
        value: 1,
        label: 'Will not evaluate as part of this project',
        bold: true,
      },
      {
        value: 2,
        label: 'Create my own question',
      },
    ],
  },
  {
    highlight: 'Outcome',
    color: 'blue',
    questionType: 'Intermediate',
    questionOptionField: 'question_intermediate',
    options: [
      {
        value: 0,
        label:
          "Was there any change in participants' risk or protective behaviors?",
      },
      {
        value: 1,
        label:
          'Has a new public policy or organizational regulation been adopted?',
      },
      {
        value: 2,
        label:
          'Is the new public policy or organizational regulation being implemented as intended?',
      },
      {
        value: 3,
        label: 'Will not evaluate as part of this project',
        bold: true,
      },
      {
        value: 4,
        label: 'Create my own question',
      },
    ],
  },
  {
    highlight: 'Outcome',
    color: 'blue',
    questionType: 'Long-term',
    questionOptionField: 'question_long_term',
    options: [
      {
        value: 0,
        label: 'Was there any change in prevalence of disease?',
      },
      {
        value: 1,
        label:
          'Is behavior changing in response to systems change or policy implementation?',
      },
      {
        value: 2,
        label: 'Will not evaluate as part of this project',
        bold: true,
      },
      {
        value: 3,
        label: 'Create my own question',
      },
    ],
  },
];

export const EVALUATION_TABLE_FIELDS = [
  {
    label: '',
    projectFieldName: 'question_resources',
    editable: false,
  },
  {
    label: '',
    projectFieldName: 'question_activities',
    editable: false,
  },
  { label: '', projectFieldName: 'question_participants', editable: false },
  { label: '', projectFieldName: 'question_short_term', editable: false },
  {
    label: '',
    projectFieldName: 'question_intermediate',
    editable: false,
  },
  {
    label: '',
    projectFieldName: 'question_long_term',
    editable: false,
  },
];

export const INDICATOR_TABLE_FIELDS = [
  {
    label: 'Change indicators (Resources)',
    projectFieldName: 'indicator_resources',
    editable: true,
  },
  {
    label: 'Change indicators (Activities)',
    projectFieldName: 'indicator_activities',
    editable: true,
  },
  {
    label: 'Change indicators (Participants)',
    projectFieldName: 'indicator_participants',
    editable: true,
  },
  {
    label: 'Change indicators (Short-term)',
    projectFieldName: 'indicator_short_term',
    editable: true,
  },
  {
    label: 'Change indicators (Intermediate-term)',
    projectFieldName: 'indicator_intermediate_term',
    editable: true,
  },
  {
    label: 'Change indicators (Long-term)',
    projectFieldName: 'indicator_long_term',
    editable: true,
  },
];

export const COLLECT_DATA_TABLE_FIELDS = [
  { label: '', projectFieldName: 'data_resources', editable: false },
  { label: '', projectFieldName: 'data_activities', editable: false },
  { label: '', projectFieldName: 'data_participants', editable: false },
  { label: '', projectFieldName: 'data_short_term', editable: false },
  { label: '', projectFieldName: 'data_intermediate_term', editable: false },
  { label: '', projectFieldName: 'data_long_term', editable: false },
];

export const MEASURE_TABLE_FIELDS = [
  { label: '', projectFieldName: 'timing_resources', editable: true },
  { label: '', projectFieldName: 'timing_activities', editable: true },
  { label: '', projectFieldName: 'timing_participants', editable: false },
  { label: '', projectFieldName: 'timing_short_term', editable: false },
  { label: '', projectFieldName: 'timing_intermediate_term', editable: false },
  { label: '', projectFieldName: 'timing_long_term', editable: false },
];

export const EVALUATION_FINDINGS = [
  'To identify areas of the program that need improvement',
  'To decide how to allocate resources',
  'To mobilize community support',
  'To redistribute or expand the locations where the intervention is carried out',
  "To improve the content of the program's materials",
  'To focus program resources on a specific population',
  'To solicit more funds or additional partners',
  'To document the level of success in achieving objectives',
  'Other',
];

export const EVALUATION_PLAN_HEADERS = [
  'Audience/ Stakeholders',
  'Evaluation Questions',
  'Indicators of Changes',
  'Data Needed',
  'Data Collection Timing',
  'Utilization',
];

export const STACK_HOLDER_OPTIONS = [
  {
    question: '1. Who is served, or directly affected, by the project?',
    options: [
      'Patients or clients',
      'Advocacy groups',
      'Community members',
      'Elected officials',
      'Other',
    ],
    description: '',
    optionFieldName: 'contact_served_option',
    inputFieldName: 'contact_served_specific',
  },
  {
    question: '2. Who is involved in project operations that you must contact?',
    options: [
      'Project management',
      'Project staff',
      'Partners',
      'Coalition members',
      'Other',
    ],
    description: '',
    optionFieldName: 'contact_involved_option',
    inputFieldName: 'contact_involved_specific',
  },
  {
    question: '3. Who do we want to influence most with our project findings?',
    options: ['Funder', 'Local decision-maker', 'Community group', 'Other'],
    description: '',
    optionFieldName: 'contact_influence_option',
    inputFieldName: 'contact_influence_specific',
  },
];
