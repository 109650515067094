import { Box, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';

const list = [
  'Meet with your team and revise your evaluation accordingly',
  'Identify teammates’ roles and responsibilities',
  'Review and update your plan regularly',
];

export const Step3GoodWorkPage = () => {
  const theme = useTheme();
  const { updateContextValue } = useMainLayoutContext();

  React.useEffect(() => {
    updateContextValue({
      title: 'Next Steps',
      pageContainerSx: { maxWidth: theme.breakpoints.values.md },
    });

    return () => {
      updateContextValue({ title: undefined, pageContainerSx: undefined });
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography fontWeight={500}>
          You have completed all three objectives of this module.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Typography fontWeight={600}>Now you need to:</Typography>
        {list.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              mt: 2,
            }}
          >
            <Box
              sx={{
                width: 14,
                height: 14,
                borderRadius: 14,
                bgcolor: 'lightgrey',
                flexShrink: 0,
                mr: 2,
              }}
            />
            <Typography sx={{ fontWeight: 500 }}>{item}</Typography>
          </Box>
        ))}
      </Grid>
    </Grid>
  );
};
