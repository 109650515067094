import { Grid, Link, Typography } from '@mui/material';
import React from 'react';

export const AdditionalResources = () => {
  return (
    <Grid container rowGap={2}>
      <Grid item xs={12}>
        <Typography sx={{ fontWeight: 500 }}>
          • Chronic Disease Prevention at CDPHE:{' '}
          <Link
            target="_blank"
            href="https://cdphe.colorado.gov/health/prevention-and-wellness/chronic-disease-prevention"
          >
            https://cdphe.colorado.gov/health/prevention-and-wellness/chronic-disease-prevention
          </Link>
        </Typography>
        <Typography sx={{ fontWeight: 500 }}>
          • Colorado Tobacco Prevention Resources:{' '}
          <Link target="_blank" href="https://www.tobaccofreeco.org/">
            https://www.tobaccofreeco.org/
          </Link>
        </Typography>
        <Typography sx={{ fontWeight: 500 }}>
          • CEPEG Virtual Access Point:{' '}
          <Link
            target="_blank"
            href="https://sites.google.com/view/cepeg-resources/home"
          >
            https://sites.google.com/view/cepeg-resources/home
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ fontWeight: 500 }}>• Evaluation TA for:</Typography>
        <Grid item xs={12} sx={{ pl: 2 }}>
          <Typography sx={{ fontWeight: 500 }}>
            STEPP Grantees:{' '}
            <Link href="mailto:EvaluationTA@UCDenver.edu">
              EvaluationTA@UCDenver.edu
            </Link>
          </Typography>
          <Typography sx={{ fontWeight: 500 }}>
            CCPD Grantees:{' '}
            <Link href="mailto:CEPEGEvaluationTA@CUAnschutz.edu">
              CEPEGEvaluationTA@CUAnschutz.edu
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
