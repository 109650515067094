import { Grid, useTheme, Box } from '@mui/material';
import React from 'react';
import Image from 'mui-image';
import { motion } from 'framer-motion';

import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';
import arrowImage from '../../../assets/images/pngs/arrow_green_right.png';
import processImage from '../../../assets/images/pngs/step3_know_the_focus_process.png';
import outcomeImage from '../../../assets/images/pngs/step3_know_the_focus_outcome.png';
import { GTypography } from '../../../components';

const list = [
  'Inputs Resources or what you invested',
  'Outputs Activities or what you did',
  'Outputs Participants or who you reached',
  `Outcomes\nShort-term\n(learning)`,
  'Outcomes\nIntermediate\n(action)',
  'Outcomes\nLong-term\n(change)',
];

const arrowVariants = {
  visible: {
    opacity: 1,
    width: '100%',
    transition: {
      duration: 1,
    },
  },
  hidden: { opacity: 0, width: 0 },
};

const cellVariants = {
  visible: (i: number) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: 1 + i * 0.3,
    },
  }),
  hidden: { opacity: 0, y: -50 },
};

const bottomImageVariants = {
  visible: (type: string) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: type === 'process' ? 6 : 8,
    },
  }),
  hidden: { opacity: 0, y: 100 },
};

export const Step3KnowYourEvaluationFocusPage = () => {
  const theme = useTheme();
  const { updateContextValue } = useMainLayoutContext();

  React.useEffect(() => {
    updateContextValue({
      title: 'Know the focus: Process and Outcome Evaluation',
      pageContainerSx: { maxWidth: theme.breakpoints.values.md },
    });

    return () => {
      updateContextValue({ title: undefined, pageContainerSx: undefined });
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Grid container rowGap={10}>
      <Grid
        item
        container
        spacing={4}
        justifyContent="center"
        sx={{
          position: 'relative',
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: { xs: 'none', md: 'block' },
            position: 'absolute',
            inset: 0,
            zIndex: 0,
          }}
        >
          <motion.div
            initial="hidden"
            variants={arrowVariants}
            animate="visible"
            style={{
              backgroundImage: `url(${arrowImage})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '50% 53%',
              height: '100%',
            }}
          />
        </Grid>
        {list.map((item, index) => (
          <Grid key={index} item sx={{ zIndex: 1 }}>
            <motion.div
              custom={index}
              initial="hidden"
              variants={cellVariants}
              animate="visible"
            >
              <Box
                sx={{
                  bgcolor: '#8cc63f',
                  borderStyle: 'solid',
                  borderColor: 'white',
                  width: 120,
                  height: 120,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Grid item>
                  <motion.div
                    custom={index + list.length}
                    initial="hidden"
                    variants={cellVariants}
                    animate="visible"
                  >
                    <GTypography textAlign="center" fontWeight={500}>
                      {item}
                    </GTypography>
                  </motion.div>
                </Grid>
              </Box>
            </motion.div>
          </Grid>
        ))}
      </Grid>
      <Grid xs={12} container item columnSpacing={4} rowSpacing={4}>
        <Grid item xs={12} sm={6}>
          <motion.div
            custom={'process'}
            initial="hidden"
            variants={bottomImageVariants}
            animate="visible"
          >
            <Image src={processImage} />
          </motion.div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <motion.div
            custom={'outcome'}
            initial="hidden"
            variants={bottomImageVariants}
            animate="visible"
          >
            <Image src={outcomeImage} />
          </motion.div>
        </Grid>
      </Grid>
    </Grid>
  );
};
