import React from 'react';
import {
  Alert,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';

import { UpdateUserDto } from '../@types';
import { useApi, useAuth, useSettingsContext } from '../contexts';
import { LoadingView } from '../components';
import { Toast } from '../utils';

const resolver = classValidatorResolver(UpdateUserDto);

export const MyAccountEditForm = () => {
  const { user, authActions } = useAuth();
  const {
    parameters: { user_occupation, user_skill_level },
  } = useSettingsContext();

  const {
    loading,
    error,
    call: updateProfile,
  } = useApi('edit-profile', '/profile', {
    method: 'PUT',
    onSuccess: data => {
      authActions.setUser(data);
      Toast.showSuccessMessage('Successfully updated.');
    },
  });

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<UpdateUserDto>({
    resolver,
    defaultValues: {
      old_password: '',
      email: user?.email,
      first_name: user?.first_name,
      last_name: user?.last_name,
      password: '',
      confirm_password: '',
      organization: user?.organization,
      occupation_id: user?.occupation_id,
      skill_level_id: user?.skill_level_id,
    },
  });

  const onSubmit = React.useCallback(
    (data: UpdateUserDto) => {
      if (data.password !== data.confirm_password) {
        return;
      }
      updateProfile({
        ...data,
        email: undefined,
        password: data.password || undefined,
      });
    },
    [updateProfile]
  );

  return (
    <Grid
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      container
      spacing={3}
    >
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Old Password"
          type="password"
          required
          error={!!errors.old_password}
          helperText={errors.old_password?.message}
          {...register('old_password')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Email"
          required
          autoComplete="email"
          error={!!errors.email}
          helperText={errors.email?.message}
          {...register('email')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="New Password"
          type="password"
          error={!!errors.password}
          helperText={errors.password?.message}
          {...register('password')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Confirm New Password"
          type="password"
          error={
            (getValues().confirm_password &&
              getValues().password !== getValues().confirm_password) ||
            !!errors.confirm_password
          }
          helperText={
            getValues().password &&
            getValues().password !== getValues().confirm_password
              ? "Password doesn't match"
              : errors.confirm_password?.message
          }
          {...register('confirm_password')}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="First Name"
          required
          error={!!errors.first_name}
          helperText={errors.first_name?.message}
          {...register('first_name')}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Last Name"
          required
          error={!!errors.last_name}
          helperText={errors.last_name?.message}
          {...register('last_name')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Organization"
          required
          error={!!errors.organization}
          helperText={errors.organization?.message}
          {...register('organization')}
        />
      </Grid>
      <Grid item xs={12} sx={{ mt: 1 }}>
        <FormControl fullWidth>
          <InputLabel id="occupation">Occupation</InputLabel>
          <Select
            label="Occupation"
            labelId="occupation"
            required
            defaultValue={user?.occupation_id}
            error={!!errors.occupation_id}
            {...register('occupation_id')}
          >
            {user_occupation.map((item, index) => (
              <MenuItem value={item.id} key={index}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText sx={{ color: 'red' }}>
            {errors.occupation_id?.message}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12} sx={{ mt: 1 }}>
        <FormControl fullWidth>
          <InputLabel id="skill_level">Rate your skill level</InputLabel>
          <Select
            label="Rate your skill level"
            labelId="skill_level"
            required
            defaultValue={user?.skill_level_id}
            error={!!errors.skill_level_id}
            {...register('skill_level_id')}
          >
            {user_skill_level.map((item, index) => (
              <MenuItem value={item.id} key={index}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText sx={{ color: 'red' }}>
            {errors.skill_level_id?.message}
          </FormHelperText>
        </FormControl>
      </Grid>
      {!!error && (
        <Grid item xs={12}>
          <Alert variant="outlined" severity="error">
            {error?.message}
          </Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <Button variant="contained" type="submit">
          Save
          <LoadingView visible={loading} size={20} />
        </Button>
      </Grid>
    </Grid>
  );
};
