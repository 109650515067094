import React from 'react';
import { Button, ButtonProps, DialogContent } from '@mui/material';

export type DialogFooterProps = {
  onSubmit?: ButtonProps['onClick'];
};

export const DialogFooter = ({ onSubmit = () => {} }: DialogFooterProps) => {
  return (
    <DialogContent sx={{ pt: 1 }}>
      <Button variant="contained" onClick={onSubmit}>
        Submit
      </Button>
    </DialogContent>
  );
};
