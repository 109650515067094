import React from 'react';
import { Box, Tooltip, TooltipProps } from '@mui/material';
import Image from 'mui-image';

import step1ExamplePng from '../../../assets/images/pngs/step1_example.png';
import { GTypography } from '../../../components';

type ExampleViewProps = {
  tooltipTitle?: TooltipProps['title'];
};

export const ExampleView = ({ tooltipTitle = '' }: ExampleViewProps) => {
  const title =
    typeof tooltipTitle === 'string' ? (
      <Box sx={{ p: 1 }}>
        <GTypography variant="body2" sx={{ color: '#ffd769' }}>
          {tooltipTitle}
        </GTypography>
      </Box>
    ) : (
      tooltipTitle
    );

  return (
    <Tooltip title={title} arrow>
      <Box sx={{ cursor: 'pointer' }}>
        <Image
          src={step1ExamplePng}
          style={{ objectFit: 'contain' }}
          duration={500}
        />
      </Box>
    </Tooltip>
  );
};
