import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Grid,
  TextField,
  Typography,
  Link,
  Alert,
} from '@mui/material';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { useForm } from 'react-hook-form';

import { ROUTES } from '../consts';
import { useAuth } from '../contexts';
import { LoadingView } from '../components';
import { LoginUserDto } from '../@types';

const resolver = classValidatorResolver(LoginUserDto);

export const LoginForm = () => {
  const navigate = useNavigate();
  const {
    values: { authorizing, error },
    authActions,
  } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginUserDto>({
    resolver,
    defaultValues: { email: '', password: '' },
  });

  const onSubmit = React.useCallback(
    (data: LoginUserDto) => {
      authActions.login({
        username: data.email,
        password: data.password,
      });
    },
    [authActions]
  );

  React.useEffect(() => {
    authActions.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid
      component="form"
      container
      spacing={3}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Email"
          required
          autoComplete="email"
          error={!!errors.email}
          helperText={errors.email?.message}
          {...register('email')}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Password"
          type="password"
          required
          error={!!errors.password}
          helperText={errors.password?.message}
          {...register('password')}
        />
      </Grid>

      <Grid item xs={12}>
        <Link
          underline="hover"
          sx={{ cursor: 'pointer' }}
          onClick={() => navigate(`/${ROUTES.AUTH}/${ROUTES.FORGOT_PASSWORD}`)}
        >
          Forgot password?
        </Link>
      </Grid>
      {!!error && (
        <Grid item xs={12}>
          <Alert variant="outlined" severity="error">
            {error?.message}
          </Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <Button variant="contained" type="submit">
          Log In
          <LoadingView visible={authorizing} size={20} />
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Typography>
          Don‘t have an account?{' '}
          <Link
            component="span"
            underline="hover"
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate(`/${ROUTES.AUTH}/${ROUTES.SIGNUP}`)}
          >
            Create an account
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};
