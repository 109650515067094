import React, { useState } from 'react';
import {
  IconButton,
  Paper,
  SxProps,
  Theme,
  Tooltip,
  useTheme,
} from '@mui/material';
import { Edit } from '@mui/icons-material';

import {
  ContentInputDialog,
  ContentInputDialogProps,
} from './ContentInputDialog';
import { useContentInputContext } from './ContentInputContext';
import { GTypography } from '../GTypoGraphy';

export type ContentViewerStyleProps = {
  container?: SxProps;
  value?: SxProps;
};

export type ContentViewerProps = {
  value?: string;
  placeholder?: string;
  noValueString?: string;
  editable?: boolean;
  contentInputDialogProps?: ContentInputDialogProps;
  styles?: ContentViewerStyleProps;
};

export const ContentViewer = ({
  value = '',
  placeholder = '',
  noValueString,
  editable = true,
  contentInputDialogProps = {},
  styles,
}: ContentViewerProps) => {
  const theme = useTheme();
  const { handleSubmit } = useContentInputContext();
  const [visibleContentInputDialog, setVisibleContentInputDialog] =
    useState<boolean>(false);

  const noValuePaperSx: SxProps<Theme> = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const noValueTypographySx: SxProps<Theme> = {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 600,
  };

  const handleFormSubmit = React.useCallback(
    (newValue: string) => {
      handleSubmit(newValue);
      setVisibleContentInputDialog(false);
    },
    [handleSubmit]
  );

  return (
    <>
      <Paper
        sx={{
          position: 'relative',
          bgcolor: '#EEB911',
          boxShadow: theme.shadows[1],
          px: 2,
          pt: 2,
          pb: 4,
          minHeight: 150,
          width: '100%',
          ...(!value ? noValuePaperSx : {}),
          ...styles?.container,
        }}
      >
        <GTypography
          sx={{
            ...(!value ? noValueTypographySx : {}),
            wordBreak: 'break-word',
            ...styles?.value,
          }}
        >
          {value || placeholder || noValueString}
        </GTypography>
        {!!editable && (
          <Tooltip
            title="Edit content"
            placement="right"
            arrow
            className="pdf-hide"
          >
            <IconButton
              size="small"
              sx={{
                position: 'absolute',
                bottom: 8,
                right: 8,
                bgcolor: 'white',
                '&:hover': {
                  bgcolor: 'rgba(0, 0, 0, 0.4)',
                },
              }}
              onClick={() => setVisibleContentInputDialog(true)}
            >
              <Edit sx={{ color: '#EEB911', fontSize: 'inherit' }} />
            </IconButton>
          </Tooltip>
        )}
      </Paper>

      <ContentInputDialog
        visible={visibleContentInputDialog}
        setVisible={value => setVisibleContentInputDialog(value)}
        {...contentInputDialogProps}
        contentProps={{ contentInputFormProps: { next: handleFormSubmit } }}
      />
    </>
  );
};
