import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';

import { ForgotPasswordForm } from '../../forms';
import { useMainLayoutContext } from '../../layouts/MainLayoutContext';

export const ForgotPasswordPage = () => {
  const { updateContextValue } = useMainLayoutContext();

  useEffect(() => {
    updateContextValue({ title: 'Forgot your password?' });

    return () => {
      updateContextValue({ title: undefined });
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography color="textSecondary">
          Please enter the email address associated with your account and We
          will email you a link to reset your password.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <ForgotPasswordForm />
      </Grid>
    </Grid>
  );
};
