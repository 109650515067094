import React, { useEffect } from 'react';

import { ResetPasswordForm } from '../../forms';
import { useMainLayoutContext } from '../../layouts/MainLayoutContext';

export const ResetPasswordPage = () => {
  const { updateContextValue } = useMainLayoutContext();

  useEffect(() => {
    updateContextValue({ title: 'Reset password' });

    return () => {
      updateContextValue({ title: undefined });
    };
    // eslint-disable-next-line
  }, []);

  return <ResetPasswordForm />;
};
