import React, { useEffect } from 'react';
import { Grid, useTheme } from '@mui/material';

import { ContentInput } from '../../../components';
import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';
import { ExampleView } from './ExampleView';
import { Description } from './Description';
import { useProjectContext } from '../../../contexts';

const descriptionItems = [
  {
    text: `Describe the `,
  },
  {
    text: 'Core Problem.',
    tooltip: `The problem your project is attempting to solve or the issue it will address`,
  },
];

const exampleTooltip = `1. In OUR CITY, retailers are not required to have a license to sell tobacco. We do not know who is selling and do not have funds for regular comliance checks. We want to pass X policy to hold retailers accountable to all current tobacco laws, and fund retailer education and compliance.\n ${'‎ '}
2. Approx X % of Black/African-American adults in CO are at risk for pre-diabetes. Many lack access to support and information to reduce risk. Our new program will create referral systems between health care providers and local diabetes prevention programs. Participation will help decrease the incidence of type 2 diabetes.`;

export const Step1CreateProjectTheoryDiagram1Page = () => {
  const theme = useTheme();
  const { updateContextValue } = useMainLayoutContext();
  const {
    project,
    projectActions: { updateProject },
  } = useProjectContext();

  useEffect(() => {
    updateContextValue({
      title: 'Describe the Core Problem',
      pageContainerSx: { maxWidth: theme.breakpoints.values.md },
    });

    return () => {
      updateContextValue({ title: undefined, pageContainerSx: undefined });
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container spacing={4} sx={{ alignItems: 'flex-start' }}>
      <Grid item xs={12} sm={7} md={8} lg={9} container spacing={4}>
        <Grid item xs={12}>
          <Description items={descriptionItems} />
        </Grid>

        <Grid item xs={12}>
          <ContentInput
            content={project?.core_problem}
            label={`1. In no more than three sentences, explain the problem your project addresses. We suggest using the formula “what is wrong,” “why it matters,” and “what you plan to do about it.” This frames it as a community problem.`}
            dialogTitle="Community Need"
            handleSubmit={value =>
              updateProject({ id: project?.id, core_problem: value })
            }
          />
        </Grid>
      </Grid>

      <Grid item xs={12} sm={5} md={4} lg={3}>
        <ExampleView tooltipTitle={exampleTooltip} />
      </Grid>
    </Grid>
  );
};
