import axios, { AxiosError, AxiosResponse } from 'axios';
import { Env } from '../consts';

const API_ENDPOINT = `${Env.apiBaseUrl}/api/v1`;

const ClientPublic = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
  },
});

ClientPublic.interceptors.request.use(
  async request => {
    return request;
  },
  error => {
    return Promise.reject(error);
  }
);

ClientPublic.interceptors.response.use(
  async (response: AxiosResponse<any, any>) => {
    return response;
  },
  async (error: AxiosError) => {
    return Promise.reject(error);
  }
);

export default ClientPublic;
