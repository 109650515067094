import { Box, Grid, useTheme, Typography } from '@mui/material';
import Image from 'mui-image';
import React from 'react';

import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';
import bubbleYellowImage from '../../../assets/images/pngs/bubble_yellow.png';
import bubbleBlueImage from '../../../assets/images/pngs/bubble_blue.png';
import bubbleGreenImage from '../../../assets/images/pngs/bubble_green.png';
import bubbleRedImage from '../../../assets/images/pngs/bubble_red.png';

export const Step3OutputOrProcessDataExamplePage = () => {
  const theme = useTheme();
  const { updateContextValue } = useMainLayoutContext();

  React.useEffect(() => {
    updateContextValue({
      title: 'An Example to Clarify Process Data Collection',
      pageContainerSx: { maxWidth: theme.breakpoints.values.md },
    });

    return () => {
      updateContextValue({ title: undefined, pageContainerSx: undefined });
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Grid container>
      <Grid container item xs={12} sm={6}>
        <Grid item xs={6} sx={{ position: 'relative' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: 224,
            }}
          >
            <Image src={bubbleYellowImage} duration={0} />
            <Typography sx={{ fontWeight: 600, mt: 1, fontSize: 20 }}>
              QUESTION
            </Typography>
          </Box>
          <Box
            sx={{ position: 'absolute', inset: 0, px: 4, pt: 6, width: 224 }}
          >
            <Typography sx={{ fontWeight: 500, fontSize: 17 }}>
              Do we have the right mix of activities?
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sx={{ mt: 20, position: 'relative' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: 224,
            }}
          >
            <Image src={bubbleBlueImage} duration={0} />
            <Typography sx={{ fontWeight: 600, mt: 1, fontSize: 20 }}>
              INDICATOR
            </Typography>
          </Box>
          <Box
            sx={{ position: 'absolute', inset: 0, px: 4, pt: 6, width: 224 }}
          >
            <Typography sx={{ fontWeight: 500, fontSize: 17 }}>
              Level of participant satisfaction with available activities.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid item container xs={12} sm={6}>
        <Grid item xs={6} sx={{ position: 'relative' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: 224,
            }}
          >
            <Image src={bubbleGreenImage} duration={0} />
            <Typography sx={{ fontWeight: 600, mt: 1, fontSize: 20 }}>
              DATA
            </Typography>
          </Box>
          <Box
            sx={{ position: 'absolute', inset: 0, pl: 3, pt: 8, width: 224 }}
          >
            <Typography sx={{ fontWeight: 500, fontSize: 14 }}>
              ● Satisfaction survey with participants
            </Typography>
            <Typography sx={{ fontWeight: 500, fontSize: 14 }}>
              ● Interview key participants
            </Typography>
            <Typography sx={{ fontWeight: 500, fontSize: 14 }}>
              ● Staff record/log
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sx={{ mt: 20, position: 'relative' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: 275,
            }}
          >
            <Image src={bubbleRedImage} duration={0} />
            <Typography sx={{ fontWeight: 600, mt: 1, fontSize: 20 }}>
              TIMING
            </Typography>
          </Box>
          <Box
            sx={{ position: 'absolute', inset: 0, px: 4, pt: 5, width: 275 }}
          >
            <Typography sx={{ fontWeight: 500, fontSize: 14 }}>
              ● Review participant satisfaction survey two weeks post
              participation.
            </Typography>
            <Typography sx={{ fontWeight: 500, fontSize: 14 }}>
              ● Interview key participants one month post participation.
            </Typography>
            <Typography sx={{ fontWeight: 500, fontSize: 14 }}>
              ● Review staff logs monthly.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
