import React, { useEffect } from 'react';
import { Grid, Paper, useTheme } from '@mui/material';

import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';
import { ContentInput, PDFButton } from '../../../components';
import arrowImage from '../../../assets/images/pngs/yellow_long_arrow.png';
import { useProjectContext } from '../../../contexts';
import { Description } from './Description';

const descriptionItems = [
  { text: 'Click the pencil icons to edit any section.' },
];

export const Step1ProjectTheoryDiagramForProjectPage = () => {
  const theme = useTheme();
  const { updateContextValue, downloadPDF } = useMainLayoutContext();
  const {
    project,
    projectActions: { updateProject },
  } = useProjectContext();

  useEffect(() => {
    updateContextValue({
      title: `The Completed Project Theory Diagram for ${
        project?.name || '<Project Name>'
      }`,
      pageContainerSx: { maxWidth: theme.breakpoints.values.md },
    });

    return () => {
      updateContextValue({ title: undefined, pageContainerSx: undefined });
    };
    // eslint-disable-next-line
  }, [project?.name]);

  return (
    <Grid
      container
      spacing={6}
      direction="row"
      alignItems="stretch"
      sx={{
        backgroundImage: `url(${arrowImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '63% 55%',
        backgroundSize: { xs: '0 0', sm: '329px 50px' },
      }}
    >
      <Grid item xs={12} className="pdf-hide">
        <Description items={descriptionItems} greenText="" />
      </Grid>
      <Grid item container xs={12} sm={5} spacing={4}>
        <Grid item xs={12}>
          <Paper sx={{ p: 1, bgcolor: 'info.main' }}>
            <ContentInput
              label="Core Problem"
              dialogTitle="Core Problem"
              dialogDescription="1. In no more than three sentences, explain the problem your project addresses. We suggest using the formula “what is wrong,” “why it matters,” and “what you plan to do about it.” This frames it as a community problem."
              content={project?.core_problem}
              handleSubmit={value =>
                updateProject({ id: project?.id, core_problem: value })
              }
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 1, bgcolor: 'info.main' }}>
            <Grid container rowSpacing={1}>
              <Grid item xs={12}>
                <ContentInput
                  label="Community Needs"
                  dialogTitle="Community Needs"
                  dialogDescription="2. In four sentences or less, make your case stronger and more specific by referencing the community need that influences or impacts the problem."
                  content={project?.community_needs}
                  handleSubmit={value =>
                    updateProject({
                      id: project?.id,
                      community_needs: value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <ContentInput
                  label=""
                  dialogTitle="Community Needs"
                  dialogDescription="2. In four sentences or less, make your case stronger and more specific by referencing the community need that influences or impacts the problem."
                  content={project?.community_need_specifics}
                  handleSubmit={value =>
                    updateProject({
                      id: project?.id,
                      community_need_specifics: value,
                    })
                  }
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 1, bgcolor: 'info.main' }}>
            <ContentInput
              label="Influential Factors"
              dialogTitle="Influential Factors"
              dialogDescription="3. In no more than five sentences, describe influential factors that could impact the implementation and desired results of your project. Think about what could really support your work and what are potential barriers to reaching your desired results?"
              content={project?.influential_factors}
              handleSubmit={value =>
                updateProject({
                  id: project?.id,
                  influential_factors: value,
                })
              }
            />
          </Paper>
        </Grid>
      </Grid>
      <Grid item container xs={12} sm={7} spacing={4} alignItems="flex-end">
        <Grid
          item
          container
          xs={12}
          direction={{ xs: 'column', sm: 'row' }}
          spacing={6}
          sx={{ mt: { xs: 0, sm: '10%' } }}
        >
          <Grid item container xs={12} sm={6}>
            <Paper sx={{ p: 1, bgcolor: 'info.main', width: '100%' }}>
              <ContentInput
                label="Intervention Strategy(ies)"
                dialogTitle="Intervention Strategy(ies)"
                dialogDescription="4. Briefly describe the strategy(ies) your work will use to achieve the results you desire, and provide the source for each strategy (e.g., Clinical Guidelines, The Community Guide, etc.)."
                content={project?.intervention_strategy}
                handleSubmit={value =>
                  updateProject({
                    id: project?.id,
                    intervention_strategy: value,
                  })
                }
              />
            </Paper>
          </Grid>
          <Grid item container xs={12} sm={6}>
            <Paper sx={{ p: 1, bgcolor: 'info.main', width: '100%' }}>
              <Grid container rowSpacing={1}>
                <Grid item xs={12}>
                  <ContentInput
                    label="Desired Results"
                    dialogTitle="Desired Results"
                    dialogDescription={'Short-term\n(3 to 12 months)'}
                    content={project?.short_term_goal}
                    handleSubmit={value =>
                      updateProject({
                        id: project?.id,
                        short_term_goal: value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <ContentInput
                    label="Desired Results"
                    dialogTitle="Desired Results"
                    dialogDescription={'Intermediate-term\n(3 to 12 months)'}
                    content={project?.intermediate_term_goal}
                    handleSubmit={value =>
                      updateProject({
                        id: project?.id,
                        intermediate_term_goal: value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <ContentInput
                    label="Desired Results"
                    dialogTitle="Desired Results"
                    dialogDescription={'Long-term\n(3 to 12 months)'}
                    content={project?.long_term_goal}
                    handleSubmit={value =>
                      updateProject({
                        id: project?.id,
                        long_term_goal: value,
                      })
                    }
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} textAlign="right" sx={{ mt: 2 }} className="pdf-hide">
        <PDFButton onClick={downloadPDF} />
      </Grid>
    </Grid>
  );
};
