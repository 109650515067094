import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AuthContextProviderValues,
  CreateUserDto,
  LoginPayload,
  User,
} from '../@types';
import { ROUTES } from '../consts';
import { AxiosClient, StorageUtils, mapError } from '../utils';
import { RedirectModal } from '../components';

const defaultValues: AuthContextProviderValues = {
  ready: false,
  authorizing: false,
  loggedIn: false,
};

export interface AuthContextType {
  user?: User;
  values: AuthContextProviderValues;
  setValues: (payload: Partial<AuthContextProviderValues>) => void;
  authActions: {
    login: (payload: LoginPayload) => Promise<void>;
    signup: (payload: CreateUserDto) => Promise<void>;
    logout: () => void;
    reset: () => void;
    setUser: (user: User) => void;
  };
}

const defaultAuthContextValue: AuthContextType = {
  values: defaultValues,
  setValues: () => {},
  authActions: {
    login: async () => {},
    signup: async () => {},
    logout: () => {},
    reset: () => {},
    setUser: () => {},
  },
};

export const AuthContext = React.createContext(defaultAuthContextValue);

interface AuthContextProviderInterface {}

export const AuthContextProvider = ({
  children,
}: React.PropsWithChildren<AuthContextProviderInterface>) => {
  const [values, setValues] =
    React.useState<AuthContextProviderValues>(defaultValues);
  const [user, setUser] = React.useState<User>();
  const navigate = useNavigate();
  const [showRedirectModal, setShowRedirectModal] = React.useState(false);

  const syncUser = React.useCallback(async () => {
    try {
      const { data } = await AxiosClient.get('/profile');
      setValues(prev => ({ ...prev, loggedIn: true, ready: true }));
      setUser(data);
    } catch (error) {
      setValues(prev => ({ ...prev, loggedIn: false, ready: true }));
    }
  }, []);

  React.useEffect(() => {
    if (StorageUtils.getAuthToken()) {
      syncUser();
    } else {
      setValues(prev => ({ ...prev, loggedIn: false, ready: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = React.useCallback(
    async (payload: LoginPayload) => {
      try {
        setValues(prev => ({ ...prev, authorizing: true, error: undefined }));
        const { data } = await AxiosClient.post('/auth/login', { ...payload });
        StorageUtils.setAuthToken(data?.access_token);
        StorageUtils.setRefreshToken(data?.refresh_token);
        setValues(prev => ({ ...prev, authorizing: false, loggedIn: true }));
        setUser(data?.user);
        navigate(ROUTES.ROOT);
        if (data?.user?.last_visit_url) {
          setShowRedirectModal(true);
        }
      } catch (error) {
        setValues(prev => ({
          ...prev,
          error: mapError(error),
          authorizing: false,
        }));
      }
    },
    [navigate]
  );

  const signup = React.useCallback(
    async (payload: CreateUserDto) => {
      try {
        setValues(prev => ({ ...prev, authorizing: true, error: undefined }));
        const { data } = await AxiosClient.post('/auth/signup', { ...payload });
        StorageUtils.setAuthToken(data?.access_token);
        StorageUtils.setRefreshToken(data?.refresh_token);
        setValues(prev => ({ ...prev, authorizing: false, loggedIn: true }));
        setUser(data?.user);
        navigate(ROUTES.ROOT);
      } catch (error) {
        setValues(prev => ({
          ...prev,
          error: mapError(error),
          authorizing: false,
        }));
      }
    },
    [navigate]
  );

  const logout = React.useCallback(async () => {
    StorageUtils.removeAuthToken();
    StorageUtils.removeRefreshToken();
    setValues({ ...defaultValues, ready: true });
    navigate(`${ROUTES.AUTH}/${ROUTES.LOGIN}`);
  }, [navigate]);

  const updateValues = React.useCallback(
    (
      payload: Partial<
        Omit<AuthContextProviderValues, 'updateValues' | 'authActions'>
      >
    ) => setValues(prev => ({ ...prev, ...payload })),
    []
  );

  return (
    <AuthContext.Provider
      value={{
        values,
        user,
        setValues: updateValues,
        authActions: {
          login,
          signup,
          logout,
          setUser,
          reset: () =>
            setValues({
              ...defaultValues,
              ready: true,
            }),
        },
      }}
    >
      {children}
      <RedirectModal
        visible={showRedirectModal}
        onRedirect={() => {
          setShowRedirectModal(false);
          if (user?.last_visit_url) {
            navigate(user?.last_visit_url!);
          }
        }}
        onClose={() => setShowRedirectModal(false)}
      />
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return React.useContext(AuthContext);
};
