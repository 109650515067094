import React from 'react';
import { Container } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts';
import { ROUTES } from '../consts';

export const PrivateLayout = () => {
  const navigate = useNavigate();
  const {
    values: { loggedIn, ready },
  } = useAuth();

  React.useEffect(() => {
    if (ready && !loggedIn) {
      navigate(ROUTES.ROOT);
    }
  }, [ready, loggedIn, navigate]);

  if (!loggedIn) {
    return null;
  }

  return (
    <Container disableGutters>
      <Outlet />
    </Container>
  );
};
