import React, { useEffect } from 'react';

import { MyAccountEditForm } from '../../forms';
import { useMainLayoutContext } from '../../layouts/MainLayoutContext';

export const MyAccountEditPage = () => {
  const { updateContextValue } = useMainLayoutContext();

  useEffect(() => {
    updateContextValue({ title: 'Online Profile' });

    return () => {
      updateContextValue({ title: undefined });
    };
    // eslint-disable-next-line
  }, []);

  return <MyAccountEditForm />;
};
