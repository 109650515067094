import React from 'react';
import {
  Alert,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { ResetPasswordDto } from '../@types';
import { useForm } from 'react-hook-form';
import { useApi } from '../contexts';
import { ConfirmDialog, LoadingView } from '../components';
import { ROUTES } from '../consts';

const resolver = classValidatorResolver(ResetPasswordDto);

export const ResetPasswordForm = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const params = React.useMemo(() => new URLSearchParams(search), [search]);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = React.useState(false);
  const { error, call, data, loading } = useApi(
    'reset-password',
    '/auth/reset',
    {
      method: 'POST',
      onSuccess: () => {
        setShowSuccessDialog(true);
      },
    }
  );

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<ResetPasswordDto>({
    resolver,
    defaultValues: {
      username: params.get('username')!,
      code: params.get('code')!,
      password: '',
      confirm_password: '',
    },
  });

  const onSubmit = React.useCallback(
    async (data: ResetPasswordDto) => {
      if (data.password !== data.confirm_password) {
        return;
      }
      await call({ ...data });
    },
    [call]
  );

  return (
    <Grid
      container
      spacing={3}
      noValidate
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Email"
          disabled
          required
          error={!!errors.username}
          helperText={errors.username?.message}
          {...register('username')}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          label="New Password"
          type={showPassword ? 'text' : 'password'}
          required
          error={!!errors.password}
          helperText={errors.password?.message}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...register('password')}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Confirm Password"
          type={showPassword ? 'text' : 'password'}
          required
          error={
            (getValues().confirm_password &&
              getValues().password !== getValues().confirm_password) ||
            !!errors.confirm_password
          }
          helperText={
            getValues().password &&
            getValues().password !== getValues().confirm_password
              ? "Password doesn't match"
              : errors.confirm_password?.message
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...register('confirm_password')}
        />
      </Grid>

      <Grid item xs={12}>
        <Button variant="contained" type="submit">
          Reset
          <LoadingView visible={loading} size={20} />
        </Button>
      </Grid>
      {!!error && (
        <Grid item xs={12}>
          <Alert variant="outlined" severity="error">
            {error?.message}
          </Alert>
        </Grid>
      )}
      <ConfirmDialog
        open={!!data && showSuccessDialog}
        description="Your password has been changed successfully. You can now sign in with your new password."
        title="Success"
        hideCancelButton
        confirmTitle="Login"
        onClose={() => setShowSuccessDialog(false)}
        onConfirm={() => navigate(`/${ROUTES.AUTH}/${ROUTES.LOGIN}`)}
      />
    </Grid>
  );
};
