import { Grid, Typography, useTheme } from '@mui/material';
import Image from 'mui-image';
import React from 'react';
import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';
import { Description } from './Description';
import image1 from '../../../assets/images/pngs/step3_identify_1.png';
import image2 from '../../../assets/images/pngs/step3_identify_2.png';
import image3 from '../../../assets/images/pngs/step3_identify_3.png';

const descriptionItems = [
  {
    text: `Identifying `,
  },
  {
    text: 'Stakeholders',
    tooltip: `Those with a vested interest in your project and its outcomes.`,
  },
  {
    text: ' for the Evaluation Plan',
  },
];

export const Step3IdentifyStakeholders1Page = () => {
  const theme = useTheme();
  const { updateContextValue } = useMainLayoutContext();

  React.useEffect(() => {
    updateContextValue({
      pageContainerSx: { maxWidth: theme.breakpoints.values.md },
    });

    return () => {
      updateContextValue({ title: undefined, pageContainerSx: undefined });
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sx={{ mb: 4 }}>
        <Description items={descriptionItems} />
      </Grid>
      <Grid item container columnSpacing={4}>
        <Grid item xs={4}>
          <Image src={image1} style={{ objectFit: 'contain' }} />
          <Typography
            textAlign="center"
            sx={{ px: { sm: 4 }, fontWeight: 500 }}
          >
            Those served or directly affected by the project
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Image src={image2} style={{ objectFit: 'contain' }} />
          <Typography
            textAlign="center"
            sx={{ px: { sm: 4 }, fontWeight: 500 }}
          >
            Those involved in the project’s operations
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Image src={image3} style={{ objectFit: 'contain' }} />
          <Typography
            textAlign="center"
            sx={{ px: { sm: 4 }, fontWeight: 500 }}
          >
            Those you want to motivate with project findings
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
