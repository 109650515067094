import { Env } from './env.const';
import { ROUTES } from './route.const';

const baseMediaUrl = `${Env.apiBaseUrl}/audios`;

export const audioFiles = {
  [ROUTES.MODULE_OBJECTIVES]: `${baseMediaUrl}/page6.wav`,
  [ROUTES.GETTING_YOUR_BEARINGS]: `${baseMediaUrl}/page7.wav`,
  [ROUTES.THE_EVALUATION_ROADMAP]: `${baseMediaUrl}/page9.wav`,
  [ROUTES.DIAGRAMS_YOU_WILL_CREATE_1]: `${baseMediaUrl}/page11.wav`,
  [ROUTES.DIAGRAMS_YOU_WILL_CREATE_2]: `${baseMediaUrl}/page12.wav`,
  [ROUTES.PROJECT_PROFILE]: `${baseMediaUrl}/page13_A.mp3`,
  [ROUTES.PROJECT_PROFILE_EDIT]: `${baseMediaUrl}/page13_B.mp3`,
  [ROUTES.PROJECT_THEORY_DIAGRAM_1]: `${baseMediaUrl}/page14.mp3`,
  [ROUTES.PROJECT_THEORY_DIAGRAM_2]: `${baseMediaUrl}/page15.mp3`,
  [ROUTES.CREATE_PROJECT_THEORY_DIAGRAM_1]: `${baseMediaUrl}/page16.mp3`,
  [ROUTES.CREATE_PROJECT_THEORY_DIAGRAM_2]: `${baseMediaUrl}/page17.mp3`,
  [ROUTES.CREATE_PROJECT_THEORY_DIAGRAM_3]: `${baseMediaUrl}/page18.mp3`,
  [ROUTES.CREATE_PROJECT_THEORY_DIAGRAM_4]: `${baseMediaUrl}/page19.mp3`,
  [ROUTES.CREATE_PROJECT_THEORY_DIAGRAM_5]: `${baseMediaUrl}/page20.mp3`,
  [ROUTES.PROJECT_THEORY_DIAGRAM_FOR_PROJECT]: `${baseMediaUrl}/page22.mp3`,
  [ROUTES.YOUR_EVALUATION_ROADMAP2]: `${baseMediaUrl}/page23.wav`,
  [ROUTES.BENEFIT_OF_PORJECT_FLOW_DIAGRAM]: `${baseMediaUrl}/page24.wav`,
  [ROUTES.CREATE_PROJECT_FLOW_DIAGRAM_1]: `${baseMediaUrl}/page26.wav`,
  [ROUTES.CREATE_PROJECT_FLOW_DIAGRAM_2]: `${baseMediaUrl}/page27.wav`,
  [ROUTES.CREATE_PROJECT_FLOW_DIAGRAM_3]: `${baseMediaUrl}/page28.wav`,
  [ROUTES.CREATE_PROJECT_FLOW_DIAGRAM_4]: `${baseMediaUrl}/page29.wav`,
  [ROUTES.CREATE_PROJECT_FLOW_DIAGRAM_5]: `${baseMediaUrl}/page30.wav`,
  [ROUTES.PROJECT_FLOW_DIAGRAM_FOR_PROJECT]: `${baseMediaUrl}/page31.wav`,
  [ROUTES.YOUR_EVALUATION_ROADMAP3]: `${baseMediaUrl}/page33.mp3`,
  [ROUTES.CREATE_PROJECT_EVALUATION_PLAN]: `${baseMediaUrl}/page34.mp3`,
  [ROUTES.KNOW_YOUR_EVALUATION_FOCUS]: `${baseMediaUrl}/page36.mp3`,
  [ROUTES.IDENITIFY_STAKEHOLDERS_1]: `${baseMediaUrl}/page37.mp3`,
  [ROUTES.IDENITIFY_STAKEHOLDERS_2]: `${baseMediaUrl}/page38.mp3`,
  [ROUTES.CHOOSE_EVALUATION_QUESTIONS]: `${baseMediaUrl}/page39.mp3`,
  [ROUTES.ALIGN_EVALUATION_QUESTIONS]: `${baseMediaUrl}/page40.mp3`,
  [ROUTES.PROCESS_EVALUATION_QUESTIONS]: `${baseMediaUrl}/page43.mp3`,
  [ROUTES.EVALUATION_PLAN_IN_PROGRESS_1]: `${baseMediaUrl}/page44.mp3`,
  [ROUTES.EVALUATION_PLAN_IN_PROGRESS_2]: `${baseMediaUrl}/page45.mp3`,
  [ROUTES.DECIDING_ON_INDICATORS_OF_CHANGE]: `${baseMediaUrl}/page46.mp3`,
  [ROUTES.DECIDING_ON_INDICATORS_1]: `${baseMediaUrl}/page47.mp3`,
  [ROUTES.DECIDING_ON_INDICATORS_2]: `${baseMediaUrl}/page48.mp3`,
  [ROUTES.DATA_AND_DATA_COLLECTION_TIMING]: `${baseMediaUrl}/page50.mp3`,
  [ROUTES.OUTPUT_OR_PROCESS_DATA_EXAMPLE]: `${baseMediaUrl}/page51.mp3`,
  [ROUTES.OUTCOME_DATA_EXAMPLE]: `${baseMediaUrl}/page52.mp3`,
  [ROUTES.SELECT_YOUR_DATA_MEASURES_AND_TIMING]: `${baseMediaUrl}/page53.mp3`,
  [ROUTES.EVALUATION_PLAN_IN_PROGRESS_4]: `${baseMediaUrl}/page54.mp3`,
  [ROUTES.USING_EVALUATION_FINDINGS]: `${baseMediaUrl}/page55.mp3`,
  [ROUTES.PROJECT_EVALUATION_PLAN_FOR_PROJECT]: `${baseMediaUrl}/page56.mp3`,
  [ROUTES.GOOD_WORK]: `${baseMediaUrl}/page57.wav`,
  [ROUTES.THANK_YOU]: '',
  [ROUTES.EVALUATION_FORM]: '',
};

export const getAudioFile = (
  pathname: string,
  editingProject: boolean = false
) => {
  const formattedPath = pathname?.endsWith('/')
    ? pathname.slice(0, -1)
    : pathname;
  let lastPath = formattedPath?.split('/')?.at(-1);

  if (editingProject && lastPath === ROUTES.PROJECT_PROFILE) {
    lastPath = ROUTES.PROJECT_PROFILE_EDIT;
  }

  if (lastPath) {
    return audioFiles[lastPath] || '';
  } else {
    return '';
  }
};
