import React, { useEffect } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import Image from 'mui-image';

import step1ProjectProfilePng from '../../../assets/images/pngs/step1_project_profile.png';
import { ProjectProfileForm } from '../../../forms';
import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';
import { useProjectContext } from '../../../contexts';

export const Step1ProjectProfilePage = () => {
  const theme = useTheme();
  const { updateContextValue } = useMainLayoutContext();
  const {
    loadings: { loadProject: loading },
    projectId,
  } = useProjectContext();

  useEffect(() => {
    updateContextValue({
      title: projectId
        ? 'Continue Your Project'
        : 'Complete a Project Profile Form',
      pageContainerSx: { maxWidth: theme.breakpoints.values.md },
    });

    return () => {
      updateContextValue({ title: undefined, pageContainerSx: undefined });
    };
    // eslint-disable-next-line
  }, [projectId]);

  useEffect(() => {
    updateContextValue({
      loading,
    });

    return () => {
      updateContextValue({ loading: false });
    };
    // eslint-disable-next-line
  }, [loading]);

  return (
    <Grid container spacing={4} sx={{ alignItems: 'flex-start' }}>
      <Grid item xs={12} sm={8}>
        <ProjectProfileForm />
      </Grid>

      <Grid item xs={12} sm={4} container spacing={2}>
        <Grid item xs={12}>
          <Image
            src={step1ProjectProfilePng}
            style={{ objectFit: 'contain' }}
            duration={500}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ textAlign: 'center', maxWidth: 180, mx: 'auto' }}
          >
            Give your project a name that you can use for future access.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
