import React, { useEffect } from 'react';
import { Container } from '@mui/material';

import { useMainLayoutContext } from '../../layouts/MainLayoutContext';
import { EvaluationForm } from '../../forms';

export const EvaluationFormPage = () => {
  const { updateContextValue } = useMainLayoutContext();

  useEffect(() => {
    updateContextValue({
      title: 'Evaluation Form',
      pageContainerSx: { width: '100%' },
    });

    return () => {
      updateContextValue({
        title: undefined,
        pageContainerSx: undefined,
      });
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Container disableGutters>
      <EvaluationForm />
    </Container>
  );
};
