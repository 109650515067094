import React from 'react';
import { Box, Container, Typography } from '@mui/material';

import { useMainLayoutContext } from './MainLayoutContext';
import { HamburgerMenu } from './hamburger-menu';
import { useParams } from 'react-router-dom';

export const BannerContainer = () => {
  const { bannerChildren, bannerContainerSx } = useMainLayoutContext();
  const { id: projectId } = useParams();

  const visibleHamburgerMenu = !!!bannerChildren && !!projectId;

  const children = bannerChildren || (
    <Typography
      sx={{
        textAlign: 'center',
        fontSize: 27,
        color: 'white',
        fontWeight: 700,
      }}
    >
      PROJECT PLANNING & EVALUATION
    </Typography>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        mx: { xs: -2, sm: -3 },
        height: 135,
        px: 2,
        bgcolor: '#4f77b2',
        ...bannerContainerSx,
      }}
    >
      <Container
        maxWidth="md"
        sx={{ p: { xs: 0 }, display: 'flex', alignItems: 'center' }}
      >
        <Box sx={{ width: 48 }}>
          {visibleHamburgerMenu && <HamburgerMenu />}
        </Box>

        <Box sx={{ flex: 1, pr: 6 }}>{children}</Box>
      </Container>
    </Box>
  );
};
