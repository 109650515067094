import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import Image from 'mui-image';

import pdfImage from '../assets/images/pngs/pdf.png';

interface Props extends ButtonProps {}

export const PDFButton = ({ ...rest }: Props) => {
  return (
    <Button
      sx={{ textTransform: 'none', fontWeight: 600 }}
      startIcon={
        <Image
          src={pdfImage}
          fit="contain"
          duration={0}
          style={{ height: 100 }}
        />
      }
      {...rest}
    >
      Click here to
      <br />
      download a PDF
    </Button>
  );
};
