import { ROUTES } from './route.const';

export const getProjectRoutes = (projectId: number) => [
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.INTRO}/${ROUTES.MODULE_OBJECTIVES}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.INTRO}/${ROUTES.GETTING_YOUR_BEARINGS}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.INTRO}/${ROUTES.THE_EVALUATION_ROADMAP}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.INTRO}/${ROUTES.DIAGRAMS_YOU_WILL_CREATE_1}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.INTRO}/${ROUTES.DIAGRAMS_YOU_WILL_CREATE_2}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_1}/${ROUTES.PROJECT_PROFILE}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_1}/${ROUTES.PROJECT_THEORY_DIAGRAM_1}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_1}/${ROUTES.PROJECT_THEORY_DIAGRAM_2}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_1}/${ROUTES.CREATE_PROJECT_THEORY_DIAGRAM_1}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_1}/${ROUTES.CREATE_PROJECT_THEORY_DIAGRAM_2}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_1}/${ROUTES.CREATE_PROJECT_THEORY_DIAGRAM_3}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_1}/${ROUTES.CREATE_PROJECT_THEORY_DIAGRAM_4}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_1}/${ROUTES.CREATE_PROJECT_THEORY_DIAGRAM_5}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_1}/${ROUTES.PROJECT_THEORY_DIAGRAM_FOR_PROJECT}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_2}/${ROUTES.YOUR_EVALUATION_ROADMAP2}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_2}/${ROUTES.BENEFIT_OF_PORJECT_FLOW_DIAGRAM}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_2}/${ROUTES.CREATE_PROJECT_FLOW_DIAGRAM_1}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_2}/${ROUTES.CREATE_PROJECT_FLOW_DIAGRAM_2}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_2}/${ROUTES.CREATE_PROJECT_FLOW_DIAGRAM_3}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_2}/${ROUTES.CREATE_PROJECT_FLOW_DIAGRAM_4}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_2}/${ROUTES.CREATE_PROJECT_FLOW_DIAGRAM_5}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_2}/${ROUTES.PROJECT_FLOW_DIAGRAM_FOR_PROJECT}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.YOUR_EVALUATION_ROADMAP3}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.CREATE_PROJECT_EVALUATION_PLAN}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.KNOW_YOUR_EVALUATION_FOCUS}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.IDENITIFY_STAKEHOLDERS_1}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.IDENITIFY_STAKEHOLDERS_2}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.CHOOSE_EVALUATION_QUESTIONS}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.ALIGN_EVALUATION_QUESTIONS}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.PROCESS_EVALUATION_QUESTIONS}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.EVALUATION_PLAN_IN_PROGRESS_1}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.EVALUATION_PLAN_IN_PROGRESS_2}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.DECIDING_ON_INDICATORS_OF_CHANGE}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.DECIDING_ON_INDICATORS_1}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.DECIDING_ON_INDICATORS_2}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.DATA_AND_DATA_COLLECTION_TIMING}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.OUTPUT_OR_PROCESS_DATA_EXAMPLE}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.OUTCOME_DATA_EXAMPLE}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.SELECT_YOUR_DATA_MEASURES_AND_TIMING}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.EVALUATION_PLAN_IN_PROGRESS_4}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.USING_EVALUATION_FINDINGS}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.PROJECT_EVALUATION_PLAN_FOR_PROJECT}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.GOOD_WORK}`,
  `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.THANK_YOU}`,
  `/${ROUTES.EVALUATION_FORM}`,
];

export const projectIntroRoutes = [
  `/${ROUTES.PROJECTS}/${ROUTES.INTRO}/${ROUTES.MODULE_OBJECTIVES}`,
  `/${ROUTES.PROJECTS}/${ROUTES.INTRO}/${ROUTES.GETTING_YOUR_BEARINGS}`,
  `/${ROUTES.PROJECTS}/${ROUTES.INTRO}/${ROUTES.THE_EVALUATION_ROADMAP}`,
  `/${ROUTES.PROJECTS}/${ROUTES.INTRO}/${ROUTES.DIAGRAMS_YOU_WILL_CREATE_1}`,
  `/${ROUTES.PROJECTS}/${ROUTES.INTRO}/${ROUTES.DIAGRAMS_YOU_WILL_CREATE_2}`,
  `/${ROUTES.PROJECTS}/${ROUTES.INTRO}/${ROUTES.PROJECT_PROFILE}`,
];

export const isInIntroPath = (pathname: string) => {
  const formattedPathname = pathname?.endsWith('/')
    ? pathname.slice(0, -1)
    : pathname;
  return projectIntroRoutes.includes(formattedPathname);
};

export const footerBlackListPaths = (projectId: string): string[] => [
  // `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_1}/${ROUTES.PROJECT_THEORY_DIAGRAM_FOR_PROJECT}`,
];

export const isInFooterBlackListPath = (
  projectId: string,
  pathname: string
) => {
  const formattedPathname = pathname?.endsWith('/')
    ? pathname.slice(0, -1)
    : pathname;
  return footerBlackListPaths(projectId).includes(formattedPathname);
};

export const getCurrentRoute = (projectId: number, pathname: string) => {
  const isIntroPath = isInIntroPath(pathname);
  const formattedPathname = pathname?.endsWith('/')
    ? pathname.slice(0, -1)
    : pathname;
  const routes = isIntroPath ? projectIntroRoutes : getProjectRoutes(projectId);
  const index = routes.findIndex(route => route === formattedPathname);
  if (index < 0) {
    return routes[0];
  } else if (index < routes.length - 1) {
    return routes[index];
  } else {
    return false;
  }
};

export const getNextRoute = (projectId: number, pathname: string) => {
  const isIntroPath = isInIntroPath(pathname);
  const formattedPathname = pathname?.endsWith('/')
    ? pathname.slice(0, -1)
    : pathname;
  const routes = isIntroPath ? projectIntroRoutes : getProjectRoutes(projectId);
  const index = routes.findIndex(route => route === formattedPathname);
  if (index < 0) {
    return routes[0];
  } else if (index < routes.length - 1) {
    return routes[index + 1];
  } else {
    return false;
  }
};

export const getPrevRoute = (projectId: number, pathname: string) => {
  const isIntroPath = isInIntroPath(pathname);
  const formattedPathname = pathname?.endsWith('/')
    ? pathname.slice(0, -1)
    : pathname;
  const routes = isIntroPath ? projectIntroRoutes : getProjectRoutes(projectId);
  const index = routes.findIndex(route => route === formattedPathname);
  if (index < 0) {
    return routes[0];
  } else if (index > 0) {
    return routes[index - 1];
  } else {
    return false;
  }
};
