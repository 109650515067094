import React, { useEffect } from 'react';
import { Grid, useTheme } from '@mui/material';

import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';
import { ExampleView } from './ExampleView';
import { Description } from './Description';
import { ContentInput } from '../../../components';
import { useProjectContext } from '../../../contexts';

const descriptionItems = [
  {
    text: `List `,
  },
  {
    text: 'Influential Factors.',
    tooltip: `The surrounding environment in which the program exists (e.g., political, other initiatives, socioeconomic factors, staff turnover, social norms and conditions, program history, stage of development) that can affect its success either positively or negatively.`,
  },
];

const exampleTooltip = `1. Our Coalition has strong representation from local leaders, business people, and youth. Our City Council is concerned with the health of our youth and is a leader for promoting youth-focused policies. Two neighboring cities recently passed a retail licensing program. A challenge – there has been talk of a recall for our city council member X.\n‎ \n2. Our community is cohesive and has successfully tackled large social problems. Many local providers are people of color - they understand the problem and are motivated to be involved. On the other hand, people who are Black/African-American face many environmental stressors resulting from systematic and structural racism that increase their risk of diabetes.`;

export const Step1CreateProjectTheoryDiagram3Page = () => {
  const theme = useTheme();
  const { updateContextValue } = useMainLayoutContext();
  const {
    project,
    projectActions: { updateProject },
  } = useProjectContext();

  useEffect(() => {
    updateContextValue({
      title: 'List Influential Factors',
      pageContainerSx: { maxWidth: theme.breakpoints.values.md },
    });

    return () => {
      updateContextValue({ title: undefined, pageContainerSx: undefined });
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container spacing={4} sx={{ alignItems: 'flex-start' }}>
      <Grid item xs={12} sm={7} md={8} lg={9} container spacing={4}>
        <Grid item xs={12}>
          <Description items={descriptionItems} />
        </Grid>

        <Grid item xs={12}>
          <ContentInput
            label={`3. In no more than five sentences, describe influential factors that could impact the implementation and desired results of your project. Think about what could really support your work and what are potential barriers to reaching your desired results?`}
            dialogTitle="Influential Factors"
            content={project?.influential_factors}
            handleSubmit={value =>
              updateProject({ id: project?.id, influential_factors: value })
            }
          />
        </Grid>
      </Grid>

      <Grid item xs={12} sm={5} md={4} lg={3}>
        <ExampleView tooltipTitle={exampleTooltip} />
      </Grid>
    </Grid>
  );
};
