import React, { useEffect } from 'react';
import {
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
} from '@mui/material';

import { useMainLayoutContext } from '../../layouts/MainLayoutContext';
import { useProjectContext } from '../../contexts';
import { Masonry } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../consts';
import { Project } from '../../@types';
import { ConfirmDialog } from '../../components';

export const ProjectsListPage = () => {
  const { updateContextValue } = useMainLayoutContext();
  const {
    projects,
    projectActions,
    loadings: { loadProjects: loading },
  } = useProjectContext();
  const navigate = useNavigate();
  const [selectedProject, setSelectedProject] = React.useState<Project>();

  useEffect(() => {
    updateContextValue({
      title: 'My Projects',
      pageContainerSx: { width: '100%', maxWidth: 'lg' },
      pageTitleSx: { textAlign: 'center' },
    });

    return () => {
      updateContextValue({
        title: undefined,
        pageContainerSx: undefined,
        pageTitleSx: undefined,
      });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    updateContextValue({
      updatingProject: loading,
    });
    return () => {
      updateContextValue({
        updatingProject: loading,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const onDeleteProject = React.useCallback(
    async (project: Project) => {
      setSelectedProject(undefined);
      await projectActions.deleteProject(project.id);
    },
    [projectActions]
  );

  return (
    <Container disableGutters>
      <Masonry columns={{ xs: 1, sm: 2, md: 3 }} spacing={3} sx={{ margin: 0 }}>
        {projects.map((project, index) => (
          <Grid key={index} container>
            <Paper sx={{ width: '100%', overflow: 'hidden', p: 1 }}>
              <Divider sx={{ backgroundColor: 'primary.main', mb: 1 }} />
              <Grid container key={index}>
                <Grid item alignSelf="center" sx={{ px: 1 }}>
                  <Typography fontWeight={500}>{project.name}</Typography>
                </Grid>

                <Grid
                  item
                  alignSelf="flex-end"
                  marginLeft="auto"
                  sx={{ px: 1 }}
                >
                  <Grid container>
                    <Grid item>
                      <Button
                        onClick={() =>
                          navigate(
                            `/${ROUTES.PROJECTS}/${project?.id}/${ROUTES.STEP_1}/${ROUTES.CREATE_PROJECT_THEORY_DIAGRAM_1}`
                          )
                        }
                      >
                        Edit
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button onClick={() => setSelectedProject(project)}>
                        Delete
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Masonry>
      {projects?.length === 0 && (
        <Typography textAlign="center" color="gray">
          {loading ? 'Loading projects...' : 'No projects yet.'}
        </Typography>
      )}
      <ConfirmDialog
        open={!!selectedProject}
        title={`Confirm Delete - "${selectedProject?.name || ''}"`}
        description={`Are you sure you want to remove this project and all its data?`}
        confirmTitle="Delete"
        titlelProps={{ sx: { backgroundColor: 'error.main' } }}
        onClose={() => setSelectedProject(undefined)}
        onConfirm={() => onDeleteProject(selectedProject!)}
      />
    </Container>
  );
};
