import React from 'react';
import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

import { ROUTES } from '../consts';
import { useAuth } from '../contexts';

export const Header = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    values: { loggedIn },
    authActions,
  } = useAuth();

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const leftNavItems = React.useMemo(
    () => [
      { label: 'Home', route: ROUTES.ROOT, action: undefined },
      { label: 'Help', route: ROUTES.HELP, action: undefined },
    ],
    []
  );

  const rightNavItems = React.useMemo(
    () => [
      { label: 'TA Contact', route: ROUTES.TA_CONTACT },
      ...(loggedIn
        ? [
            {
              label: 'My Account',
              route: `${ROUTES.MY_ACCOUNT}/${ROUTES.VIEW}`,
            },
            {
              label: 'Create New Project',
              route: `${ROUTES.PROJECTS}/${ROUTES.INTRO}/${ROUTES.MODULE_OBJECTIVES}`,
            },
            { label: 'My Projects', route: ROUTES.PROJECTS },
            {
              label: 'Log Out',
              route: ROUTES.PROJECTS,
              action: authActions.logout,
            },
          ]
        : []),
    ],
    [authActions, loggedIn]
  );

  return (
    <AppBar
      component="nav"
      sx={{ bgcolor: '#192d52', zIndex: theme.zIndex.drawer + 1 }}
    >
      <Container>
        <Toolbar
          sx={{
            height: { sm: 50, md: 40 },
            minHeight: { xs: 'unset' },
            padding: { xs: 0 },
          }}
        >
          <Box sx={{ display: { xs: 'none', md: 'flex' }, width: '100%' }}>
            <Box>
              {leftNavItems.map(({ label, route }, index) => (
                <Button
                  key={`left-nav-item-${index}`}
                  sx={{ color: '#fff', py: 0.2 }}
                  onClick={() => navigate(route)}
                >
                  {label}
                </Button>
              ))}
            </Box>

            <Box sx={{ ml: 'auto' }}>
              {rightNavItems.map(({ label, route, action }, index) => (
                <Button
                  key={`right-nav-item-${index}`}
                  sx={{ color: '#fff', py: 0.2 }}
                  onClick={() => {
                    action ? action() : navigate(route);
                  }}
                >
                  {label}
                </Button>
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', md: 'none' },
              justifyContent: 'flex-end',
            }}
          >
            <IconButton
              size="large"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {[...leftNavItems, ...rightNavItems].map(
                ({ label, route, action }, index) => (
                  <MenuItem key={index} onClick={handleCloseNavMenu}>
                    <Button
                      key={`right-nav-item-${index}`}
                      fullWidth={true}
                      style={{ justifyContent: 'flex-start' }}
                      sx={{ py: 0.2 }}
                      onClick={() => {
                        action ? action() : navigate(route);
                      }}
                    >
                      {label}
                    </Button>
                  </MenuItem>
                )
              )}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
