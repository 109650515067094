export const appendZero = (num: number) => (num < 10 ? `0${num}` : num);

function isNumeric(n: any): boolean {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export const getFormattedTime = (time: number, remaning = false) => {
  const dateTime = new Date(0, 0, 0, 0, 0, time, 0);

  const dateTimeH = appendZero(dateTime.getHours());
  const dateTimeM = appendZero(dateTime.getMinutes());
  const dateTimeS = appendZero(dateTime.getSeconds());
  const minus = remaning ? '-' : '';

  return Number(dateTimeH) > 0
    ? `${minus}${dateTimeH}:${dateTimeM}:${dateTimeS}`
    : `${minus}${dateTimeM}:${dateTimeS}`;
};

export const getProgress = (currentTime: number, duration: number) => {
  if (isNumeric(currentTime) && isNumeric(duration)) {
    return parseFloat((100 * (currentTime / duration)).toString());
  }
  return 0;
};

export const getCurrentTime = (progress: number, duration: number) => {
  if (isNumeric(progress) && isNumeric(duration)) {
    return parseFloat(((progress * duration) / 100).toString());
  }
  return 0;
};

export const getRemaningTime = (progress: number, duration: number) => {
  if (isNumeric(progress) && isNumeric(duration)) {
    return parseFloat((((100 - progress) * duration) / 100).toString());
  }
  return 0;
};

export const formatDuration = (value: number) => {
  const minute = Math.floor(value / 60);
  const secondLeft = Math.floor(value - minute * 60);
  return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
};
