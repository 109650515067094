import {
  ComponentsLogic,
  GettingYourBearing,
  DownloadExamples,
  AdditionalResources,
} from '../components/resources';
import { ROUTES } from './route.const';

export const sources = {
  [ROUTES.MODULE_OBJECTIVES]: undefined,
  [ROUTES.GETTING_YOUR_BEARINGS]: <GettingYourBearing />,
  [ROUTES.THE_EVALUATION_ROADMAP]: undefined,
  [ROUTES.DIAGRAMS_YOU_WILL_CREATE_1]: undefined,
  [ROUTES.DIAGRAMS_YOU_WILL_CREATE_2]: undefined,
  [ROUTES.PROJECT_PROFILE]: undefined,
  [ROUTES.PROJECT_THEORY_DIAGRAM_1]: undefined,
  [ROUTES.PROJECT_THEORY_DIAGRAM_2]: <GettingYourBearing />,
  [ROUTES.CREATE_PROJECT_THEORY_DIAGRAM_1]: undefined,
  [ROUTES.CREATE_PROJECT_THEORY_DIAGRAM_2]: <GettingYourBearing />,
  [ROUTES.CREATE_PROJECT_THEORY_DIAGRAM_3]: undefined,
  [ROUTES.CREATE_PROJECT_THEORY_DIAGRAM_4]: <GettingYourBearing />,
  [ROUTES.CREATE_PROJECT_THEORY_DIAGRAM_5]: undefined,
  [ROUTES.PROJECT_THEORY_DIAGRAM_FOR_PROJECT]: undefined,
  [ROUTES.YOUR_EVALUATION_ROADMAP2]: undefined,
  [ROUTES.BENEFIT_OF_PORJECT_FLOW_DIAGRAM]: undefined,
  [ROUTES.CREATE_PROJECT_FLOW_DIAGRAM_1]: <ComponentsLogic />,
  [ROUTES.CREATE_PROJECT_FLOW_DIAGRAM_2]: <ComponentsLogic />,
  [ROUTES.CREATE_PROJECT_FLOW_DIAGRAM_3]: <ComponentsLogic />,
  [ROUTES.CREATE_PROJECT_FLOW_DIAGRAM_4]: <ComponentsLogic />,
  [ROUTES.CREATE_PROJECT_FLOW_DIAGRAM_5]: <ComponentsLogic />,
  [ROUTES.PROJECT_FLOW_DIAGRAM_FOR_PROJECT]: undefined,
  [ROUTES.YOUR_EVALUATION_ROADMAP3]: undefined,
  [ROUTES.CREATE_PROJECT_EVALUATION_PLAN]: undefined,
  [ROUTES.KNOW_YOUR_EVALUATION_FOCUS]: undefined,
  [ROUTES.IDENITIFY_STAKEHOLDERS_1]: undefined,
  [ROUTES.IDENITIFY_STAKEHOLDERS_2]: undefined,
  [ROUTES.CHOOSE_EVALUATION_QUESTIONS]: undefined,
  [ROUTES.ALIGN_EVALUATION_QUESTIONS]: undefined,
  [ROUTES.PROCESS_EVALUATION_QUESTIONS]: undefined,
  [ROUTES.EVALUATION_PLAN_IN_PROGRESS_1]: undefined,
  [ROUTES.EVALUATION_PLAN_IN_PROGRESS_2]: undefined,
  [ROUTES.DECIDING_ON_INDICATORS_OF_CHANGE]: <DownloadExamples />,
  [ROUTES.DECIDING_ON_INDICATORS_1]: undefined,
  [ROUTES.DECIDING_ON_INDICATORS_2]: <DownloadExamples />,
  [ROUTES.DATA_AND_DATA_COLLECTION_TIMING]: undefined,
  [ROUTES.OUTPUT_OR_PROCESS_DATA_EXAMPLE]: undefined,
  [ROUTES.OUTCOME_DATA_EXAMPLE]: undefined,
  [ROUTES.SELECT_YOUR_DATA_MEASURES_AND_TIMING]: undefined,
  [ROUTES.EVALUATION_PLAN_IN_PROGRESS_4]: undefined,
  [ROUTES.USING_EVALUATION_FINDINGS]: undefined,
  [ROUTES.PROJECT_EVALUATION_PLAN_FOR_PROJECT]: undefined,
  [ROUTES.GOOD_WORK]: undefined,
  [ROUTES.THANK_YOU]: <AdditionalResources />,
  [ROUTES.EVALUATION_FORM]: undefined,
};

export const getSourceContent = (pathname: string) => {
  const formattedPath = pathname?.endsWith('/')
    ? pathname.slice(0, -1)
    : pathname;
  const lastPath = formattedPath?.split('/')?.at(-1);
  if (lastPath) {
    return sources[lastPath] || undefined;
  } else {
    return undefined;
  }
};
