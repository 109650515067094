import React, { useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';

import { Grid, TextField, Typography } from '@mui/material';
import { useContentInputContext } from './ContentInputContext';
import { ContentOptions } from '../ContentOptions';
import { ContentMultiOptions } from '../ContentMultiOptions';

const defaultLabel = 'Type your content here';

type FormData = {
  content: string;
};

const MAX_WORD_COUNT = 45;

const getWordCount = (value: string = '') => value?.split(' ')?.length || 0;

export type ContentInputFormProps = {
  label?: string;
  dialogDescription?: string;
  value?: string;
  next?: (value: string) => void;
};

export const ContentInputForm = React.forwardRef(
  (
    {
      label = defaultLabel,
      dialogDescription = '',
      value = '',
      next = () => {},
    }: ContentInputFormProps,
    ref
  ) => {
    const { content, updateContextValue, type, optionProps, multiOptionProps } =
      useContentInputContext();
    const [optionValue, setOptionValues] = React.useState('');
    const [multiOptionValue, setMultiOptionValue] = React.useState<string[]>(
      []
    );
    const {
      register,
      handleSubmit,
      watch,
      formState: { errors },
    } = useForm<FormData>({
      defaultValues: { content },
      resolver: data => {
        if (getWordCount(data.content) <= MAX_WORD_COUNT) {
          return { errors: {}, values: data };
        }
        return {
          errors: { content: true },
          values: data,
        };
      },
    });

    const onSubmit = handleSubmit(data => {
      if (type === 'input') {
        updateContextValue({ content: data.content });
        next(data.content);
      } else if (type === 'options') {
        updateContextValue({ content: optionValue });
        next(optionValue);
      } else if (type === 'multi-options') {
        updateContextValue({ content: multiOptionValue?.join('\n') });
        next(multiOptionValue?.join('\n'));
      }
    });

    useImperativeHandle(ref, () => ({ onSubmit }), [onSubmit]);

    return (
      <form onSubmit={onSubmit}>
        <Grid container spacing={2}>
          {label && (
            <Grid item xs={12}>
              <Typography sx={{ fontSize: 14 }}>
                {dialogDescription || label}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            {type === 'input' && (
              <TextField
                fullWidth
                multiline
                rows={5}
                error={!!errors.content}
                helperText={`Words used: ${getWordCount(
                  watch().content
                )} of ${MAX_WORD_COUNT}`}
                {...register('content')}
              />
            )}
            {type === 'options' && !!optionProps && (
              <ContentOptions {...optionProps} onChange={setOptionValues} />
            )}
            {type === 'multi-options' && !!multiOptionProps && (
              <ContentMultiOptions
                {...multiOptionProps}
                onChange={setMultiOptionValue}
              />
            )}
          </Grid>
        </Grid>
      </form>
    );
  }
);
