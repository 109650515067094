import React from 'react';
import { Box, BoxProps, useTheme } from '@mui/material';
import { useMainLayoutContext } from './MainLayoutContext';

type PageContentContainerProps = BoxProps;

export const PageContentContainer = ({
  children,
}: PageContentContainerProps) => {
  const theme = useTheme();
  const { pageContainerSx = {} } = useMainLayoutContext();

  return (
    <Box
      sx={{
        py: 8,
        maxWidth: theme.breakpoints.values.sm,
        mx: 'auto',
        flex: 1,
        ...pageContainerSx,
      }}
    >
      {children}
    </Box>
  );
};
