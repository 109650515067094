import { Grid, useTheme } from '@mui/material';
import React from 'react';
import Image from 'mui-image';

import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';
import stepsPng from '../../../assets/images/pngs/evaluation-flow1-element.png';

export const Step2CreateProjectFlowDiagram1Page = () => {
  const theme = useTheme();
  const { updateContextValue } = useMainLayoutContext();

  React.useEffect(() => {
    updateContextValue({
      title: 'Basic components of the Project Flow Diagram',
      pageContainerSx: { maxWidth: theme.breakpoints.values.md },
    });

    return () => {
      updateContextValue({ title: undefined, pageContainerSx: undefined });
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Grid
      container
      direction="column"
      spacing={4}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item position="relative">
        <Image src={stepsPng} fit="contain" duration={500} />
      </Grid>
    </Grid>
  );
};
