import React from 'react';
import {
  Avatar,
  Box,
  Button,
  Container,
  Fab,
  Tooltip,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import printIcon from '../assets/images/pngs/print.png';
import resourcesIcon from '../assets/images/pngs/resources.png';
import messageIcon from '../assets/images/pngs/message.png';
import { useProjectStepContext } from '../contexts/ProjectStepContext';
import { AudioPlayer } from '../components';
import { useMainLayoutContext } from './MainLayoutContext';

export const ProjectStepFooter = () => {
  const {
    hideNextButton,
    hidePrevButton,
    onNext,
    onPrev,
    audioUrl,
    scriptContent,
    sourceContent,
    updateContextValue,
  } = useProjectStepContext();
  const { downloadPDF } = useMainLayoutContext();
  const leftActions = React.useMemo(
    () => [
      {
        label: 'PRINT',
        tooltip: 'Click here to print this screen',
        icon: printIcon,
        disabled: false,
        onClick: () => downloadPDF(),
      },
      {
        label: 'RESOURCES',
        icon: resourcesIcon,
        disabled: !sourceContent,
        onClick: () => updateContextValue({ showSourceModal: true }),
      },
      {
        label: 'SCRIPT',
        tooltip: 'Click here to view the script for this screen',
        icon: messageIcon,
        disabled: !scriptContent,
        onClick: () => updateContextValue({ showScriptModal: true }),
      },
    ],
    [updateContextValue, scriptContent, sourceContent, downloadPDF]
  );

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        px: { xs: 1, md: 12 },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        mx: { xs: -2, sm: -3 },
        width: 'auto',
        bgcolor: '#ececec',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {leftActions?.map(
          ({ label, icon, tooltip, disabled, onClick }, index) => (
            <Tooltip
              key={index}
              title={tooltip}
              placement="top"
              arrow
              sx={{ cursor: 'pointer' }}
            >
              <Button
                sx={{
                  flexDirection: 'column',
                  justifyContent: 'center',
                  pt: 2,
                  width: 100,
                  borderRadius: 0,
                  borderRight: 1,
                  borderColor: '#7bc5de',
                  opacity: disabled ? 0.3 : 1,
                }}
                disabled={disabled}
                onClick={onClick}
              >
                <Avatar
                  variant="square"
                  src={icon}
                  sx={{ img: { objectFit: 'contain' } }}
                />
                <Typography
                  variant="caption"
                  sx={{ fontWeight: 600, color: '#36495a', mt: 1 }}
                >
                  {label}
                </Typography>
              </Button>
            </Tooltip>
          )
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
        }}
      >
        {!!audioUrl && <AudioPlayer src={audioUrl} />}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
        gap={3}
      >
        {!hidePrevButton && (
          <Fab color="error" size="small" onClick={onPrev}>
            <ArrowBackIcon />
          </Fab>
        )}
        {!hideNextButton && (
          <Fab color="error" size="small" onClick={onNext}>
            <ArrowForwardIcon />
          </Fab>
        )}
      </Box>
    </Container>
  );
};
