import React, { useEffect } from 'react';
import {
  SxProps,
  Grid,
  Theme,
  Typography,
  TypographyProps,
  useTheme,
  Box,
  SvgIcon,
  Tooltip,
  TooltipProps,
} from '@mui/material';
import { motion } from 'framer-motion';

import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';

const sectionVariants = {
  visible: (i: number) => ({
    opacity: 1,
    width: '100%',
    transition: {
      delay: i - 1,
      duration: 2,
    },
  }),
  hidden: { opacity: 0, width: '100%' },
};

export const Step1ProjectTheoryDiagram2Page = () => {
  const theme = useTheme();
  const { updateContextValue } = useMainLayoutContext();

  useEffect(() => {
    updateContextValue({
      title: 'Basic components of the Project Theory Diagram',
      pageContainerSx: { maxWidth: theme.breakpoints.values.md },
    });

    return () => {
      updateContextValue({ title: undefined, pageContainerSx: undefined });
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Box sx={{ py: 6 }}>
      <Grid container sx={{ alignItems: 'center' }}>
        <Grid item sx={{ width: '30%' }} container>
          <motion.div
            custom={1}
            initial="hidden"
            variants={sectionVariants}
            animate="visible"
          >
            <Grid item xs={12}>
              <TextBox
                label={`Core Problem`}
                tooltip={
                  <Box sx={{ p: 1 }}>
                    <Typography variant="body2">
                      {`This is where you start. In no more then three sentences, you will explain the problem your project addresses. We suggest framing your core problem by answering these three questions:`}
                    </Typography>

                    <Typography
                      variant="subtitle2"
                      sx={{ mt: 1 }}
                    >{`1) What is wrong?`}</Typography>
                    <Typography variant="subtitle2">{`2) Why does it matter?`}</Typography>
                    <Typography variant="subtitle2">
                      {`3) What will your project do about it?`}
                    </Typography>
                  </Box>
                }
              />
            </Grid>

            <Grid item xs={12} sx={{ marginTop: '2px' }}>
              <TextBox
                label={`Community Needs`}
                tooltip={
                  <Box sx={{ p: 1 }}>
                    <Typography variant="body2">
                      {`Make your case stronger and more specific by referencing the community need that led you to work on this problem. This will help your evaluation plan later on as it can serve as a baseline.`}
                    </Typography>

                    <Typography variant="body2" sx={{ mt: 1 }}>
                      {`Part 2 - list the data sources connected to the need (e.g. community needs assesment, state, regional or local data).`}
                    </Typography>
                  </Box>
                }
              />
            </Grid>

            <Grid item xs={12} sx={{ marginTop: '2px' }}>
              <TextBox
                label={`Influential Factors`}
                tooltip={
                  <Box sx={{ p: 1 }}>
                    <Typography variant="body2">
                      {`What factors could influence the implementation and desired results of your projects?`}
                    </Typography>

                    <Typography variant="body2" sx={{ mt: 1 }}>
                      {`What supports the work and what might cause some barriers to reaching your desired results?`}
                    </Typography>

                    <Typography variant="body2" sx={{ mt: 1 }}>
                      {`Are there policies or other factors that could affect your program?`}
                    </Typography>

                    <Typography variant="body2" sx={{ mt: 1 }}>
                      {`Have other communities found success with the approach you plan to take?`}
                    </Typography>

                    <Typography variant="body2" sx={{ mt: 1 }}>
                      {`Have you created strong relationships to support the work?`}
                    </Typography>

                    <Typography variant="body2" sx={{ mt: 1 }}>
                      {`Are there barriers to your work?`}
                    </Typography>
                  </Box>
                }
              />
            </Grid>
          </motion.div>
        </Grid>

        <Grid item sx={{ flex: 1, mx: '2px' }}>
          <motion.div
            custom={2}
            initial="hidden"
            variants={sectionVariants}
            animate="visible"
          >
            <Box sx={{ bgcolor: '#EEB911', height: 18, width: '100%' }} />
          </motion.div>
        </Grid>

        <Grid item sx={{ width: '30%' }}>
          <motion.div
            custom={3}
            initial="hidden"
            variants={sectionVariants}
            animate="visible"
          >
            <TextBox
              label={`Intervention Strategies`}
              tooltip={
                <Box sx={{ p: 1 }}>
                  <Typography variant="body2">
                    {`Briefly describe the strategy your work will use to achieve the results you desire and the source for that strategy (for examples see Clinical Guidelines, CDC‘s The Community Guide, etc.)`}
                  </Typography>
                </Box>
              }
            />
          </motion.div>
        </Grid>

        <Grid item sx={{ flex: 1, mx: '2px' }}>
          <motion.div
            custom={4}
            initial="hidden"
            variants={sectionVariants}
            animate="visible"
          >
            <SvgIcon sx={{ fontSize: 48, color: '#EEB911' }}>
              <path d="M16 8V4l8 8-8 8v-4H0V8z" />
            </SvgIcon>
          </motion.div>
        </Grid>

        <Grid item sx={{ width: '30%' }}>
          <motion.div
            custom={5}
            initial="hidden"
            variants={sectionVariants}
            animate="visible"
          >
            <TextBox
              label={`Desired Results`}
              containerSx={{ minHeight: 90 }}
              tooltip={
                <Box sx={{ p: 1 }}>
                  <Typography variant="body2">
                    {`Idenitify your desired results, short term (3-12 months), intermediate (12-24 months), and long term (24+ months).`}
                  </Typography>
                </Box>
              }
            />
          </motion.div>
        </Grid>
      </Grid>
    </Box>
  );
};

type TextBoxProps = Omit<TypographyProps, 'chilren' | 'sx'> & {
  label?: string;
  tooltip?: TooltipProps['title'];
  containerSx?: SxProps<Theme>;
  textSx?: SxProps<Theme>;
};

const TextBox = ({
  label,
  tooltip = '',
  containerSx = {},
  textSx = {},
  ...other
}: TextBoxProps) => {
  return (
    <Tooltip title={tooltip} arrow>
      <Box
        sx={{
          bgcolor: '#EEB911',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          px: 2,
          py: 1.5,
          borderRadius: 2,
          cursor: 'pointer',
          ...containerSx,
        }}
      >
        <Typography
          variant="h6"
          sx={{ textAlign: 'center', ...textSx }}
          {...other}
        >
          {label}
        </Typography>
      </Box>
    </Tooltip>
  );
};
