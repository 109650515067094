import { Grid, Link, Typography, useTheme } from '@mui/material';
import React from 'react';
import Image from 'mui-image';
import logoPng from '../../../assets/images/pngs/logo.png';
import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';

export const Step3ThankYouPage = () => {
  const theme = useTheme();
  const { updateContextValue } = useMainLayoutContext();

  React.useEffect(() => {
    updateContextValue({
      title:
        'Thank you for participating. Please share your feedback on the next slide.',
      pageContainerSx: { maxWidth: theme.breakpoints.values.md },
      pageTitleSx: {
        textAlign: 'center',
        color: undefined,
        px: { md: '20%', xs: 0 },
      },
    });

    return () => {
      updateContextValue({
        title: undefined,
        pageContainerSx: undefined,
        pageTitleSx: undefined,
      });
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Grid container rowGap={8}>
      <Grid container textAlign="center" sx={{ mt: 4 }}>
        <Grid item xs={3} />
        <Grid item xs={6} textAlign="center">
          <Typography variant="h6">This module was developed by</Typography>
          <Typography
            variant="h6"
            component={Link}
            target="_blank"
            href="https://coloradosph.cuanschutz.edu/research-and-practice/centers-programs/cepeg"
          >
            {`Community Epidemiology and Program Evaluation Group`}
          </Typography>
          <Typography variant="h6" component="span">
            {' '}
            {`(CEPEG) of the Colorado School of Public Health`}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3} sx={{ mt: { xs: 4, sm: 0 } }}>
          <Image
            width={100}
            height={100}
            wrapperStyle={{ display: 'inline-block' }}
            style={{ objectFit: 'contain' }}
            src={logoPng}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent="center">
        <Grid
          container
          item
          xs={6}
          textAlign="center"
          justifyContent="center"
          spacing={4}
        >
          <Grid item xs={12}>
            <Typography sx={{ mb: 1 }}>
              If you have questions regarding this module or your project
              diagrams, please email CEPEG TA via email:{' '}
            </Typography>
            <Typography>
              For STEPP grantees:{' '}
              <Link
                component="a"
                underline="hover"
                href={`mailto:EvaluationTA@UCDenver.edu`}
                sx={{ fontWeight: 600, cursor: 'pointer' }}
              >
                EvaluationTA@UCDenver.edu
              </Link>
            </Typography>
            <Typography>
              For CCPD grantees:{' '}
              <Link
                component="a"
                underline="hover"
                href={`mailto:CEPEGEvaluationTA@CUAnschutz.edu`}
                sx={{ fontWeight: 600, cursor: 'pointer' }}
              >
                CEPEGEvaluationTA@CUAnschutz.edu
              </Link>
            </Typography>
            <Typography sx={{ mt: 1 }}>
              You will receive a response within 3 business days.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Link
              component="a"
              underline="hover"
              href={`#`}
              sx={{ fontWeight: 500, cursor: 'pointer' }}
            >
              Please click here to access our library of our Project Planning
              and Evaluation diagrams to assist you with your next project.
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
