import React, { PropsWithChildren } from 'react';
import { Box, Tooltip, TooltipProps, Typography } from '@mui/material';

type Item = {
  text?: string;
  tooltip?: TooltipProps['title'];
};

type DescriptionProps = {
  items?: Item[];
  greenText?: string;
};

export const Description = ({
  items = [],
  greenText: _greenText,
}: DescriptionProps) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <Typography component="div">
      {items.map(({ text, tooltip }, index) => {
        const Wrapper = tooltip ? TooltipWrapper : EmptyWrapper;

        return (
          <Wrapper key={`combined-text-seg-${index}`} tooltip={tooltip}>
            <Typography
              component="span"
              sx={{ color: 'red', fontWeight: 600, fontSize: 20 }}
            >
              {text}
            </Typography>
          </Wrapper>
        );
      })}
    </Typography>
  );
};

type TooltipWrapperProps = {
  tooltip?: TooltipProps['title'];
  children?: any;
};

const TooltipWrapper = ({ tooltip = '', children }: TooltipWrapperProps) => {
  const title =
    typeof tooltip === 'string' ? (
      <Box sx={{ p: 1 }}>
        <Typography variant="body2">{tooltip}</Typography>
      </Box>
    ) : (
      tooltip
    );

  return (
    <>
      {children ? (
        <Tooltip title={title} arrow>
          <Typography
            component="span"
            sx={{
              textDecoration: 'underline',
              cursor: 'pointer',
              color: 'red',
            }}
          >
            {children}
          </Typography>
        </Tooltip>
      ) : null}
    </>
  );
};

const EmptyWrapper = (props: PropsWithChildren<{}>) => <>{props.children}</>;
