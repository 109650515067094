import { Project } from '../@types';
import { ROUTES } from '../consts/route.const';

export const validateProjectStepFields = (route: string, project: Project) => {
  const projectId = project?.id;
  if (!projectId) {
    return true;
  }
  switch (route) {
    case `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.IDENITIFY_STAKEHOLDERS_2}`:
      const requiredFields = [
        'contact_served_option',
        'contact_served_specific',
        'contact_involved_option',
        'contact_involved_specific',
        'contact_influence_option',
        'contact_influence_specific',
      ];
      let validated = true;
      requiredFields.forEach(field => {
        if (!(project as any)?.[field]?.trim()) {
          validated = false;
        }
      });
      return validated;
    default:
      break;
  }
  return true;
};
