import React from 'react';

import {
  AudioPlayerContextProvider,
  AudioPlayerContextProviderProps,
} from './AudioPlayerContext';
import { AudioPlayerUI } from './AudioPlayerUI';

export type AudioPlayerProps = AudioPlayerContextProviderProps;

export const AudioPlayer = (props: AudioPlayerProps) => {
  return (
    <AudioPlayerContextProvider {...props}>
      <AudioPlayerUI />
    </AudioPlayerContextProvider>
  );
};
