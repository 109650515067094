import React from 'react';

import {
  ContentInputContextProvider,
  ContentInputContextProviderProps,
} from './ContentInputContext';
import { ContentInputUI } from './ContentInputUI';

export type ContentInputProps = ContentInputContextProviderProps;

export const ContentInput = (props: ContentInputProps) => {
  return (
    <ContentInputContextProvider {...props}>
      <ContentInputUI />
    </ContentInputContextProvider>
  );
};
