import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Slide,
  Typography,
  useTheme,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Close } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

interface Props {
  visible: boolean;
  onRedirect?: () => void;
  onClose?: () => void;
}

export const RedirectModal = ({
  visible,
  onRedirect,
  onClose = () => {},
}: Props) => {
  const theme = useTheme();
  return (
    <Dialog
      fullWidth
      maxWidth={'sm'}
      open={visible}
      onClose={onClose}
      sx={{
        '.MuiDialog-container': { alignItems: 'flex-start', marginTop: '10%' },
      }}
      TransitionComponent={Transition}
    >
      <DialogTitle
        sx={{
          bgcolor: theme.palette.primary.main,
          display: 'flex',
          alignItems: 'center',
          py: 2,
          position: 'relative',
        }}
        component="div"
      >
        <Box
          sx={{
            position: 'absolute',
            inset: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="h6"
            component="span"
            textAlign="center"
            sx={{
              color: 'white',
            }}
          >
            Course started
          </Typography>
        </Box>

        <IconButton
          size="small"
          sx={{ ml: 'auto' }}
          edge="end"
          onClick={onClose}
        >
          <Close sx={{ fontSize: 'inherit', color: 'white' }} />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{ pt: '20px !important', pb: 2, maxHeight: 400, overflow: 'auto' }}
      >
        Would you like to be redirected to your last visited page?
      </DialogContent>
      <Divider />
      <DialogContent sx={{ py: 1.5, textAlign: 'right' }}>
        <Button variant="contained" onClick={onRedirect}>
          REDIRECT
        </Button>
      </DialogContent>
    </Dialog>
  );
};
