import React, { useEffect } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';

import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';
import { ExampleView } from './ExampleView';
import { Description } from './Description';
import { ContentInput } from '../../../components';
import { useProjectContext } from '../../../contexts';

const descriptionItems = [
  {
    text: `Identify Desired Results (`,
  },
  {
    text: 'short-term',
    tooltip: `Short-term objectives are typically about a change in knowledge and happen over three to 12 months.`,
  },
  {
    text: `, `,
  },
  {
    text: 'intermediate',
    tooltip: `Intermediate objectives are often about action—for example, passing a policy or integrating a new protocol.`,
  },
  {
    text: `, and `,
  },
  {
    text: 'long-term',
    tooltip: `Long-term objectives focus on changes in the health status of a population—for example, morbidity and mortality. They are often measured by methods such as statewide surveillance and registration systems.`,
  },
  {
    text: `).`,
  },
  {
    text: `). These timelines are rough estimates and can vary depending on the project.`,
  },
];

const exampleTooltip = (
  <Box sx={{ p: 1 }}>
    <Typography variant="body2" sx={{ color: '#ffd769', fontWeight: 600 }}>
      POLICY EXAMPLE
    </Typography>
    <Typography variant="body2" sx={{ mt: 0.5, color: '#ffd769' }}>
      {`Short term (3-12 months): Increase X's knowledge of the importance of regular compliance checks.`}
    </Typography>

    <Typography variant="body2" sx={{ mt: 0.5, color: '#ffd769' }}>
      {`Intermediate-term 12-24 months): Successful passage of policy X.`}
    </Typography>

    <Typography variant="body2" sx={{ mt: 0.5, color: '#ffd769' }}>
      {`Long-term (24+months): Reduce youth tobacco use rates by X% (measure by HKCS].`}
    </Typography>
    <Typography
      variant="body2"
      sx={{ color: '#ffd769', fontWeight: 600, mt: 1 }}
    >
      SYSTEMS EXAMPLE
    </Typography>
    <Typography variant="body2" sx={{ mt: 0.5, color: '#ffd769' }}>
      {`Short term (3-12 months): Increase # referral systems between local providers  and diabetes prevention programs in our community.`}
    </Typography>

    <Typography variant="body2" sx={{ mt: 0.5, color: '#ffd769' }}>
      {`Intermediate-term 12-24 months): Increase the # of Black/African-Americans at risk for diabetes who are referred to and enrolled in community diabetes prevention programs.`}
    </Typography>

    <Typography variant="body2" sx={{ mt: 0.5, color: '#ffd769' }}>
      {`Long-term (24+months): Increase healthy behaviors among program participants`}
    </Typography>
  </Box>
);

export const Step1CreateProjectTheoryDiagram5Page = () => {
  const theme = useTheme();
  const { updateContextValue } = useMainLayoutContext();
  const {
    project,
    projectActions: { updateProject },
  } = useProjectContext();

  useEffect(() => {
    updateContextValue({
      title: 'Identify Desired Results',
      pageContainerSx: { maxWidth: theme.breakpoints.values.md },
    });

    return () => {
      updateContextValue({ title: undefined, pageContainerSx: undefined });
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container spacing={4} sx={{ alignItems: 'flex-start' }}>
      <Grid item xs={12} sm={7} md={8} lg={9} container spacing={4}>
        <Grid item xs={12}>
          <Description items={descriptionItems} />
        </Grid>

        <Grid item xs={12}>
          <Typography>
            5. What will this project achieve at the time intervals? (20 words
            or less for short-term, 25 words or less for intermediate-term, and
            25 words or less for long-term).
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <ContentInput
            label={`A. Short-term (3 to 12 months)`}
            dialogTitle="Community Need"
            content={project?.short_term_goal}
            handleSubmit={value =>
              updateProject({ id: project?.id, short_term_goal: value })
            }
          />
        </Grid>

        <Grid item xs={12}>
          <ContentInput
            label={`B. Intermediate-term (12 to 24 months)`}
            dialogTitle="Community Need Specifics"
            content={project?.intermediate_term_goal}
            handleSubmit={value =>
              updateProject({ id: project?.id, intermediate_term_goal: value })
            }
          />
        </Grid>

        <Grid item xs={12}>
          <ContentInput
            label={`C. Long-term (24+ months)`}
            dialogTitle="Community Need Specifics"
            content={project?.long_term_goal}
            handleSubmit={value =>
              updateProject({ id: project?.id, long_term_goal: value })
            }
          />
        </Grid>
      </Grid>

      <Grid item xs={12} sm={5} md={4} lg={3}>
        <ExampleView tooltipTitle={exampleTooltip} />
      </Grid>
    </Grid>
  );
};
