import { Typography } from '@mui/material';
import React from 'react';
import { useMainLayoutContext } from '../layouts/MainLayoutContext';

type PageTitleProps = {
  title?: string;
};

export const PageTitle = ({ title = '' }: PageTitleProps) => {
  const { pageTitleSx = {} } = useMainLayoutContext();
  return (
    <Typography
      variant="h5"
      sx={{ color: '#fb3034', fontWeight: 500, ...pageTitleSx }}
    >
      {title}
    </Typography>
  );
};
