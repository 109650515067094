import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import React from 'react';
import ArrowBack from '@mui/icons-material/ArrowBackIos';
import ArrowForward from '@mui/icons-material/ArrowForwardIos';

import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';
import imgEstimate from '../../../assets/images/pngs/benefits_slide_estimate.png';
import imgIdentify from '../../../assets/images/pngs/benefits_slide_identify.png';
import imgClarify from '../../../assets/images/pngs/benefits_slide_clarify.png';
import imgTodo from '../../../assets/images/pngs/benefits_slide_todo.png';
import imgFocus from '../../../assets/images/pngs/benefits_slide_focus.png';
import imgWrite from '../../../assets/images/pngs/benefits_slide_write.png';
import imgResult from '../../../assets/images/pngs/benefits_slide_result.png';
import imgPriority from '../../../assets/images/pngs/benefits_slide_priorities.png';
import { GTypography } from '../../../components';

const carouselData = [
  { label: 'Estimate timelines', image: imgEstimate },
  { label: 'Identify necessary partnerships', image: imgIdentify },
  { label: 'Negotiate roles and responsibilites', image: imgTodo },
  {
    label: 'Focus discussions and make planning time more efficient',
    image: imgFocus,
  },
  { label: 'Clarify a project strategy', image: imgClarify },
  {
    label: 'Help to write a grant proposal and/or track progress',
    image: imgWrite,
  },
  {
    label: 'Access the potential effectivness of an approach',
    image: imgResult,
  },
  { label: 'Set priorities for allocating resources', image: imgPriority },
];

export const Step2BenefitOfProjectFlowDiagramPage = () => {
  const theme = useTheme();
  const [index, setIndex] = React.useState(0);
  const { updateContextValue } = useMainLayoutContext();

  React.useEffect(() => {
    updateContextValue({
      title: 'Benefits of a Project Flow Diagram',
      pageContainerSx: { maxWidth: theme.breakpoints.values.md },
    });

    return () => {
      updateContextValue({ title: undefined, pageContainerSx: undefined });
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container alignItems="center" justifyContent="center">
      <IconButton onClick={() => setIndex(index - 1)} disabled={index === 0}>
        <ArrowBack />
      </IconButton>
      <SwipeableViews
        index={index}
        autoPlay
        onChangeIndex={setIndex}
        style={{ width: 338 }}
      >
        {carouselData.map((item, index) => (
          <Box key={index}>
            <Avatar
              variant="square"
              src={item.image}
              sx={{ width: 338, height: 298 }}
            />
            <Typography sx={{ textAlign: 'center', px: 2, mt: 2 }}>
              {item.label}
            </Typography>
          </Box>
        ))}
      </SwipeableViews>
      <IconButton
        onClick={() => setIndex(index + 1)}
        disabled={index === carouselData.length - 1}
      >
        <ArrowForward />
      </IconButton>
      <Grid item xs={12}>
        <GTypography
          sx={{
            textAlign: 'center',
            px: 2,
            mt: 2,
            lineHeight: 0.5,
            fontWeight: 500,
          }}
        >
          {`Click through the gallery to see how a Project Flow\nDiagram benefits your project`}
        </GTypography>
      </Grid>
    </Grid>
  );
};
