import { AxiosRequestConfig, Method } from 'axios';
import React from 'react';
import { EvaluationError } from '../@types';
import { AxiosClient, mapError } from '../utils';

interface UseApiOptions extends AxiosRequestConfig {
  method?: Method;
  onSuccess?: (data: any) => void;
  onFailure?: (error: EvaluationError) => void;
}

const defaultOptions: UseApiOptions = {
  method: 'GET',
  onSuccess: () => {},
  onFailure: () => {},
};

export const useApi = (
  key: string,
  url: string,
  options: UseApiOptions = defaultOptions
) => {
  const [loading, setLoading] = React.useState<{ [symbol: string]: boolean }>(
    {}
  );

  const [data, setData] = React.useState<{ [symbol: string]: any }>({});
  const [error, setError] = React.useState<{
    [symbol: string]: EvaluationError | undefined;
  }>({});

  const call = React.useCallback(
    async (requestData: any) => {
      setError(prev => ({ ...prev, [key]: undefined }));
      setLoading(prev => ({ ...prev, [key]: true }));
      try {
        const { data: response } = await AxiosClient.request({
          ...options,
          url,
          data: requestData,
        });
        options.onSuccess && options.onSuccess(response);
        if (response) {
          setData(prev => ({ ...prev, [key]: response }));
        }
      } catch (error) {
        options.onFailure && options.onFailure(mapError(error));
        setError(prev => ({ ...prev, [key]: mapError(error) }));
      } finally {
        setLoading(prev => ({ ...prev, [key]: false }));
      }
    },
    [key, url, options]
  );

  return {
    loading: loading[key] || false,
    data: data[key],
    error: error[key],
    call,
  };
};
