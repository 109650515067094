import { Grid, Link } from '@mui/material';
import React from 'react';

export const ComponentsLogic = () => {
  return (
    <Grid container>
      <Link
        sx={{ fontWeight: 500, textDecoration: 'none' }}
        target="_blank"
        href="https://www.cdc.gov/std/Program/pupestd/Components%20of%20a%20Logic%20Model.pdf"
      >
        Components of a Logic Model.
      </Link>
    </Grid>
  );
};
