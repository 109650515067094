import React from 'react';
import PauseRounded from '@mui/icons-material/PauseRounded';
import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded';
import { IconButton, IconButtonProps } from '@mui/material';

interface AudioPlayButtonProps extends IconButtonProps {
  paused: boolean;
}

export const AudioPlayButton = ({ paused, ...rest }: AudioPlayButtonProps) => {
  return (
    <IconButton {...rest}>
      {!paused ? (
        <PauseRounded color="primary" fontSize="large" />
      ) : (
        <PlayArrowRounded color="primary" fontSize="large" />
      )}
    </IconButton>
  );
};
