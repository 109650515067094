import React from 'react';
import { Box, SxProps, Grid, Typography, useTheme } from '@mui/material';

import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';
import { ContentInput } from '../../../components';
import { useProjectContext } from '../../../contexts';
import { EVALUATION_PLAN_HEADERS } from '../../../consts';

const boxStyle: SxProps = { minHeight: 200, bgcolor: '#00ABD7' };

export const Step3EvaluationPlanInProgress2Page = () => {
  const theme = useTheme();
  const { updateContextValue } = useMainLayoutContext();
  const { project } = useProjectContext();

  const contentList = React.useMemo(
    () => [
      [
        {
          label: 'Which stakeholder group and specific contact?',
          value: project?.contact_served_option
            ? `${project?.contact_served_option}\n${project?.contact_served_specific}`
            : '',
        },
        {
          label: 'Who in project operations and specific name(s)?',
          value: project?.contact_involved_option
            ? `${project?.contact_involved_option}\n${project?.contact_involved_specific}`
            : '',
        },
        {
          label: 'Who to influence the most, and specific contact?',
          value: project?.contact_influence_option
            ? `${project?.contact_influence_option}\n${project?.contact_influence_specific}`
            : '',
        },
      ],
      [
        {
          label: '',
          value: project?.question_resources,
        },
        {
          label: '',
          value: project?.question_activities,
        },
        {
          label: '',
          value: project?.question_participants,
        },
        {
          label: '',
          value: project?.question_intermediate,
        },
        {
          label: '',
          value: project?.question_short_term,
        },
        {
          label: '',
          value: project?.question_long_term,
        },
      ],
      [],
      [],
      [],
      [],
    ],
    [project]
  );

  React.useEffect(() => {
    updateContextValue({
      title: 'The Project Evaluation Plan in Progress',
      pageContainerSx: { maxWidth: theme.breakpoints.values.md },
    });

    return () => {
      updateContextValue({ title: undefined, pageContainerSx: undefined });
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Grid container alignItems="flex-start" sx={{ height: '100%' }}>
      <Grid item container sx={{ mt: 4 }}>
        {EVALUATION_PLAN_HEADERS.map((item, index) => (
          <Grid item xs={2} alignSelf="end" key={index}>
            <Typography
              textAlign="center"
              fontWeight={500}
              fontSize={{ xs: 10, sm: 14, md: 16 }}
            >
              {item}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid container columnSpacing={1} sx={{ position: 'relative' }}>
        <Box
          sx={{
            display: { xs: 'none', sm: 'block' },
            position: 'absolute',
            inset: 0,
            top: 90,
            left: 40,
            width: '98%',
            height: 20,
            bgcolor: '#00ABD7',
            zIndex: 0,
          }}
        />
        <Box
          sx={{
            display: { xs: 'none', sm: 'block' },
            position: 'absolute',
            top: 80,
            left: '102%',
            borderTop: '20px solid transparent',
            borderBottom: '20px solid transparent',
            borderLeft: '20px solid #00ABD7',
            zIndex: 0,
          }}
        />
        {contentList.map((data, index) => (
          <Grid item xs={2} key={index} sx={{ zIndex: 1 }}>
            <Box
              sx={{
                ...boxStyle,
                p: 0.5,
                borderStyle: 'solid',
                borderColor: 'white',
              }}
            >
              {data.map((item, iIndex) => (
                <ContentInput
                  key={iIndex}
                  label={item?.label || ''}
                  placeholder=" "
                  content={item?.value || ''}
                  editable={false}
                  styles={{
                    label: {
                      color: 'white',
                      mb: 0,
                      fontWeight: 500,
                      fontSize: 14,
                    },
                    contentViewer: {
                      container: {
                        minHeight: 10,
                        bgcolor: '#99CAEC',
                        px: 1,
                        py: 1,
                        mb: 1,
                      },
                      value: { fontSize: 12, fontWeight: 500 },
                    },
                  }}
                />
              ))}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
