import { IsEmail, IsNotEmpty, IsString } from 'class-validator';

export class HelpFormDto {
  @IsString()
  @IsNotEmpty({ message: 'Add name' })
  public name: string;

  @IsEmail({}, { message: 'Invalid email address' })
  @IsNotEmpty({ message: 'Add Email' })
  public email: string;

  @IsString()
  @IsNotEmpty({ message: 'Add description' })
  public description: string;
}
