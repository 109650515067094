import React from 'react';
import {
  Box,
  BoxProps,
  CircularProgress,
  CircularProgressProps,
} from '@mui/material';

interface Props {
  visible: boolean;
  styles?: {
    container?: BoxProps['sx'];
    circle?: CircularProgressProps['sx'];
  };
  size?: CircularProgressProps['size'];
}

export const LoadingView = ({ visible, size, styles }: Props) => {
  if (!visible) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        inset: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 100,
        backgroundColor: '#00000050',
        ...styles?.container,
      }}
    >
      <CircularProgress
        size={size}
        sx={{ color: 'white', ...styles?.circle }}
      />
    </Box>
  );
};
