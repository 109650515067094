import { Box, Paper, Slider, SxProps } from '@mui/material';
import React from 'react';

interface VolumeSliderProps {
  volumeAmount: number;
  onSeek?: (value: number) => void;
  containerStyle?: SxProps;
}

export const VolumeSlider = ({
  volumeAmount,
  containerStyle,
  onSeek,
}: VolumeSliderProps) => {
  return (
    <Box
      component={Paper}
      elevation={1}
      className="volume-slider"
      sx={{
        display: 'none',
        position: 'absolute',
        height: 120,
        bottom: '100%',
        left: '10%',
        py: 2,
        backgroundColor: 'white',
        borderRadius: 2,
        ...containerStyle,
      }}
    >
      <Slider
        size="small"
        orientation="vertical"
        value={volumeAmount * 100}
        min={0}
        step={1}
        max={100}
        onChange={(_, value) => onSeek && onSeek((value as number) / 100)}
        sx={{
          '& input[type="range"]': {
            WebkitAppearance: 'slider-vertical',
          },
          color: '#0297d0',
          height: '100%',
          '& .MuiSlider-thumb': {
            width: 8,
            height: 8,
            transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
            '&:before': {
              boxShadow: '0 2px 12px 0 #0297d0',
            },
            '&:hover, &.Mui-focusVisible': {
              boxShadow: `0px 0px 0px 4px ${'#0297d0'}`,
            },
            '&.Mui-active': {
              width: 10,
              height: 10,
              boxShadow: `0px 0px 0px 4px ${'#0297d010'}`,
            },
          },
          '& .MuiSlider-rail': {
            opacity: 0.28,
          },
        }}
      />
    </Box>
  );
};
