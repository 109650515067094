import React, { ForwardedRef } from 'react';
import { DialogContent as MuiDialogContent } from '@mui/material';

import { ContentInputForm, ContentInputFormProps } from './ContentInputForm';

export type DialogContentProps = {
  contentInputFormProps?: ContentInputFormProps;
  formRef?: ForwardedRef<unknown>;
};

export const DialogContent = ({
  contentInputFormProps = {},
  formRef,
}: DialogContentProps) => {
  return (
    <MuiDialogContent sx={{ mt: 2 }}>
      <ContentInputForm ref={formRef} {...contentInputFormProps} />
    </MuiDialogContent>
  );
};
