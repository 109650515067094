import React from 'react';
import { SxProps, Theme, useTheme } from '@mui/material';
import { GTypography } from '../GTypoGraphy';

export type LabelProps = {
  value?: string;
  sx?: SxProps<Theme>;
};

export const Label = ({ value = '', sx = {} }: LabelProps) => {
  const theme = useTheme();

  return (
    <>
      {value && (
        <GTypography
          sx={{
            fontSize: 14,
            color: theme.palette.text.secondary,
            ...sx,
          }}
        >
          {value}
        </GTypography>
      )}
    </>
  );
};
