import React from 'react';
import {
  DialogTitle,
  IconButton,
  IconButtonProps,
  Typography,
  useTheme,
} from '@mui/material';
import { Close } from '@mui/icons-material';

const defaultTitle = 'Input your content';

export type DialogHeaderProps = {
  title?: string;
  onClose?: IconButtonProps['onClick'];
};

export const DialogHeader = ({
  title = defaultTitle,
  onClose = () => {},
}: DialogHeaderProps) => {
  const theme = useTheme();

  return (
    <DialogTitle
      sx={{
        bgcolor: theme.palette.primary.main,
        display: 'flex',
        alignItems: 'center',
        py: 1,
      }}
      component="div"
    >
      <Typography sx={{ color: 'white', fontSize: 14, fontWeight: 600 }}>
        {title}
      </Typography>

      <IconButton size="small" sx={{ ml: 'auto' }} edge="end" onClick={onClose}>
        <Close sx={{ fontSize: 'inherit', color: 'white' }} />
      </IconButton>
    </DialogTitle>
  );
};
