import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import React from 'react';

export interface ContentOptionProps {
  list: Array<{ label: string; value: number }>;
  hasCustomOption?: boolean;
  multiLineEdit?: boolean;
  selected: string | null;
  onChange?: (value: string) => void;
}

export const ContentOptions = ({
  list = [],
  hasCustomOption = false,
  multiLineEdit = true,
  selected = null,
  onChange = () => {},
}: ContentOptionProps) => {
  const [option, setOption] = React.useState<number | null>(null);
  const [customValue, setCustomValue] = React.useState<string>(
    option === list?.length - 1 ? selected || '' : ''
  );

  React.useEffect(() => {
    if (selected) {
      const value = Number(list?.find(item => item.label === selected)?.value);
      setOption(value >= 0 ? value : list?.length - 1);
    } else {
      setOption(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  React.useEffect(() => {
    if (
      option === list?.length - 1 &&
      !list?.some(({ label }) => label === selected)
    ) {
      setCustomValue(selected || '');
    }
  }, [option, selected, list]);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const itemValue = Number(event.target.value);
      const label = list?.find(item => item.value === Number(itemValue))?.label;
      setOption(itemValue);

      if (hasCustomOption && itemValue === list?.length - 1) {
        return;
      }
      onChange(label!);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list, onChange]
  );

  return (
    <FormControl fullWidth>
      <RadioGroup onChange={handleChange} value={option}>
        {list.map(({ value, label, bold }: any, oIndex) => (
          <FormControlLabel
            key={oIndex}
            value={value}
            control={<Radio size="small" />}
            label={label}
            sx={{
              span: {
                py: 0.2,
                fontSize: 14,
                fontWeight: oIndex === list?.length - 1 || bold ? 600 : 500,
              },
            }}
          />
        ))}
      </RadioGroup>
      {hasCustomOption && option === list.length - 1 && (
        <TextField
          multiline={multiLineEdit}
          sx={{ mt: 1 }}
          rows={3}
          value={customValue}
          onChange={e => setCustomValue(e.target.value)}
          onBlur={e =>
            e.target.value &&
            e.target.value !== selected &&
            onChange(e.target.value)
          }
        />
      )}
    </FormControl>
  );
};
