import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Grid, Link, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';

import { ROUTES } from '../consts';
import { ForgotPasswordDto } from '../@types';
import { useApi } from '../contexts';
import { ConfirmDialog, LoadingView } from '../components';

const resolver = classValidatorResolver(ForgotPasswordDto);

export const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const [showSuccessDialog, setShowSuccessDialog] = React.useState(false);
  const { call, data, loading, error } = useApi(
    'forgot-password',
    '/auth/forgot',
    {
      method: 'POST',
      onSuccess: () => {
        setShowSuccessDialog(true);
      },
    }
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordDto>({
    resolver,
    defaultValues: { email: '', type: 'forgot' },
  });

  const onSubmit = React.useCallback(
    (data: ForgotPasswordDto) => {
      call({
        username: data.email,
        type: data.type,
      });
    },
    [call]
  );

  return (
    <Grid
      container
      spacing={3}
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Email"
          required
          autoComplete="email"
          error={!!errors.email}
          helperText={errors.email?.message}
          {...register('email')}
        />
      </Grid>

      <Grid item xs={12}>
        <Button variant="contained" type="submit">
          Recover Password
          <LoadingView visible={loading} size={20} />
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Link
          component="span"
          underline="hover"
          sx={{ cursor: 'pointer' }}
          onClick={() => navigate(`/${ROUTES.AUTH}/${ROUTES.LOGIN}`)}
        >
          Back to sign in
        </Link>
      </Grid>
      {!!error && (
        <Grid item xs={12}>
          <Alert variant="outlined" severity="error">
            {error?.message}
          </Alert>
        </Grid>
      )}
      <ConfirmDialog
        open={!!data && showSuccessDialog}
        description={data?.message}
        title="Success"
        hideCancelButton
        confirmTitle="Ok"
        onClose={() => setShowSuccessDialog(false)}
        onConfirm={() => setShowSuccessDialog(false)}
      />
    </Grid>
  );
};
