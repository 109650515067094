import { Grid, useTheme } from '@mui/material';
import Image from 'mui-image';
import React from 'react';

import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';
import bgImage from '../../../assets/images/pngs/step3_right_evaluation_image.jpg';

export const Step3ChooseEvaluationQuestionsPage = () => {
  const theme = useTheme();
  const { updateContextValue } = useMainLayoutContext();

  React.useEffect(() => {
    updateContextValue({
      title: 'Choose the Right Evaluation Questions',
      pageContainerSx: { maxWidth: theme.breakpoints.values.md },
    });

    return () => {
      updateContextValue({ title: undefined, pageContainerSx: undefined });
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Grid container justifyContent="center">
      <Grid item xs={10} sx={{ mt: 2 }}>
        <Image src={bgImage} style={{ maxWidth: 550 }} />
      </Grid>
    </Grid>
  );
};
