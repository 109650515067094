import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Tooltip, Link, ListItemText } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';

import { NavItemProps } from './types';
import { StyledItem, StyledIcon, StyledDotIcon } from './styles';

export const NavItem = ({
  item,
  depth,
  open,
  active,
  isExternalLink,
  tooltip,
  ...other
}: NavItemProps) => {
  const { title, path, icon, info, children, disabled, caption } = item;

  const subItem = depth !== 1;

  const renderContent = (
    <StyledItem
      depth={depth}
      active={active}
      disabled={disabled}
      caption={!!caption}
      {...other}
    >
      {icon && <StyledIcon>{icon}</StyledIcon>}

      {subItem && (
        <StyledIcon>
          <StyledDotIcon active={active && subItem} />
        </StyledIcon>
      )}

      <ListItemText
        primary={title}
        secondary={
          caption &&
          (tooltip ? (
            <Tooltip
              title={caption}
              placement="bottom-start"
              arrow
              sx={{ padding: 10 }}
            >
              <span>{caption}</span>
            </Tooltip>
          ) : (
            <span>{caption}</span>
          ))
        }
        primaryTypographyProps={{
          noWrap: true,
          component: 'span',
          color: theme =>
            active ? theme.palette.primary.main : theme.palette.text.primary,
          variant: active ? 'subtitle2' : 'body2',
          lineHeight: 1.4,
        }}
        secondaryTypographyProps={{
          noWrap: true,
          variant: 'caption',
          color: theme =>
            active ? theme.palette.primary.main : theme.palette.text.primary,
          lineHeight: 1.4,
        }}
      />

      {info && (
        <Box component="span" sx={{ lineHeight: 0 }}>
          {info}
        </Box>
      )}

      {!!children && (
        <ChevronRight
          sx={{
            transition: 'all 200ms',
            ...(open ? { transform: 'rotate(90deg)' } : {}),
          }}
        />
      )}
    </StyledItem>
  );

  const renderItem = () => {
    // ExternalLink
    if (isExternalLink)
      return (
        <Link href={path} target="_blank" rel="noopener" underline="none">
          {renderContent}
        </Link>
      );

    // Has child
    if (children) {
      return renderContent;
    }

    // Default
    return (
      <Link component={RouterLink} to={path} underline="none">
        {renderContent}
      </Link>
    );
  };

  return <>{renderItem()}</>;
};
