import React, { useEffect } from 'react';
import { Button, Grid, Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import logoPng from '../assets/images/pngs/logo.png';
import homeBannerPng from '../assets/images/pngs/home_banner.png';
import { ROUTES } from '../consts';
import { useMainLayoutContext } from '../layouts/MainLayoutContext';
import { useAuth } from '../contexts';
import Image from 'mui-image';

export const RootPage = () => {
  const navigate = useNavigate();
  const { updateContextValue } = useMainLayoutContext();
  const {
    values: { loggedIn },
  } = useAuth();

  useEffect(() => {
    if (loggedIn) {
      navigate(`${ROUTES.HOME}`);
    }
  }, [loggedIn, navigate]);

  useEffect(() => {
    updateContextValue({
      bannerContainerSx: {
        backgroundImage: `url(${homeBannerPng})`,
        backgroundSize: 'cover',
        backgroundPosition: 'bottom center',
        height: 320,
      },
      bannerChildren: (
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: 36,
            color: 'white',
            fontWeight: 900,
            textShadow: '-2px 0 1px #000000',
            letterSpacing: 4,
          }}
        >
          PROJECT PLANNING
          <br />& EVALUATION
          {/* <br />
          <span style={{ fontStyle: 'italic' }}>for</span>
          <br />
          A35 GRANTEES */}
        </Typography>
      ),
    });

    return () => {
      updateContextValue({
        bannerContainerSx: undefined,
        bannerChildren: undefined,
      });
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container rowGap={8}>
      <Grid container textAlign="center">
        <Grid item xs={2} />
        <Grid item xs={7} textAlign="center">
          <Typography variant="h6">This module was developed by</Typography>
          <Typography
            variant="h6"
            component={Link}
            target="_blank"
            href="https://coloradosph.cuanschutz.edu/research-and-practice/centers-programs/cepeg"
          >
            {`Community Epidemiology and Program Evaluation Group`}
          </Typography>
          <Typography variant="h6" component="span">
            {' '}
            {`(CEPEG) of the Colorado School of Public Health`}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Image
            width={100}
            height={100}
            wrapperStyle={{ display: 'inline-block', marginTop: 20 }}
            style={{ objectFit: 'contain' }}
            src={logoPng}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          size="large"
          onClick={() => navigate(`${ROUTES.HOME}`)}
          sx={{ textTransform: 'none' }}
        >
          Click here to enter the module
        </Button>
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Typography>
          Funding for this module was provided in part by Amendment 35 Tobacco
          Education, Prevention and Cessation Grant Program. The views expressed
          do not necessarily reflect the official policies of, nor does the
          mention of imply endorsement by, the Colorado Department of Public
          Health and Environment.
        </Typography>
      </Grid>
    </Grid>
  );
};
