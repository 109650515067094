import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import Image from 'mui-image';

import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';
import bgImage from '../../../assets/images/pngs/step3_evaluation_plan.png';
import step3Image from '../../../assets/images/pngs/intro_diagram_step_3_no_bar.png';
import { EVALUATION_PLAN_HEADERS } from '../../../consts';

export const Step3CreateProjectEvaluationPlanPage = () => {
  const theme = useTheme();
  const { updateContextValue } = useMainLayoutContext();

  React.useEffect(() => {
    updateContextValue({
      title: 'Basic Components of the Project Evaluation Plan',
      pageContainerSx: { maxWidth: theme.breakpoints.values.md },
    });

    return () => {
      updateContextValue({ title: undefined, pageContainerSx: undefined });
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Grid container alignItems="flex-start" sx={{ height: '100%' }} spacing={1}>
      <Grid item container justifyContent="flex-end" sx={{ mt: -8, mb: 8 }}>
        <Grid item>
          <Image src={step3Image} />
        </Grid>
        <Grid item xs={2} />
      </Grid>
      <Grid item container>
        {EVALUATION_PLAN_HEADERS.map((item, index) => (
          <Grid item xs={1.95} sm={1.93} md={1.72} alignSelf="end" key={index}>
            <Typography
              textAlign="center"
              fontWeight={500}
              fontSize={{ xs: 10, sm: 14, md: 16 }}
            >
              {item}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12} alignItems="flex-start">
        <Image
          src={bgImage}
          wrapperStyle={{ maxWidth: 796, justifyContent: 'flex-start' }}
          duration={0}
        />
      </Grid>
    </Grid>
  );
};
