import { Box, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import Image from 'mui-image';

import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';
import bgImage from '../../../assets/images/pngs/intro_your_evaluation_roadmap_right.png';

const list = [
  'Add evaluation focus',
  'Define evaluation audiences',
  'Draft evaluation questions',
  'Select indicators of change',
  'Determine data to collect, when to collect it and how to analyze',
  'Describe how results will be used',
];

export const Step3YourEvaluationRoadMapPage = () => {
  const theme = useTheme();
  const { updateContextValue } = useMainLayoutContext();

  React.useEffect(() => {
    updateContextValue({
      title: 'Step 3 - Design The Evaluation Plan',
      pageContainerSx: { maxWidth: theme.breakpoints.values.md },
    });

    return () => {
      updateContextValue({ title: undefined, pageContainerSx: undefined });
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Grid container justifyContent="center">
      <Grid container item sm={7} spacing={2}>
        {list.map((item, index) => (
          <Grid item xs={12} key={index}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                sx={{
                  borderRadius: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'gray',
                  mr: 2,
                }}
              />
              <Typography variant="subtitle1">{item}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12} sm={4}>
        <Image
          src={bgImage}
          style={{ width: '100%', maxWidth: 433, objectFit: 'contain' }}
        />
      </Grid>
    </Grid>
  );
};
