import axios, { AxiosError, AxiosResponse } from 'axios';
import * as StorageUtils from './storage';
import { Env } from '../consts';

const API_ENDPOINT = `${Env.apiBaseUrl}/api/v1`;

const Client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
  },
});

Client.interceptors.request.use(
  async request => {
    const token = StorageUtils.getAuthToken();
    request.headers.Authorization = token ? `Bearer ${token}` : '';

    return request;
  },
  error => {
    return Promise.reject(error);
  }
);

Client.interceptors.response.use(
  async (response: AxiosResponse<any, any>) => {
    return response;
  },
  async (error: AxiosError) => {
    const originalRequest = error.config as any;
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return refreshAccessToken().then(() => {
        const token = StorageUtils.getAuthToken();
        originalRequest.headers.Authorization = token ? `Bearer ${token}` : '';
        return Client(originalRequest);
      });
    }
    return Promise.reject(error);
  }
);

async function refreshAccessToken() {
  try {
    const response = await axios.post(`${API_ENDPOINT}/auth/refresh`, {
      refresh_token: StorageUtils.getRefreshToken(),
    });
    StorageUtils.setAuthToken(response?.data?.access_token);
    StorageUtils.setRefreshToken(response?.data?.refresh_token);
    return true;
  } catch (err) {
    throw err;
  }
}

export default Client;
