import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';

import { ROUTES } from '../consts';
import { CreateUserDto } from '../@types';
import { useAuth, useSettingsContext } from '../contexts';
import { LoadingView } from '../components';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const resolver = classValidatorResolver(CreateUserDto);

export const SignupForm = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const {
    values: { authorizing, error },
    authActions,
  } = useAuth();

  const {
    parameters: { user_occupation, user_skill_level },
  } = useSettingsContext();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<CreateUserDto>({
    resolver,
    defaultValues: {
      email: '',
      first_name: '',
      last_name: '',
      password: '',
      confirm_password: '',
      organization: '',
      occupation_id: undefined,
      skill_level_id: undefined,
    },
  });

  const onSubmit = React.useCallback(
    (data: CreateUserDto) => {
      if (data.password !== data.confirm_password) {
        return;
      }
      authActions.signup({
        ...data,
      });
    },
    [authActions]
  );

  React.useEffect(() => {
    authActions.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // React.useEffect(() => {
  //   setShowOtherOccupation(occupation === OCCUPATIONS[OCCUPATIONS.length - 1]);
  //   if (occupation !== OCCUPATIONS[OCCUPATIONS.length - 1]) {
  //     setValue('occupation_other', '');
  //   }
  // }, [occupation, setValue]);

  return (
    <Grid
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      container
      spacing={3}
    >
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Email"
          required
          autoComplete="email"
          error={!!errors.email}
          helperText={errors.email?.message}
          {...register('email')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Password"
          type={showPassword ? 'text' : 'password'}
          required
          error={!!errors.password}
          helperText={errors.password?.message}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...register('password')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Confirm Password"
          type={showPassword ? 'text' : 'password'}
          required
          error={
            (getValues().confirm_password &&
              getValues().password !== getValues().confirm_password) ||
            !!errors.confirm_password
          }
          helperText={
            getValues().password &&
            getValues().password !== getValues().confirm_password
              ? "Password doesn't match"
              : errors.confirm_password?.message
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...register('confirm_password')}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="First Name"
          required
          error={!!errors.first_name}
          helperText={errors.first_name?.message}
          {...register('first_name')}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Last Name"
          required
          error={!!errors.last_name}
          helperText={errors.last_name?.message}
          {...register('last_name')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Organization"
          required
          error={!!errors.organization}
          helperText={errors.organization?.message}
          {...register('organization')}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="occupation">Occupation</InputLabel>
          <Select
            label="Occupation"
            labelId="occupation"
            required
            defaultValue=""
            error={!!errors.occupation_id}
            {...register('occupation_id')}
          >
            {user_occupation.map((item, index) => (
              <MenuItem value={item.id} key={index}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText sx={{ color: 'red' }}>
            {errors.occupation_id?.message}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="skill_level">
            Rate your skill level in evaluation
          </InputLabel>
          <Select
            label="Rate your skill level in evaluation"
            labelId="skill_level"
            required
            defaultValue=""
            error={!!errors.skill_level_id}
            {...register('skill_level_id')}
          >
            {user_skill_level.map((item, index) => (
              <MenuItem value={item.id} key={index}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText sx={{ color: 'red' }}>
            {errors.skill_level_id?.message}
          </FormHelperText>
        </FormControl>
      </Grid>
      {!!error && (
        <Grid item xs={12}>
          <Alert variant="outlined" severity="error">
            {error?.message}
          </Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <Button variant="contained" type="submit">
          Sign Up
          <LoadingView visible={authorizing} size={20} />
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          Already have an account?{' '}
          <Link
            component="span"
            underline="hover"
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate(`/${ROUTES.AUTH}/${ROUTES.LOGIN}`)}
          >
            Sign in
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};
