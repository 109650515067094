import React from 'react';
import { Navigate, Route, Routes, Outlet } from 'react-router-dom';

import { ROUTES } from '../consts';
import { useAuth } from '../contexts';
import { MainLayout, PrivateLayout } from '../layouts';
import { ProjectLayout } from '../layouts/ProjectLayout';
import {
  ForgotPasswordPage,
  HelpPage,
  HomePage,
  InitialLoadingPage,
  LoginPage,
  MyAccountEditPage,
  MyAccountViewPage,
  ResetPasswordPage,
  RootPage,
  SignupPage,
  TaContactPage,
} from '../pages';
import {
  EvaluationFormPage,
  IntroDiagramsYouWillCreate1Page,
  IntroDiagramsYouWillCreate2Page,
  IntroGettingYourBearingsPage,
  IntroModuleObjectivesPage,
  IntroTheEvaluationRoadmapPage,
  ProjectsListPage,
  Step1CreateProjectTheoryDiagram1Page,
  Step1CreateProjectTheoryDiagram2Page,
  Step1CreateProjectTheoryDiagram3Page,
  Step1CreateProjectTheoryDiagram4Page,
  Step1CreateProjectTheoryDiagram5Page,
  Step1ProjectProfilePage,
  Step1ProjectTheoryDiagram1Page,
  Step1ProjectTheoryDiagram2Page,
  Step1ProjectTheoryDiagramForProjectPage,
  Step2BenefitOfProjectFlowDiagramPage,
  Step2CreateProjectFlowDiagram1Page,
  Step2CreateProjectFlowDiagram2Page,
  Step2CreateProjectFlowDiagram3Page,
  Step2CreateProjectFlowDiagram4Page,
  Step2CreateProjectFlowDiagram5Page,
  Step2ProjectFlowDiagramForProjectPage,
  Step2YourEvaluationRoadMapPage,
  Step3AlignEvaluationQuestionsPage,
  Step3ChooseEvaluationQuestionsPage,
  Step3CreateProjectEvaluationPlanPage,
  Step3DataAndDataCollectionTimingPage,
  Step3DecidingOnIndicators1Page,
  Step3DecidingOnIndicators2Page,
  Step3DecidingOnIndicatorsOfChangePage,
  Step3EvaluationPlanInProgress1Page,
  Step3EvaluationPlanInProgress2Page,
  Step3EvaluationPlanInProgress4Page,
  Step3GoodWorkPage,
  Step3IdentifyStakeholders1Page,
  Step3IdentifyStakeholders2Page,
  Step3KnowYourEvaluationFocusPage,
  Step3OutcomeDataExamplePage,
  Step3OutputOrProcessDataExamplePage,
  Step3ProcessEvaluationQuestions,
  Step3ProjectEvaluationPlanForProjectPage,
  Step3SelectYourDataMeasuresAndTimingPage,
  Step3ThankYouPage,
  Step3UsingEvaluationFindingsPage,
  Step3YourEvaluationRoadMapPage,
} from '../pages/projects';

export const RootNavigator = () => {
  const {
    values: { ready },
  } = useAuth();

  if (!ready) {
    return <InitialLoadingPage />;
  }

  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route path={ROUTES.ROOT} element={<RootPage />} />
        <Route path={ROUTES.HOME} element={<HomePage />} />
        <Route path={ROUTES.HELP} element={<HelpPage />} />
        <Route path={ROUTES.TA_CONTACT} element={<TaContactPage />} />

        <Route path={ROUTES.AUTH} element={<Outlet />}>
          <Route path={ROUTES.LOGIN} element={<LoginPage />} />
          <Route path={ROUTES.SIGNUP} element={<SignupPage />} />
          <Route
            path={ROUTES.FORGOT_PASSWORD}
            element={<ForgotPasswordPage />}
          />
          <Route path={ROUTES.RESET_PASSWORD} element={<ResetPasswordPage />} />
        </Route>
        <Route element={<PrivateLayout />}>
          <Route path={ROUTES.MY_ACCOUNT} element={<Outlet />}>
            <Route path={ROUTES.VIEW} element={<MyAccountViewPage />} />
            <Route path={ROUTES.EDIT} element={<MyAccountEditPage />} />
          </Route>
          <Route element={<ProjectLayout />}>
            <Route path={ROUTES.PROJECTS}>
              <Route path={ROUTES.INTRO} element={<Outlet />}>
                <Route
                  path={ROUTES.MODULE_OBJECTIVES}
                  element={<IntroModuleObjectivesPage />}
                />
                <Route
                  path={ROUTES.GETTING_YOUR_BEARINGS}
                  element={<IntroGettingYourBearingsPage />}
                />
                <Route
                  path={ROUTES.THE_EVALUATION_ROADMAP}
                  element={<IntroTheEvaluationRoadmapPage />}
                />
                <Route
                  path={ROUTES.DIAGRAMS_YOU_WILL_CREATE_1}
                  element={<IntroDiagramsYouWillCreate1Page />}
                />
                <Route
                  path={ROUTES.DIAGRAMS_YOU_WILL_CREATE_2}
                  element={<IntroDiagramsYouWillCreate2Page />}
                />
                <Route
                  path={ROUTES.PROJECT_PROFILE}
                  element={<Step1ProjectProfilePage />}
                />
              </Route>
            </Route>

            <Route path={ROUTES.PROJECTS}>
              <Route path={''} element={<ProjectsListPage />} />

              <Route path=":id">
                <Route path={ROUTES.INTRO} element={<Outlet />}>
                  <Route
                    path={ROUTES.MODULE_OBJECTIVES}
                    element={<IntroModuleObjectivesPage />}
                  />
                  <Route
                    path={ROUTES.GETTING_YOUR_BEARINGS}
                    element={<IntroGettingYourBearingsPage />}
                  />
                  <Route
                    path={ROUTES.THE_EVALUATION_ROADMAP}
                    element={<IntroTheEvaluationRoadmapPage />}
                  />
                  <Route
                    path={ROUTES.DIAGRAMS_YOU_WILL_CREATE_1}
                    element={<IntroDiagramsYouWillCreate1Page />}
                  />
                  <Route
                    path={ROUTES.DIAGRAMS_YOU_WILL_CREATE_2}
                    element={<IntroDiagramsYouWillCreate2Page />}
                  />
                </Route>

                <Route path={ROUTES.STEP_1} element={<Outlet />}>
                  <Route
                    path={ROUTES.PROJECT_PROFILE}
                    element={<Step1ProjectProfilePage />}
                  />
                  <Route
                    path={ROUTES.PROJECT_THEORY_DIAGRAM_1}
                    element={<Step1ProjectTheoryDiagram1Page />}
                  />
                  <Route
                    path={ROUTES.PROJECT_THEORY_DIAGRAM_2}
                    element={<Step1ProjectTheoryDiagram2Page />}
                  />
                  <Route
                    path={ROUTES.CREATE_PROJECT_THEORY_DIAGRAM_1}
                    element={<Step1CreateProjectTheoryDiagram1Page />}
                  />
                  <Route
                    path={ROUTES.CREATE_PROJECT_THEORY_DIAGRAM_2}
                    element={<Step1CreateProjectTheoryDiagram2Page />}
                  />
                  <Route
                    path={ROUTES.CREATE_PROJECT_THEORY_DIAGRAM_3}
                    element={<Step1CreateProjectTheoryDiagram3Page />}
                  />
                  <Route
                    path={ROUTES.CREATE_PROJECT_THEORY_DIAGRAM_4}
                    element={<Step1CreateProjectTheoryDiagram4Page />}
                  />
                  <Route
                    path={ROUTES.CREATE_PROJECT_THEORY_DIAGRAM_5}
                    element={<Step1CreateProjectTheoryDiagram5Page />}
                  />
                  <Route
                    path={ROUTES.PROJECT_THEORY_DIAGRAM_FOR_PROJECT}
                    element={<Step1ProjectTheoryDiagramForProjectPage />}
                  />
                </Route>

                <Route path={ROUTES.STEP_2} element={<Outlet />}>
                  <Route
                    path={ROUTES.YOUR_EVALUATION_ROADMAP2}
                    element={<Step2YourEvaluationRoadMapPage />}
                  />
                  <Route
                    path={ROUTES.BENEFIT_OF_PORJECT_FLOW_DIAGRAM}
                    element={<Step2BenefitOfProjectFlowDiagramPage />}
                  />
                  <Route
                    path={ROUTES.CREATE_PROJECT_FLOW_DIAGRAM_1}
                    element={<Step2CreateProjectFlowDiagram1Page />}
                  />
                  <Route
                    path={ROUTES.CREATE_PROJECT_FLOW_DIAGRAM_2}
                    element={<Step2CreateProjectFlowDiagram2Page />}
                  />
                  <Route
                    path={ROUTES.CREATE_PROJECT_FLOW_DIAGRAM_3}
                    element={<Step2CreateProjectFlowDiagram3Page />}
                  />
                  <Route
                    path={ROUTES.CREATE_PROJECT_FLOW_DIAGRAM_4}
                    element={<Step2CreateProjectFlowDiagram4Page />}
                  />
                  <Route
                    path={ROUTES.CREATE_PROJECT_FLOW_DIAGRAM_5}
                    element={<Step2CreateProjectFlowDiagram5Page />}
                  />
                  <Route
                    path={ROUTES.PROJECT_FLOW_DIAGRAM_FOR_PROJECT}
                    element={<Step2ProjectFlowDiagramForProjectPage />}
                  />
                </Route>

                <Route path={ROUTES.STEP_3} element={<Outlet />}>
                  <Route
                    path={ROUTES.YOUR_EVALUATION_ROADMAP3}
                    element={<Step3YourEvaluationRoadMapPage />}
                  />
                  <Route
                    path={ROUTES.CREATE_PROJECT_EVALUATION_PLAN}
                    element={<Step3CreateProjectEvaluationPlanPage />}
                  />
                  <Route
                    path={ROUTES.KNOW_YOUR_EVALUATION_FOCUS}
                    element={<Step3KnowYourEvaluationFocusPage />}
                  />
                  <Route
                    path={ROUTES.IDENITIFY_STAKEHOLDERS_1}
                    element={<Step3IdentifyStakeholders1Page />}
                  />
                  <Route
                    path={ROUTES.IDENITIFY_STAKEHOLDERS_2}
                    element={<Step3IdentifyStakeholders2Page />}
                  />
                  <Route
                    path={ROUTES.CHOOSE_EVALUATION_QUESTIONS}
                    element={<Step3ChooseEvaluationQuestionsPage />}
                  />
                  <Route
                    path={ROUTES.ALIGN_EVALUATION_QUESTIONS}
                    element={<Step3AlignEvaluationQuestionsPage />}
                  />
                  <Route
                    path={ROUTES.PROCESS_EVALUATION_QUESTIONS}
                    element={<Step3ProcessEvaluationQuestions />}
                  />
                  <Route
                    path={ROUTES.EVALUATION_PLAN_IN_PROGRESS_1}
                    element={<Step3EvaluationPlanInProgress1Page />}
                  />
                  <Route
                    path={ROUTES.EVALUATION_PLAN_IN_PROGRESS_2}
                    element={<Step3EvaluationPlanInProgress2Page />}
                  />
                  <Route
                    path={ROUTES.DECIDING_ON_INDICATORS_OF_CHANGE}
                    element={<Step3DecidingOnIndicatorsOfChangePage />}
                  />
                  <Route
                    path={ROUTES.DECIDING_ON_INDICATORS_1}
                    element={<Step3DecidingOnIndicators1Page />}
                  />
                  <Route
                    path={ROUTES.DECIDING_ON_INDICATORS_2}
                    element={<Step3DecidingOnIndicators2Page />}
                  />
                  <Route
                    path={ROUTES.DATA_AND_DATA_COLLECTION_TIMING}
                    element={<Step3DataAndDataCollectionTimingPage />}
                  />
                  <Route
                    path={ROUTES.OUTPUT_OR_PROCESS_DATA_EXAMPLE}
                    element={<Step3OutputOrProcessDataExamplePage />}
                  />
                  <Route
                    path={ROUTES.OUTCOME_DATA_EXAMPLE}
                    element={<Step3OutcomeDataExamplePage />}
                  />
                  <Route
                    path={ROUTES.SELECT_YOUR_DATA_MEASURES_AND_TIMING}
                    element={<Step3SelectYourDataMeasuresAndTimingPage />}
                  />
                  <Route
                    path={ROUTES.EVALUATION_PLAN_IN_PROGRESS_4}
                    element={<Step3EvaluationPlanInProgress4Page />}
                  />
                  <Route
                    path={ROUTES.USING_EVALUATION_FINDINGS}
                    element={<Step3UsingEvaluationFindingsPage />}
                  />
                  <Route
                    path={ROUTES.PROJECT_EVALUATION_PLAN_FOR_PROJECT}
                    element={<Step3ProjectEvaluationPlanForProjectPage />}
                  />
                  <Route
                    path={ROUTES.GOOD_WORK}
                    element={<Step3GoodWorkPage />}
                  />
                  <Route
                    path={ROUTES.THANK_YOU}
                    element={<Step3ThankYouPage />}
                  />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>

        <Route path={ROUTES.EVALUATION_FORM} element={<EvaluationFormPage />} />

        <Route path="*" element={<Navigate replace to={ROUTES.ROOT} />} />
      </Route>
    </Routes>
  );
};
