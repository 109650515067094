import {
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps,
  TypographyTypeMap,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

export type TypographyProps = MuiTypographyProps;

export const GTypography: OverridableComponent<TypographyTypeMap> = ({
  children,
  ...rest
}: any) => {
  return (
    <>
      {typeof children === 'string' ? (
        children.split('\n').map((paragraph, index) => (
          <MuiTypography key={`paragraph-${index}`} {...rest}>
            {paragraph}
          </MuiTypography>
        ))
      ) : (
        <MuiTypography {...rest}>{children}</MuiTypography>
      )}
    </>
  );
};
