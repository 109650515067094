import React, { useEffect } from 'react';

import { LoginForm } from '../../forms';
import { useMainLayoutContext } from '../../layouts/MainLayoutContext';

export const LoginPage = () => {
  const { updateContextValue } = useMainLayoutContext();

  useEffect(() => {
    updateContextValue({ title: 'Sign in to your account' });

    return () => {
      updateContextValue({ title: undefined });
    };
    // eslint-disable-next-line
  }, []);

  return <LoginForm />;
};
