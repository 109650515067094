import React, { useEffect } from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { useMainLayoutContext } from '../../layouts/MainLayoutContext';
import { useAuth, useSettingsContext } from '../../contexts';
import { ROUTES } from '../../consts';

export const MyAccountViewPage = () => {
  const { updateContextValue } = useMainLayoutContext();
  const { user } = useAuth();
  const {
    parameters: { user_occupation, user_skill_level },
  } = useSettingsContext();

  useEffect(() => {
    updateContextValue({ title: 'Online Profile' });

    return () => {
      updateContextValue({ title: undefined });
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Box>
      <Paper variant="outlined" sx={{ p: 1, mb: 1 }}>
        <Typography fontWeight={600}>First Name: {user?.first_name}</Typography>
      </Paper>
      <Paper variant="outlined" sx={{ p: 1, mb: 1 }}>
        <Typography fontWeight={600}>Last Name: {user?.last_name}</Typography>
      </Paper>
      <Paper variant="outlined" sx={{ p: 1, mb: 1 }}>
        <Typography fontWeight={600}>
          Occupation:{' '}
          {
            user_occupation?.find(item => item.id === user?.occupation_id)
              ?.label
          }
        </Typography>
      </Paper>
      <Paper variant="outlined" sx={{ p: 1, mb: 1 }}>
        <Typography fontWeight={600}>Email: {user?.email}</Typography>
      </Paper>
      <Paper variant="outlined" sx={{ p: 1, mb: 1 }}>
        <Typography fontWeight={600}>
          Please rate your skill level with, and knowledge of, evaluation?:
        </Typography>
        <Typography fontWeight={600}>
          {
            user_skill_level?.find(item => item.id === user?.skill_level_id)
              ?.label
          }
        </Typography>
      </Paper>
      <Paper variant="outlined" sx={{ p: 1, mb: 1 }}>
        <Typography fontWeight={600}>
          Organization: {user?.organization}
        </Typography>
      </Paper>

      <Button
        component={Link}
        to={`/${ROUTES.MY_ACCOUNT}/${ROUTES.EDIT}`}
        variant="contained"
        sx={{ mt: 2 }}
      >
        Edit
      </Button>
    </Box>
  );
};
