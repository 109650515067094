import { Box, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';

import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';
import bubbleYellowImage from '../../../assets/images/pngs/bubble_yellow.png';
import bubbleBlueImage from '../../../assets/images/pngs/bubble_blue.png';
import Image from 'mui-image';

const list = [
  'Indicators are an important link between questions and data',
  'To define an indicator, think about what you can actually measure that will indicate change or progress',
  'Indicators will help to identify exactly what data you need to collect',
  'Make sure there will be data available for the indicator',
];

export const Step3DecidingOnIndicatorsOfChangePage = () => {
  const theme = useTheme();
  const { updateContextValue } = useMainLayoutContext();

  React.useEffect(() => {
    updateContextValue({
      title: 'Deciding on Indicators of Change',
      pageContainerSx: { maxWidth: theme.breakpoints.values.md },
    });

    return () => {
      updateContextValue({ title: undefined, pageContainerSx: undefined });
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Grid container>
      <Grid item xs={12} sm={5}>
        {list.map((item, index) => (
          <Box key={index} sx={{ display: 'flex', mb: 2 }}>
            <Box
              sx={{
                width: 12,
                height: 12,
                borderRadius: 12,
                bgcolor: 'lightgray',
                flexShrink: 0,
                mt: 0.8,
                mr: 2,
              }}
            />
            <Typography sx={{ fontWeight: 500 }}>{item}</Typography>
          </Box>
        ))}
      </Grid>
      <Grid item xs={1} />
      <Grid item container xs={12} sm={6}>
        <Grid item xs={6} sx={{ position: 'relative' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: 224,
            }}
          >
            <Image src={bubbleYellowImage} duration={0} />
            <Typography sx={{ fontWeight: 600, mt: 1, fontSize: 20 }}>
              QUESTION
            </Typography>
          </Box>
          <Box
            sx={{ position: 'absolute', inset: 0, px: 4, pt: 6, width: 224 }}
          >
            <Typography
              sx={{ fontWeight: 500, fontSize: 17, textAlign: 'center' }}
            >
              Do we have the right mix of activities?
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sx={{ mt: 20, position: 'relative' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: 224,
            }}
          >
            <Image src={bubbleBlueImage} duration={0} />
            <Typography sx={{ fontWeight: 600, mt: 1, fontSize: 20 }}>
              INDICATOR
            </Typography>
          </Box>
          <Box
            sx={{ position: 'absolute', inset: 0, px: 4, pt: 6, width: 224 }}
          >
            <Typography
              sx={{ fontWeight: 500, fontSize: 17, textAlign: 'center' }}
            >
              Level of participant satisfaction with available activities.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
