import React from 'react';

import { Label } from './Label';
import { ContentViewer, ContentViewerProps } from './ContentViewer';
import { useContentInputContext } from './ContentInputContext';

export const ContentInputUI = () => {
  const { label, content, styles, placeholder, editable } =
    useContentInputContext();

  const contentViewProps: ContentViewerProps = {
    value: content,
    placeholder,
    editable,
    contentInputDialogProps: {
      headerProps: {},
      contentProps: {},
      footerProps: {},
    },
    styles: styles?.contentViewer,
  };

  return (
    <>
      <Label value={label} sx={{ mb: 2, ...styles?.label }} />

      <ContentViewer {...contentViewProps} />
    </>
  );
};

// const contentInputProps: ContentInputProps = {
//   labelProps: {
//     value: `1. In no more than three sentences, explain the problem your project addresses. We suggest using the formula “what is wrong,” “why it matters,” and “what you plan to do about it.” This frames it as a community problem.`,
//   },
//   contentViewProps: {
//     value: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
//     contentInputDialogProps: {
//       headerProps: {
//         title: `Community Need`,
//       },
//       contentProps: {
//         contentInputFormProps: {
//           label: `1. In no more than three sentences, explain the problem your project addresses. We suggest using the formula “what is wrong,” “why it matters,” and “what you plan to do about it.” This frames it as a community problem.`,
//           value: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
//         },
//       },
//     },
//   },
// };
