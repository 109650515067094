import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
} from '@mui/material';
import Image from 'mui-image';
import { Close } from '@mui/icons-material';

interface Props extends DialogProps {
  src?: string;
  onClose: () => void;
  onConfirm?: () => void;
}

export const ImageDialog = ({
  src = '',
  onClose,
  onConfirm = () => {},
  ...props
}: Props) => {
  return (
    <Dialog fullWidth maxWidth={'md'} onClose={onClose} {...props}>
      <DialogTitle
        component="div"
        sx={{
          // bgcolor: theme.palette.primary.main,
          display: 'flex',
          alignItems: 'center',
          py: 1,
        }}
      >
        <IconButton
          size="small"
          sx={{ ml: 'auto' }}
          edge="end"
          onClick={onClose}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ mt: 2.5 }}>
        <Image
          src={src!}
          style={{
            objectFit: 'contain',
          }}
          duration={0}
        />
      </DialogContent>
    </Dialog>
  );
};
