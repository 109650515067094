import React from 'react';
import { AudioUtils } from '../../utils';
import { useProjectStepContext } from '../../contexts';

// eslint-disable-next-line no-useless-escape
const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);

export interface AudioPlayerContextType {
  src?: string;
  muted: boolean;
  paused: boolean;
  volumeAmount: number;
  duration: number;
  seek: number;
  progress: number;
  player?: React.MutableRefObject<HTMLAudioElement | null>;
  pausePlayer: (value: boolean) => void;
  seekPlayer: (value: number) => void;
  onChangeVolume: (value: number) => void;
  updateContextValue: (
    payload: Partial<Omit<AudioPlayerContextType, 'updateContextValue'>>
  ) => void;
}

const defaultAudioPlayerContextValue: AudioPlayerContextType = {
  src: undefined,
  muted: false,
  paused: true,
  duration: 0,
  seek: 0,
  progress: 0,
  volumeAmount: 1,
  seekPlayer: () => {},
  pausePlayer: () => {},
  onChangeVolume: () => {},
  updateContextValue: () => {},
};

export const AudioPlayerContext = React.createContext<AudioPlayerContextType>(
  defaultAudioPlayerContextValue
);

export interface AudioPlayerContextProviderProps {
  src?: string;
}

export const AudioPlayerContextProvider = ({
  src,
  children,
}: React.PropsWithChildren<AudioPlayerContextProviderProps>) => {
  const { updateContextValue: updateProjectStepContectValue, audioPaused } =
    useProjectStepContext();
  const [contextValue, setContextValue] =
    React.useState<AudioPlayerContextType>(defaultAudioPlayerContextValue);
  const player = React.useRef<HTMLAudioElement | null>(null);
  const prevVolume = React.useRef<number>(
    defaultAudioPlayerContextValue.volumeAmount
  );

  const updateContextValue = (
    payload: Partial<Omit<AudioPlayerContextType, 'updateContextValue'>>
  ) => setContextValue(prev => ({ ...prev, ...payload }));

  const onLoad = React.useCallback(() => {
    setContextValue(prev => ({
      ...prev,
      duration: player?.current?.duration!,
    }));
  }, []);
  const handlePlayerTimeUpdate = React.useCallback(() => {
    const progress = AudioUtils.getProgress(
      player?.current?.currentTime!,
      player?.current?.duration!
    );
    setContextValue(prev => ({
      ...prev,
      progress,
      seek: player?.current?.currentTime!,
    }));
  }, []);
  const handleAudioEnd = React.useCallback(() => {
    setContextValue(prev => ({ ...prev, paused: true }));
  }, []);
  const onPlayed = React.useCallback(() => {
    setContextValue(prev => ({ ...prev, paused: false }));
  }, []);
  const onPaused = React.useCallback(() => {
    setContextValue(prev => ({ ...prev, paused: true }));
  }, []);

  const seekPlayer = React.useCallback(
    (value: number) => {
      if (player && player.current) {
        player.current.currentTime = Math.floor(value);
        setContextValue(prev => ({ ...prev, seek: value }));
      }
    },
    [player]
  );

  const pausePlayer = React.useCallback(
    (value: boolean) => {
      if (player && player.current) {
        if (value) {
          player.current.pause();
        } else {
          player.current.play();
        }
        setContextValue(prev => ({ ...prev, paused: value }));
        updateProjectStepContectValue({ audioPaused: value });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [player]
  );

  const onChangeVolume = React.useCallback(
    (value: number) => {
      if (player && player.current) {
        player.current.volume = value;
        prevVolume.current = value;
        setContextValue(prev => ({
          ...prev,
          volumeAmount: value,
          muted: value === 0,
        }));
      }
    },
    [player]
  );

  React.useEffect(() => {
    if (player && player.current) {
      if (contextValue.muted) {
        prevVolume.current = contextValue.volumeAmount;
        setContextValue(prev => ({ ...prev, volumeAmount: 0 }));
      } else {
        setContextValue(prev => ({
          ...prev,
          volumeAmount: prevVolume.current,
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextValue.muted]);

  React.useEffect(() => {
    if (player && player.current) {
      if (player.current.readyState > 3) {
        onLoad();
      }
      if (!player.current.autoplay) {
        player.current.autoplay = true;
      }
      if (isSafari) {
        player.current.load();
      }
      player.current.addEventListener('canplay', onLoad);
      player.current.addEventListener('timeupdate', handlePlayerTimeUpdate);
      player.current.addEventListener('ended', handleAudioEnd);
      player.current.addEventListener('pause', onPaused);
      player.current.addEventListener('play', onPlayed);
    }
    return () => {
      if (player && player.current) {
        player.current.removeEventListener('canplay', onLoad);

        player.current.removeEventListener(
          'timeupdate',
          handlePlayerTimeUpdate
        );
        player.current.removeEventListener('ended', handleAudioEnd);
        player.current.removeEventListener('pause', onPaused);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        player.current.removeEventListener('play', onPlayed);
      }
    };
  }, [
    handleAudioEnd,
    handlePlayerTimeUpdate,
    onLoad,
    onPaused,
    onPlayed,
    player,
    src,
  ]);

  React.useEffect(() => {
    if (audioPaused) {
      player?.current?.pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player]);

  return (
    <AudioPlayerContext.Provider
      value={{
        ...contextValue,
        src,
        player,
        seekPlayer,
        pausePlayer,
        onChangeVolume,
        updateContextValue,
      }}
    >
      {children}
    </AudioPlayerContext.Provider>
  );
};

export const useAudioPlayerContext = () => {
  return React.useContext(AudioPlayerContext);
};
