import React, { useEffect } from 'react';
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import {
  Diversity1,
  KeyboardAltOutlined,
  KeyboardArrowDownOutlined,
  SettingsSuggestOutlined,
} from '@mui/icons-material';

import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';

export const IntroModuleObjectivesPage = () => {
  const theme = useTheme();
  const [showList, setShowList] = React.useState(1);
  const { updateContextValue } = useMainLayoutContext();

  useEffect(() => {
    updateContextValue({
      title: 'Module Objectives',
      pageContainerSx: { maxWidth: theme.breakpoints.values.sm },
    });

    return () => {
      updateContextValue({ title: undefined, pageContainerSx: undefined });
    };
    // eslint-disable-next-line
  }, []);

  const options: {
    icon: any;
    color: string;
    description: string;
  }[] = [
    {
      icon: Diversity1,
      color: '#f2c313',
      description: `Create a project theory diagram.`,
    },
    {
      icon: KeyboardAltOutlined,
      color: '#8cc63e',
      description: `Create a project flow diagram (logic model)`,
    },
    {
      icon: SettingsSuggestOutlined,
      color: '#24a9d4',
      description: `Create a project evaluation plan.`,
    },
  ];

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography color="textSecondary">
          {`This is a “product oriented” module. You are going to create three printable diagrams that represent an evaluation roadmap. Click the down arrow in each color bar to reveal the objectives for this module.`}
        </Typography>
      </Grid>

      <Grid item xs={12} container>
        {options.map(({ color, icon: Icon, description }, index) => (
          <Collapse
            component={Grid}
            in={index < showList}
            key={`option-${index}`}
            sx={{
              bgcolor: color,
              borderBottom: `2px solid white`,
              width: '100%',
            }}
          >
            <Box sx={{ p: 2 }}>
              <Grid container spacing={4} sx={{ alignItems: 'center' }}>
                <Grid item>
                  <Box
                    sx={{
                      width: 48,
                      height: 48,
                      borderRadius: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      bgcolor: 'white',
                      color,
                    }}
                  >
                    <Icon width={30} height={30} />
                  </Box>
                </Grid>

                <Grid item sx={{ flex: 1 }}>
                  <Typography
                    sx={{
                      color: 'white',
                      fontWeight: 600,
                      maxWidth: theme.breakpoints.values.sm,
                    }}
                  >
                    {description}
                  </Typography>
                </Grid>
                {index !== options.length - 1 && (
                  <Grid item sx={{ color: 'white' }}>
                    <IconButton
                      onClick={() =>
                        index === showList - 1 && setShowList(prev => prev + 1)
                      }
                    >
                      <KeyboardArrowDownOutlined
                        sx={{ width: 32, height: 32, color: 'white' }}
                      />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Collapse>
        ))}
      </Grid>
    </Grid>
  );
};
