import { Grid, Typography, useTheme, SxProps } from '@mui/material';
import React from 'react';
import { ContentInput, ContentOptions } from '../../../components';
import { useProjectContext } from '../../../contexts';
import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';
import { Description } from './Description';
import { STACK_HOLDER_OPTIONS } from '../../../consts';

const descriptionItems = [
  {
    text: `Identifying and Describing the Evaluation `,
  },
  {
    text: 'Stakeholders',
    tooltip: `Those with a vested interest in your project and its outcomes.`,
  },
];

const cellBorder: SxProps = {
  borderWidth: 0.5,
  borderColor: 'black',
  borderStyle: 'solid',
  padding: 1,
  // display: 'flex',
  // justifyContent: 'center',
  // alignItems: 'center',
};

export const Step3IdentifyStakeholders2Page = () => {
  const theme = useTheme();
  const { updateContextValue } = useMainLayoutContext();
  const {
    project,
    projectActions: { updateProject },
  } = useProjectContext();

  React.useEffect(() => {
    updateContextValue({
      pageContainerSx: { maxWidth: theme.breakpoints.values.md },
    });

    return () => {
      updateContextValue({ title: undefined, pageContainerSx: undefined });
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Grid container>
      <Grid item xs={12}>
        <Description items={descriptionItems} />
      </Grid>
      <Grid item xs={12} sx={{ my: 2 }}>
        <Typography sx={{ color: '#66cc00', fontWeight: 600, fontSize: 16 }}>
          Click on the pencil icons and select one group or individual in each
          category that you will contact, and describe them in more detail.{' '}
        </Typography>
      </Grid>
      <Grid item container sx={{ ...cellBorder, p: 0 }}>
        <Grid item container>
          <Grid item xs={4} sx={{ ...cellBorder }}>
            <Typography>Question</Typography>
          </Grid>
          <Grid item xs={4} sx={{ ...cellBorder }}>
            <Typography>
              Choose one{' '}
              <Typography component="span" color="error">
                *
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ ...cellBorder }}>
            <Typography>
              Describe the stakeholder in more detail{' '}
              <Typography component="span" color="error">
                *
              </Typography>
            </Typography>
          </Grid>
        </Grid>
        {STACK_HOLDER_OPTIONS.map((item, index) => (
          <Grid item container key={index}>
            <Grid item xs={4} sx={{ ...cellBorder }}>
              <Typography>{item.question}</Typography>
            </Grid>
            <Grid item xs={4} sx={{ ...cellBorder }}>
              <ContentOptions
                hasCustomOption
                multiLineEdit={false}
                selected={((project as any) || {})[item.optionFieldName]}
                list={item.options.map((value, index) => ({
                  value: index,
                  label: value,
                }))}
                onChange={value =>
                  updateProject({
                    id: project?.id,
                    [item.optionFieldName]: value,
                  })
                }
              />
            </Grid>
            <Grid item xs={4} sx={{ ...cellBorder, p: 0, display: 'flex' }}>
              <ContentInput
                content={((project as any) || {})[item.inputFieldName]}
                handleSubmit={value =>
                  updateProject({
                    id: project?.id,
                    [item.inputFieldName]: value,
                  })
                }
                styles={{
                  contentViewer: {
                    container: {
                      flex: 1,
                      bgcolor: 'transparent',
                      boxShadow: 'none',
                      minHeight: 80,
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
