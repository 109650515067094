import React from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useApi, useSettingsContext } from '../contexts';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../consts';
import { useMainLayoutContext } from '../layouts/MainLayoutContext';

export const EvaluationForm = () => {
  const navigate = useNavigate();
  const { updateContextValue } = useMainLayoutContext();
  const { call, loading } = useApi('evaluation-form', '/profile/eval', {
    method: 'PUT',
    onSuccess: () => navigate(`/${ROUTES.PROJECTS}`),
  });
  const { questions } = useSettingsContext();
  const [answers, setAnswers] = React.useState<{ [symbol: string]: string }>(
    {}
  );

  const onSubmit = React.useCallback(() => {
    if (Object?.keys(answers)?.length === 0) {
      navigate(`/${ROUTES.PROJECTS}`);
      return;
    }

    call({
      answers: Object.keys(answers).map(key => ({
        id: key,
        text: answers[key],
      })),
    });
  }, [answers, call, navigate]);

  React.useEffect(() => {
    updateContextValue({
      loading,
    });
    return () => {
      updateContextValue({
        loading: false,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <Grid container rowGap={4}>
      <Grid item xs={12}>
        For each objective listed in the table below, please select the number
        that best describes the extent of knowledge or skill you had before
        completing the module, and then indicate the extent of knowledge or
        skill you have now as a result of completing the module.
      </Grid>
      <Grid container rowGap={3}>
        {questions?.map((question, index) => (
          <Grid container key={index}>
            <Grid item xs={12}>
              <Typography sx={{ mb: 1 }} fontWeight="600">
                {index + 1}. {question.title}
              </Typography>
            </Grid>
            <Grid container>
              {question?.custom_data?.length > 0 ? (
                <FormControl fullWidth sx={{ mt: 1, ml: 2 }}>
                  <RadioGroup
                    onChange={e =>
                      setAnswers(prev => ({
                        ...prev,
                        [question.id]: e.target.value,
                      }))
                    }
                  >
                    {question.custom_data?.map((option, oIndex) => (
                      <Grid item xs={6} key={oIndex}>
                        <FormControlLabel
                          value={option.id}
                          control={<Radio size="small" />}
                          label={option.label}
                          sx={{ span: { py: 0, fontSize: 14 } }}
                        />
                      </Grid>
                    ))}
                  </RadioGroup>
                </FormControl>
              ) : (
                <TextField
                  key={index}
                  fullWidth
                  required={false}
                  multiline
                  rows={3}
                  onChange={e =>
                    setAnswers(prev => ({
                      ...prev,
                      [question.id]: e.target.value,
                    }))
                  }
                />
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" onClick={onSubmit}>
          Submit
        </Button>
      </Grid>
    </Grid>
  );
};
