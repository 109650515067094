import {
  IsEmail,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  MinLength,
  ValidateIf,
} from 'class-validator';

export class LoginUserDto {
  @IsEmail({}, { message: 'Invalid email address' })
  @IsNotEmpty({ message: 'Add Email' })
  public email: string;

  @IsString()
  @IsNotEmpty({ message: 'Add password' })
  public password: string;
}

export class CreateUserDto {
  @IsEmail({}, { message: 'Invalid email address' })
  @IsNotEmpty({ message: 'Add Email' })
  public email: string;

  @IsString()
  @IsNotEmpty({ message: 'Add First Name' })
  public first_name: string;

  @IsString()
  @IsNotEmpty({ message: 'Add Last Name' })
  public last_name: string;

  @IsString()
  @MinLength(8, { message: 'Weak password' })
  @IsNotEmpty({ message: 'Add password' })
  public password: string;

  @IsString()
  @IsNotEmpty({ message: 'Add re-enter password' })
  public confirm_password: string;

  @IsString()
  @IsNotEmpty({ message: 'Add organization' })
  public organization: string;

  @IsNumber()
  @IsNotEmpty({ message: 'Add occupation' })
  public occupation_id: number;

  @IsNumber()
  @IsNotEmpty({ message: 'Add skill level' })
  public skill_level_id: number;
}

export class UpdateUserDto {
  @IsString()
  @IsNotEmpty({ message: 'Add old password' })
  public old_password: string;

  @IsEmail({}, { message: 'Invalid email address' })
  @IsNotEmpty({ message: 'Add Email' })
  public email: string;

  @IsString()
  @IsNotEmpty({ message: 'Add First Name' })
  public first_name: string;

  @IsString()
  @IsNotEmpty({ message: 'Add Last Name' })
  public last_name: string;

  @IsOptional()
  @IsString()
  @ValidateIf(obj => !!obj.password)
  @MinLength(6, { message: 'Weak password' })
  @IsNotEmpty({ message: 'Add password' })
  public password: string;

  @ValidateIf(obj => !!obj.password)
  @IsString()
  @IsNotEmpty({ message: 'Add re-enter password' })
  public confirm_password: string;

  @IsString()
  @IsNotEmpty({ message: 'Add organization' })
  public organization: string;

  @IsNumber()
  @IsNotEmpty({ message: 'Add occupation' })
  public occupation_id: number;

  @IsNumber()
  @IsNotEmpty({ message: 'Add skill level' })
  public skill_level_id: number;
}

export class ForgotPasswordDto {
  @IsEmail({}, { message: 'Invalid email address' })
  @IsNotEmpty({ message: 'Add Email' })
  public email: string;

  @IsString()
  public type: string;
}

export class ResetPasswordDto {
  @IsEmail({}, { message: 'Invalid email address' })
  @IsNotEmpty({ message: 'Add Email' })
  public username: string;

  @IsString()
  @IsNotEmpty({ message: 'Invalid verification code' })
  public code: string;

  @IsString()
  // @ValidateIf(obj => !!obj.password)
  @MinLength(6, { message: 'Weak password' })
  @IsNotEmpty({ message: 'Add password' })
  public password: string;

  @ValidateIf(obj => !!obj.password)
  @IsString()
  @IsNotEmpty({ message: 'Add re-enter password' })
  public confirm_password: string;
}
