import { Grid, Typography, useTheme, SxProps } from '@mui/material';
import React from 'react';
import { ContentInput } from '../../../components';
import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';
import { useProjectContext } from '../../../contexts';
import {
  EVALUATION_TABLE_FIELDS,
  INDICATOR_TABLE_FIELDS,
  GStyles,
} from '../../../consts';

const textStyle: SxProps = {
  textAlign: 'center',
  fontSize: { xs: 10, sm: 14 },
  fontWeight: 600,
};
const cellBorder: SxProps = {
  borderWidth: 1,
  borderColor: 'lightgray',
  borderStyle: 'solid',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const NOT_EVALUATION_LABEL = 'Will not evaluate as part of this project';

export const Step3DecidingOnIndicators2Page = () => {
  const theme = useTheme();
  const { updateContextValue } = useMainLayoutContext();
  const {
    project,
    projectActions: { updateProject },
  } = useProjectContext();

  React.useEffect(() => {
    updateContextValue({
      title: 'Draft the Indicators of Change',
      pageContainerSx: { maxWidth: theme.breakpoints.values.md },
    });

    return () => {
      updateContextValue({ title: undefined, pageContainerSx: undefined });
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Grid container justifyContent="center">
      <Grid container item xs={10} sx={{ ...cellBorder }}>
        <Grid container item bgcolor="#8cc63f" sx={{ height: 60 }}>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <Typography sx={{ ...textStyle }}>Inputs</Typography>
          </Grid>
          <Grid item xs={4} sx={{ ...cellBorder }}>
            <Typography sx={{ ...textStyle }}>Outputs</Typography>
          </Grid>
          <Grid item xs={6} sx={{ ...cellBorder }}>
            <Typography sx={{ ...textStyle }}>Outcomes</Typography>
          </Grid>
        </Grid>
        <Grid container item bgcolor="#8cc63f" sx={{ height: 60 }}>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <Typography sx={{ ...textStyle }}>Resources</Typography>
          </Grid>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <Typography sx={{ ...textStyle }}>Activities</Typography>
          </Grid>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <Typography sx={{ ...textStyle }}>Participants</Typography>
          </Grid>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <Typography sx={{ ...textStyle }}>
              Short-term
              <br />
              (3 to 12 months)
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <Typography sx={{ ...textStyle }}>
              Intermediate
              <br />
              (12 to 24 months)
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <Typography sx={{ ...textStyle }}>
              Long-term
              <br />
              (24+ months)
            </Typography>
          </Grid>
        </Grid>
        <Grid container item bgcolor="white" sx={{ minHeight: 200 }}>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <ContentInput
              editable={false}
              content={project?.resources}
              styles={{ contentViewer: { container: GStyles.plainInput } }}
            />
          </Grid>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <ContentInput
              editable={false}
              content={project?.activities}
              styles={{ contentViewer: { container: GStyles.plainInput } }}
            />
          </Grid>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <ContentInput
              editable={false}
              content={project?.participants}
              styles={{ contentViewer: { container: GStyles.plainInput } }}
            />
          </Grid>
          <Grid item xs={2} bgcolor="#f2c312" sx={{ ...cellBorder }}>
            <ContentInput
              editable={false}
              content={project?.short_term_goal}
              styles={{ contentViewer: { container: GStyles.plainInput } }}
            />
          </Grid>
          <Grid item xs={2} bgcolor="#f2c312" sx={{ ...cellBorder }}>
            <ContentInput
              editable={false}
              content={project?.intermediate_term_goal}
              styles={{ contentViewer: { container: GStyles.plainInput } }}
            />
          </Grid>
          <Grid item xs={2} bgcolor="#f2c312" sx={{ ...cellBorder }}>
            <ContentInput
              editable={false}
              content={project?.long_term_goal}
              styles={{ contentViewer: { container: GStyles.plainInput } }}
            />
          </Grid>
        </Grid>
        <Grid container item bgcolor="#f2c312" sx={{ minHeight: 200 }}>
          <Grid
            container
            item
            xs={6}
            sx={{ ...cellBorder, pt: 2, alignItems: 'flex-start' }}
          >
            <Grid item xs={12}>
              <Typography sx={{ ...textStyle }}>
                Intervention Strategy(ies)
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ContentInput
                content={project?.intervention_strategy}
                editable={false}
                styles={{
                  contentViewer: { container: GStyles.plainInput },
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={6}
            sx={{ ...cellBorder, pt: 2, alignItems: 'flex-start' }}
          >
            <Grid item xs={12}>
              <Typography sx={{ ...textStyle }}>Influential Factors</Typography>
            </Grid>
            <Grid item xs={12}>
              <ContentInput
                content={project?.influential_factors}
                editable={false}
                styles={{
                  contentViewer: { container: GStyles.plainInput },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          bgcolor="#24a9d4"
          justifyContent="center"
          sx={{ ...cellBorder, py: 1 }}
        >
          <Typography sx={{ ...textStyle, textAlign: 'center' }}>
            Evaluation Questions Here!
          </Typography>
        </Grid>
        <Grid container item bgcolor="#7ecce6" sx={{ minHeight: 200 }}>
          {EVALUATION_TABLE_FIELDS.map((item, index) => (
            <Grid item xs={2} sx={{ ...cellBorder }}>
              <ContentInput
                key={index}
                placeholder=" "
                content={project ? (project as any)[item.projectFieldName] : ''}
                editable={item.editable}
                styles={{
                  contentViewer: {
                    container: {
                      ...GStyles.plainInput,
                      p: 0,
                    },
                    value: GStyles.tableContentText,
                  },
                }}
              />
            </Grid>
          ))}
        </Grid>
        <Grid
          container
          item
          bgcolor="#24a9d4"
          justifyContent="center"
          sx={{ ...cellBorder, py: 1 }}
        >
          <Typography sx={{ ...textStyle, textAlign: 'center' }}>
            Indicators of Change
          </Typography>
        </Grid>
        <Grid container item bgcolor="white" sx={{ minHeight: 200 }}>
          {INDICATOR_TABLE_FIELDS.map((item, index) => (
            <Grid
              key={index}
              item
              xs={2}
              direction="row"
              sx={{ ...cellBorder, flexDirection: 'column' }}
            >
              <ContentInput
                placeholder=" "
                label={
                  !!(project as any)?.[
                    EVALUATION_TABLE_FIELDS[index].projectFieldName
                  ] &&
                  (project as any)?.[
                    EVALUATION_TABLE_FIELDS[index].projectFieldName
                  ] !== NOT_EVALUATION_LABEL
                    ? item.label
                    : ''
                }
                content={
                  !!(project as any)?.[
                    EVALUATION_TABLE_FIELDS[index].projectFieldName
                  ] &&
                  (project as any)?.[
                    EVALUATION_TABLE_FIELDS[index].projectFieldName
                  ] !== NOT_EVALUATION_LABEL
                    ? project
                      ? (project as any)[item.projectFieldName]
                      : ''
                    : ''
                }
                editable={
                  !!(project as any)?.[
                    EVALUATION_TABLE_FIELDS[index].projectFieldName
                  ] &&
                  (project as any)?.[
                    EVALUATION_TABLE_FIELDS[index].projectFieldName
                  ] !== NOT_EVALUATION_LABEL
                }
                styles={{
                  contentViewer: {
                    container: { ...GStyles.plainInput, p: 0 },
                    value: GStyles.tableContentText,
                  },
                  label: GStyles.tableContentText,
                }}
                handleSubmit={value =>
                  updateProject({
                    id: project?.id,
                    [item.projectFieldName]: value,
                  })
                }
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
