import React, { useRef } from 'react';
import { Dialog, useMediaQuery, useTheme } from '@mui/material';

import { DialogHeader, DialogHeaderProps } from './DialogHeader';
import { DialogContent, DialogContentProps } from './DialogContent';
import { DialogFooter, DialogFooterProps } from './DialogFooter';
import { useContentInputContext } from './ContentInputContext';

export type ContentInputDialogProps = {
  visible?: boolean;
  setVisible?: (val: boolean) => void;
  headerProps?: DialogHeaderProps;
  contentProps?: DialogContentProps;
  footerProps?: DialogFooterProps;
};

export const ContentInputDialog = ({
  visible = false,
  setVisible = () => {},
  headerProps: _headerProps = {},
  contentProps: _contentProps = {},
  footerProps: _footerProps = {},
}: ContentInputDialogProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { dialogTitle, label, dialogDescription } = useContentInputContext();

  const formRef = useRef<any>();
  const onSubmit = () => formRef.current.onSubmit();
  const onClose = () => setVisible(false);

  const headerProps: DialogHeaderProps = {
    title: dialogTitle,
    onClose,
    ..._headerProps,
  };

  const contentProps: DialogContentProps = {
    formRef,
    ..._contentProps,
    contentInputFormProps: {
      label,
      dialogDescription,
      next: onClose,
      ..._contentProps.contentInputFormProps,
    },
  };

  const footerProps: DialogFooterProps = {
    onSubmit,
    ..._footerProps,
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth={'sm'}
      open={visible}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      scroll="body"
    >
      <DialogHeader {...headerProps} />

      <DialogContent {...contentProps} />

      <DialogFooter {...footerProps} />
    </Dialog>
  );
};
