import React, { useEffect } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { Image } from 'mui-image';

import introTheEvaluationRoadmapPng from '../../../assets/images/pngs/intro_the_evaluation_roadmap.png';
import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';
import { CircleGrey } from './CircleGrey';

export const IntroTheEvaluationRoadmapPage = () => {
  const theme = useTheme();
  const { updateContextValue } = useMainLayoutContext();

  useEffect(() => {
    updateContextValue({
      title: 'The Evaluation Roadmap',
      pageContainerSx: { maxWidth: theme.breakpoints.values.md },
    });

    return () => {
      updateContextValue({ title: undefined, pageContainerSx: undefined });
    };
    // eslint-disable-next-line
  }, []);

  const options = [
    'This map illustrates what your Scope of Work might look like.',
    'On that map, you may have one or more projects. Together, these projects make up your entire program.',
    'In this module, you will focus on <u>one</u> project at a time.',
  ];

  return (
    <Grid container spacing={4} sx={{ alignItems: 'flex-start' }}>
      <Grid
        item
        xs={12}
        sm={6}
        container
        spacing={4}
        sx={{ mt: { xs: 0, sm: 4 } }}
      >
        <Grid item xs={12} container spacing={1} rowGap={2}>
          {options.map((val, index) => (
            <Grid key={`option-${index}`} item xs={12}>
              <Grid container spacing={2}>
                <Grid item>
                  <CircleGrey sx={{ mt: 0.5 }} />
                </Grid>

                <Grid item sx={{ flex: 1 }}>
                  <Typography
                    component="span"
                    sx={{ fontSize: 18 }}
                    dangerouslySetInnerHTML={{ __html: val }}
                  />
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Image
          src={introTheEvaluationRoadmapPng}
          style={{ objectFit: 'contain' }}
          duration={500}
        />
      </Grid>
    </Grid>
  );
};
