export const ROUTES = {
  /**
   * Global
   */
  ROOT: '/',
  HOME: '/home',
  GOOD_WORK: 'good-work',
  YOUR_EVALUATION_ROADMAP2: 'your-evaluation-roadmap2',
  YOUR_EVALUATION_ROADMAP3: 'your-evaluation-roadmap3',

  /**
   * General
   */
  HELP: 'help',
  TA_CONTACT: 'ta-contact',

  /**
   * Auth
   */
  AUTH: 'auth',
  LOGIN: 'login',
  SIGNUP: 'signup',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'password-reset',

  /**
   * Account
   */
  MY_ACCOUNT: 'account',
  VIEW: 'view',
  EDIT: 'edit',

  /**
   * Projects
   */
  PROJECTS: 'projects',

  /**
   * Evaluation form
   */
  EVALUATION_FORM: 'evaluation-form',

  /**
   * Intro
   */
  INTRO: 'intro',
  DIAGRAMS_YOU_WILL_CREATE_1: 'diagrams-you-will-create-1',
  DIAGRAMS_YOU_WILL_CREATE_2: 'diagrams-you-will-create-2',
  GETTING_YOUR_BEARINGS: 'getting-your-bearings',
  MODULE_OBJECTIVES: 'module-objectives',
  THE_EVALUATION_ROADMAP: 'the-evaluation-roadmap',

  /**
   * Step 1
   */
  STEP_1: 'step-1',
  CREATE_PROJECT_THEORY_DIAGRAM_1: 'create-project-theory-diagram-1',
  CREATE_PROJECT_THEORY_DIAGRAM_2: 'create-project-theory-diagram-2',
  CREATE_PROJECT_THEORY_DIAGRAM_3: 'create-project-theory-diagram-3',
  CREATE_PROJECT_THEORY_DIAGRAM_4: 'create-project-theory-diagram-4',
  CREATE_PROJECT_THEORY_DIAGRAM_5: 'create-project-theory-diagram-5',
  PROJECT_PROFILE: 'project-profile',
  PROJECT_PROFILE_EDIT: 'project-profile-edit',
  PROJECT_THEORY_DIAGRAM_1: 'project-theory-diagram-1',
  PROJECT_THEORY_DIAGRAM_2: 'project-theory-diagram-2',
  PROJECT_THEORY_DIAGRAM_FOR_PROJECT: 'project-theory-diagram-for-project',

  /**
   * Step 2
   */
  STEP_2: 'step-2',
  BENEFIT_OF_PORJECT_FLOW_DIAGRAM: 'benefit-of-project-flow-diagram',
  CREATE_PROJECT_FLOW_DIAGRAM_1: 'create-project-flow-diagram-1',
  CREATE_PROJECT_FLOW_DIAGRAM_2: 'create-project-flow-diagram-2',
  CREATE_PROJECT_FLOW_DIAGRAM_3: 'create-project-flow-diagram-3',
  CREATE_PROJECT_FLOW_DIAGRAM_4: 'create-project-flow-diagram-4',
  CREATE_PROJECT_FLOW_DIAGRAM_5: 'create-project-flow-diagram-5',
  PROJECT_FLOW_DIAGRAM_FOR_PROJECT: 'project-flow-diagram-for-project',

  /**
   * Step 3
   */
  STEP_3: 'step-3',
  ALIGN_EVALUATION_QUESTIONS: 'align-evaluation-questions',
  CHOOSE_EVALUATION_QUESTIONS: 'choose-evaluation-questions',
  CREATE_PROJECT_EVALUATION_PLAN: 'create-project-evaluation-plan',
  DATA_AND_DATA_COLLECTION_TIMING: 'data-and-data-collection-timing',
  DECIDING_ON_INDICATORS_1: 'deciding-on-indicators-1',
  DECIDING_ON_INDICATORS_2: 'deciding-on-indicators-2',
  DECIDING_ON_INDICATORS_OF_CHANGE: 'deciding-on-indicators-of-change',
  EVALUATION_PLAN_IN_PROGRESS_1: 'evaluation-plan-in-progress-1',
  EVALUATION_PLAN_IN_PROGRESS_2: 'evaluation-plan-in-progress-2',
  EVALUATION_PLAN_IN_PROGRESS_4: 'evaluation-plan-in-progress-4',
  IDENITIFY_STAKEHOLDERS_1: 'idenify-stakeholders-1',
  IDENITIFY_STAKEHOLDERS_2: 'idenify-stakeholders-2',
  KNOW_YOUR_EVALUATION_FOCUS: 'know-your-evaluation-focus',
  OUTCOME_DATA_EXAMPLE: 'outcome-data-example',
  OUTPUT_OR_PROCESS_DATA_EXAMPLE: 'output-or-process-data-example',
  PROCESS_EVALUATION_QUESTIONS: 'process-evaluation-questions',
  PROJECT_EVALUATION_PLAN_FOR_PROJECT: 'project-evaluation-plan-for-project',
  SELECT_YOUR_DATA_MEASURES_AND_TIMING: 'select-your-data-measures-and-timing',
  USING_EVALUATION_FINDINGS: 'using-evaluation-findings',
  THANK_YOU: 'thankyou-info',
};
