import { SxProps } from '@mui/material';
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ContentViewerStyleProps } from './ContentViewer';
import { ContentOptionProps } from '../ContentOptions';
import { ContentMultiOptionProps } from '../ContentMultiOptions';

type ContentInputContextType = {
  type?: 'input' | 'options' | 'multi-options';
  label: string;
  optionProps?: ContentOptionProps;
  multiOptionProps?: ContentMultiOptionProps;
  placeholder?: string;
  content: string;
  dialogTitle: string;
  dialogDescription: string;
  editable?: boolean;
  styles?: {
    contentViewer?: ContentViewerStyleProps;
    label?: SxProps;
  };
  updateContextValue: (
    payload: Partial<Omit<ContentInputContextType, 'updateContextValue'>>
  ) => void;
  handleSubmit: (value: string) => void;
};

const defaultContentInputContextValue: ContentInputContextType = {
  label: '',
  placeholder: '',
  content: '',
  dialogTitle: '',
  dialogDescription: '',
  editable: true,
  updateContextValue: () => {},
  handleSubmit: () => {},
};

export const ContentInputContext = createContext<ContentInputContextType>(
  defaultContentInputContextValue
);

export type ContentInputContextProviderProps = {
  type?: 'input' | 'options' | 'multi-options';
  label?: string;
  optionProps?: ContentOptionProps;
  multiOptionProps?: ContentMultiOptionProps;
  placeholder?: string;
  content?: string;
  dialogTitle?: string;
  dialogDescription?: string;
  editable?: boolean;
  styles?: {
    contentViewer?: ContentViewerStyleProps;
    label?: SxProps;
  };
  handleSubmit?: (value: string) => void;
};

export const ContentInputContextProvider = ({
  type = 'input',
  optionProps,
  multiOptionProps,
  children,
  label = '',
  placeholder = '',
  content = '',
  dialogTitle = '',
  dialogDescription = '',
  editable = true,
  styles,
  handleSubmit = () => {},
}: PropsWithChildren<ContentInputContextProviderProps>) => {
  const [contextValue, setContextValue] = useState<ContentInputContextType>({
    ...defaultContentInputContextValue,
    type,
    optionProps,
    multiOptionProps,
    label,
    placeholder,
    content,
    dialogTitle,
    dialogDescription,
    editable,
    styles,
  });

  useEffect(() => {
    setContextValue(prev => ({ ...prev, content }));
  }, [content]);

  useEffect(() => {
    setContextValue(prev => ({
      ...prev,
      optionProps,
      multiOptionProps,
      editable,
    }));
  }, [optionProps, multiOptionProps, editable]);

  useEffect(() => {
    setContextValue(prev => ({ ...prev, styles }));
  }, [styles]);

  const updateContextValue = (
    payload: Partial<Omit<ContentInputContextType, 'updateContextValue'>>
  ) => setContextValue(prev => ({ ...prev, ...payload }));

  return (
    <ContentInputContext.Provider
      value={{ ...contextValue, updateContextValue, handleSubmit }}
    >
      {children}
    </ContentInputContext.Provider>
  );
};

export const useContentInputContext = () => {
  const contextValue = useContext(ContentInputContext);

  return { ...contextValue };
};
