import {
  Box,
  Grid,
  SxProps,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import Image from 'mui-image';
import React from 'react';

import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';
import arrowRedDown from '../../../assets/images/pngs/arrow_red_down.png';
import imgStep2 from '../../../assets/images/pngs/intro_diagram_step_2_no_bar.png';
import { Description } from '../step1/Description';
import { ContentInput } from '../../../components';
import { useProjectContext } from '../../../contexts';

const textStyle: SxProps = {
  textAlign: 'center',
  fontSize: { xs: 10, sm: 14 },
  fontWeight: 600,
};
const cellBorder: SxProps = {
  borderWidth: 2,
  borderColor: 'lightgray',
  borderStyle: 'solid',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const contentInputStyle: SxProps = {
  bgcolor: 'transparent',
  boxShadow: 'none',
  px: 1,
  height: '100%',
};

const descriptionItems = [
  {
    text: `Describe Specific Project `,
  },
  {
    text: 'Activities.',
    tooltip: `What you will do in order to achieve the desired outcomes of your project.`,
  },
];

export const Step2CreateProjectFlowDiagram4Page = () => {
  const theme = useTheme();
  const { updateContextValue } = useMainLayoutContext();
  const {
    project,
    projectActions: { updateProject },
  } = useProjectContext();

  React.useEffect(() => {
    updateContextValue({
      title: 'Describe the Project Activities',
      pageContainerSx: { maxWidth: theme.breakpoints.values.md },
    });

    return () => {
      updateContextValue({ title: undefined, pageContainerSx: undefined });
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container justifyContent="center">
      <Grid container item>
        <Grid item xs={8}>
          <Description items={descriptionItems} />
          <Typography sx={{ my: 2 }}>
            2. What specific activities will you do to reach your participants
            and move them to action?
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={9} sx={{ ...cellBorder }}>
        <Grid container item bgcolor="#8cc63f" sx={{ height: 60 }}>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <Typography sx={{ ...textStyle }}>Inputs</Typography>
          </Grid>
          <Grid item xs={4} sx={{ ...cellBorder }}>
            <Typography sx={{ ...textStyle }}>Outputs</Typography>
          </Grid>
          <Grid item xs={6} sx={{ ...cellBorder }}>
            <Typography sx={{ ...textStyle }}>Outcomes</Typography>
          </Grid>
        </Grid>
        <Grid container item bgcolor="#8cc63f" sx={{ height: 60 }}>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <Typography sx={{ ...textStyle }}>Resources</Typography>
          </Grid>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <Typography sx={{ ...textStyle }}>Activities</Typography>
          </Grid>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <Typography sx={{ ...textStyle }}>Participants</Typography>
          </Grid>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <Typography sx={{ ...textStyle }}>
              Short-term
              <br />
              (3 to 12 months)
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <Typography sx={{ ...textStyle }}>
              Intermediate
              <br />
              (12 to 24 months)
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <Typography sx={{ ...textStyle }}>
              Long-term
              <br />
              (24+ months)
            </Typography>
          </Grid>
        </Grid>
        <Grid container item bgcolor="white" sx={{ minHeight: 200 }}>
          <Grid item xs={2} sx={{ ...cellBorder }}></Grid>
          <Grid item xs={2} sx={{ ...cellBorder, position: 'relative' }}>
            <ContentInput
              styles={{
                contentViewer: { container: contentInputStyle },
              }}
              content={project?.activities}
              handleSubmit={value =>
                updateProject({
                  id: project?.id,
                  activities: value,
                })
              }
            />
            <Box sx={{ position: 'absolute', top: -20 }}>
              <Image
                src={arrowRedDown}
                duration={100}
                height={30}
                width={40}
                fit="fill"
              />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <ContentInput
              content={project?.participants}
              editable={false}
              styles={{
                contentViewer: { container: contentInputStyle },
              }}
            />
          </Grid>
          <Grid item xs={2} bgcolor="#f2c312" sx={{ ...cellBorder }}>
            <ContentInput
              content={project?.short_term_goal}
              editable={false}
              styles={{
                contentViewer: { container: contentInputStyle },
              }}
            />
          </Grid>
          <Grid item xs={2} bgcolor="#f2c312" sx={{ ...cellBorder }}>
            <ContentInput
              content={project?.intermediate_term_goal}
              editable={false}
              styles={{
                contentViewer: { container: contentInputStyle },
              }}
            />
          </Grid>
          <Grid item xs={2} bgcolor="#f2c312" sx={{ ...cellBorder }}>
            <ContentInput
              content={project?.long_term_goal}
              editable={false}
              styles={{
                contentViewer: { container: contentInputStyle },
              }}
            />
          </Grid>
        </Grid>
        <Grid container item bgcolor="#f2c312" sx={{ minHeight: 200 }}>
          <Grid
            container
            item
            xs={6}
            sx={{ ...cellBorder, pt: 2, alignItems: 'flex-start' }}
          >
            <Grid item xs={12}>
              <Typography sx={{ ...textStyle }}>
                Intervention Strategy(ies)
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ContentInput
                content={project?.intervention_strategy}
                editable={false}
                styles={{
                  contentViewer: { container: contentInputStyle },
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={6}
            sx={{ ...cellBorder, pt: 2, alignItems: 'flex-start' }}
          >
            <Grid item xs={12}>
              <Typography sx={{ ...textStyle }}>Influential Factors</Typography>
            </Grid>
            <Grid item xs={12}>
              <ContentInput
                content={project?.influential_factors}
                editable={false}
                styles={{
                  contentViewer: { container: contentInputStyle },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={3}
        sx={{
          alignSelf: 'start',
          justifyContent: 'center',
          mt: -8,
        }}
      >
        <Tooltip
          arrow
          sx={{ fontSize: 18 }}
          title={
            <Box sx={{ p: 1 }}>
              <Typography variant="body2">
                Outreach, engagement and education of local health care
                providers about pre-diabetes, and ways to identify and refer
                patients at risk for diabetes.
              </Typography>
            </Box>
          }
          PopperProps={{
            sx: {
              '& .MuiTooltip-arrow': {
                left: '-16px !important',
              },
            },
          }}
        >
          <Box>
            <Image src={imgStep2} duration={500} width={140} fit="contain" />
          </Box>
        </Tooltip>
      </Grid>
    </Grid>
  );
};
