import { EvaluationError } from '../@types';

export const mapError = (error: any): EvaluationError => {
  if (error.response && error.response.data) {
    return {
      ...error.response.data,
      message: error.response.data.message || error.response.data.error,
    };
  }
  if (error.message) {
    return { message: error.message, status: undefined };
  }
  return { message: 'Unknown Error.', status: undefined };
};
