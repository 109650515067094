import React from 'react';
import { Box, Container, Hidden, Paper } from '@mui/material';
import Image from 'mui-image';

import { useAudioPlayerContext } from './AudioPlayerContext';
import audioImage from '../../assets/images/jpegs/audio.jpg';
import { AudioPlayButton } from './AudioPlayButton';
import { AudioSeekBar } from './AudioSeekBar';
import { AudioVolumeButton } from './AudioVolumeButton';

export const AudioPlayerUI = () => {
  const {
    src,
    player,
    muted,
    duration,
    seek,
    paused,
    volumeAmount,
    seekPlayer,
    pausePlayer,
    onChangeVolume,
    updateContextValue,
  } = useAudioPlayerContext();

  return (
    <Container
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Hidden mdDown>
        <Image
          src={audioImage}
          wrapperStyle={{ width: 50, marginRight: 10 }}
          style={{ objectFit: 'contain' }}
          duration={0}
        />
      </Hidden>
      <Box
        alignItems="center"
        component={Paper}
        // bgcolor="primary.main"
        elevation={0}
        sx={{
          p: 0.5,
          width: 280,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <AudioPlayButton paused={paused} onClick={() => pausePlayer(!paused)} />
        <AudioSeekBar
          duration={duration}
          position={seek}
          containerStyle={{ mx: 1 }}
          onSeek={value => seekPlayer(value)}
        />
        <AudioVolumeButton
          muted={muted}
          amount={volumeAmount}
          onChangeVolume={onChangeVolume}
          onClick={() => updateContextValue({ muted: !muted })}
        />
      </Box>
      <audio
        ref={player}
        controls
        autoPlay
        hidden
        muted={muted}
        preload="metadata"
      >
        <source src={src} />
      </audio>
    </Container>
  );
};
