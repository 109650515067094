import React from 'react';
import { Grid } from '@mui/material';

import { TechnicalIssueForm } from '../forms';
import { PageTitle } from '../components';

export const HelpPage = () => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <PageTitle title="To Report Technical Difficulties:" />
      </Grid>

      <Grid item xs={12}>
        <TechnicalIssueForm />
      </Grid>
    </Grid>
  );
};
