import React, { useEffect } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import Image from 'mui-image';

import introDiagramStep1Png from '../../../assets/images/pngs/intro_diagram_step_1.png';
import introDiagramStep2Png from '../../../assets/images/pngs/intro_diagram_step_2.png';
import introDiagramStep3Png from '../../../assets/images/pngs/intro_diagram_step_3.png';
import redArrowRightPng from '../../../assets/images/pngs/red_arrow_right.png';
import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';
import { CircleGrey } from './CircleGrey';

export const IntroDiagramsYouWillCreate1Page = () => {
  const theme = useTheme();
  const { updateContextValue } = useMainLayoutContext();

  useEffect(() => {
    updateContextValue({
      title: 'The Activities in Each Diagram',
      pageContainerSx: { maxWidth: theme.breakpoints.values.md },
    });

    return () => {
      updateContextValue({ title: undefined, pageContainerSx: undefined });
    };
    // eslint-disable-next-line
  }, []);

  const step1Options = [
    `Define the problem`,
    `Describe the data and rationale for the strategies you will employ`,
    `Specify desired results`,
  ];

  const step2Options = [
    `This is also known as a logic model`,
    `Create a graphic of the relationship between the project’s resources, activities, and intended effects`,
    `Create a visual of activities and expected results`,
  ];

  const step3Options = [
    `Add evaluation focus`,
    `Define audiences`,
    `Write questions`,
    `Select indicators`,
    `Describe data collection and analysis`,
    `Share how results will be used`,
  ];

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography color="textSecondary">
          Start by picking a project with your team.
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        container
        spacing={4}
        sx={{ alignItems: 'flex-start' }}
      >
        <Grid item sx={{ width: '25%' }}>
          <Image
            src={introDiagramStep1Png}
            style={{ objectFit: 'contain' }}
            duration={500}
          />

          <Grid container sx={{ mt: 2 }}>
            {step1Options.map((val, index) => (
              <Grid key={`option-${index}`} item xs={12} sx={{ mt: 0.5 }}>
                <Grid container spacing={2}>
                  <Grid item>
                    <CircleGrey
                      sx={{
                        width: 6,
                        height: 6,
                        bgcolor: theme.palette.grey[600],
                        mt: 1,
                      }}
                    />
                  </Grid>

                  <Grid item sx={{ flex: 1 }}>
                    <Typography variant="body2">{val}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item sx={{ flex: 1, height: '50%' }}>
          <Image
            src={redArrowRightPng}
            style={{ objectFit: 'contain' }}
            duration={500}
          />
        </Grid>

        <Grid item sx={{ width: '25%' }}>
          <Image
            src={introDiagramStep2Png}
            style={{ objectFit: 'contain' }}
            duration={500}
          />

          <Grid container sx={{ mt: 2 }}>
            {step2Options.map((val, index) => (
              <Grid key={`option-${index}`} item xs={12} sx={{ mt: 0.5 }}>
                <Grid container spacing={2}>
                  <Grid item>
                    <CircleGrey
                      sx={{
                        width: 6,
                        height: 6,
                        bgcolor: theme.palette.grey[600],
                        mt: 1,
                      }}
                    />
                  </Grid>

                  <Grid item sx={{ flex: 1 }}>
                    <Typography variant="body2">{val}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item sx={{ flex: 1, height: '50%' }}>
          <Image
            src={redArrowRightPng}
            style={{ objectFit: 'contain' }}
            duration={500}
          />
        </Grid>

        <Grid item sx={{ width: '25%' }}>
          <Image
            src={introDiagramStep3Png}
            style={{ objectFit: 'contain' }}
            duration={500}
          />

          <Grid container sx={{ mt: 2 }}>
            {step3Options.map((val, index) => (
              <Grid key={`option-${index}`} item xs={12} sx={{ mt: 0.5 }}>
                <Grid container spacing={2}>
                  <Grid item>
                    <CircleGrey
                      sx={{
                        width: 6,
                        height: 6,
                        bgcolor: theme.palette.grey[600],
                        mt: 1,
                      }}
                    />
                  </Grid>

                  <Grid item sx={{ flex: 1 }}>
                    <Typography variant="body2">{val}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
