import { Grid, Typography, useTheme, SxProps } from '@mui/material';
import React from 'react';
import { ContentInput } from '../../../components';
import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';
import { useProjectContext } from '../../../contexts';
import { EVALUATION_QUESTIONS } from '../../../consts';

const textStyle: SxProps = {
  textAlign: 'center',
  fontSize: { xs: 10, sm: 14 },
  fontWeight: 600,
};
const cellBorder: SxProps = {
  borderWidth: 1,
  borderColor: 'lightgray',
  borderStyle: 'solid',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const contentInputStyle: SxProps = {
  bgcolor: 'transparent',
  boxShadow: 'none',
  px: 1,
  height: '100%',
};

const NOT_EVALUATION_LABEL = 'Will not evaluate as part of this project';

export const Step3EvaluationPlanInProgress1Page = () => {
  const theme = useTheme();
  const { updateContextValue } = useMainLayoutContext();
  const {
    project,
    projectActions: { updateProject },
  } = useProjectContext();

  React.useEffect(() => {
    updateContextValue({
      title: 'Do Evaluation Questions Align?',
      pageContainerSx: { maxWidth: theme.breakpoints.values.md },
    });

    return () => {
      updateContextValue({ title: undefined, pageContainerSx: undefined });
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Grid container justifyContent="center">
      <Grid container item xs={10} sx={{ ...cellBorder }}>
        <Grid container item bgcolor="#8cc63f" sx={{ height: 60 }}>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <Typography sx={{ ...textStyle }}>Inputs</Typography>
          </Grid>
          <Grid item xs={4} sx={{ ...cellBorder }}>
            <Typography sx={{ ...textStyle }}>Outputs</Typography>
          </Grid>
          <Grid item xs={6} sx={{ ...cellBorder }}>
            <Typography sx={{ ...textStyle }}>Outcomes</Typography>
          </Grid>
        </Grid>
        <Grid container item bgcolor="#8cc63f" sx={{ height: 60 }}>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <Typography sx={{ ...textStyle }}>Resources</Typography>
          </Grid>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <Typography sx={{ ...textStyle }}>Activities</Typography>
          </Grid>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <Typography sx={{ ...textStyle }}>Participants</Typography>
          </Grid>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <Typography sx={{ ...textStyle }}>
              Short-term
              <br />
              (3 to 12 months)
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <Typography sx={{ ...textStyle }}>
              Intermediate
              <br />
              (12 to 24 months)
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <Typography sx={{ ...textStyle }}>
              Long-term
              <br />
              (24+ months)
            </Typography>
          </Grid>
        </Grid>
        <Grid container item bgcolor="white" sx={{ minHeight: 200 }}>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <ContentInput
              styles={{
                contentViewer: { container: contentInputStyle },
              }}
              content={project?.resources}
              editable={false}
            />
          </Grid>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <ContentInput
              styles={{
                contentViewer: { container: contentInputStyle },
              }}
              content={project?.activities}
              editable={false}
            />
          </Grid>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <ContentInput
              styles={{
                contentViewer: { container: contentInputStyle },
              }}
              content={project?.participants}
              editable={false}
            />
          </Grid>
          <Grid item xs={2} bgcolor="#f2c312" sx={{ ...cellBorder }}>
            <ContentInput
              styles={{
                contentViewer: { container: contentInputStyle },
              }}
              content={project?.short_term_goal}
              editable={false}
            />
          </Grid>
          <Grid item xs={2} bgcolor="#f2c312" sx={{ ...cellBorder }}>
            <ContentInput
              styles={{
                contentViewer: { container: contentInputStyle },
              }}
              content={project?.intermediate_term_goal}
              editable={false}
            />
          </Grid>
          <Grid item xs={2} bgcolor="#f2c312" sx={{ ...cellBorder }}>
            <ContentInput
              styles={{
                contentViewer: { container: contentInputStyle },
              }}
              content={project?.intermediate_term_goal}
              editable={false}
            />
          </Grid>
        </Grid>
        <Grid container item bgcolor="#f2c312" sx={{ minHeight: 200 }}>
          <Grid
            container
            item
            xs={6}
            sx={{ ...cellBorder, pt: 2, alignItems: 'flex-start' }}
          >
            <Grid item xs={12}>
              <Typography sx={{ ...textStyle }}>
                Intervention Strategy(ies)
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ContentInput
                content={project?.intervention_strategy}
                editable={false}
                styles={{
                  contentViewer: { container: contentInputStyle },
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={6}
            sx={{ ...cellBorder, pt: 2, alignItems: 'flex-start' }}
          >
            <Grid item xs={12}>
              <Typography sx={{ ...textStyle }}>Influential Factors</Typography>
            </Grid>
            <Grid item xs={12}>
              <ContentInput
                content={project?.influential_factors}
                editable={false}
                styles={{
                  contentViewer: { container: contentInputStyle },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          bgcolor="#24a9d4"
          justifyContent="center"
          sx={{ ...cellBorder, py: 1 }}
        >
          <Typography sx={{ ...textStyle, textAlign: 'center' }}>
            Evaluation Questions Here!
          </Typography>
        </Grid>
        <Grid container item bgcolor="#7ecce6" sx={{ minHeight: 200 }}>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <ContentInput
              editable={project?.question_resources !== NOT_EVALUATION_LABEL}
              type="options"
              optionProps={{
                list: EVALUATION_QUESTIONS[0].options,
                hasCustomOption: true,
                selected: project?.question_resources || null,
              }}
              content={project?.question_resources}
              styles={{
                contentViewer: { container: contentInputStyle },
              }}
              handleSubmit={value =>
                updateProject({ id: project?.id, question_resources: value })
              }
            />
          </Grid>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <ContentInput
              type="options"
              editable={project?.question_activities !== NOT_EVALUATION_LABEL}
              optionProps={{
                list: EVALUATION_QUESTIONS[1].options,
                hasCustomOption: true,
                selected: project?.question_activities || null,
              }}
              content={project?.question_activities}
              styles={{
                contentViewer: { container: contentInputStyle },
              }}
            />
          </Grid>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <ContentInput
              type="options"
              editable={project?.question_participants !== NOT_EVALUATION_LABEL}
              optionProps={{
                list: EVALUATION_QUESTIONS[2].options,
                hasCustomOption: true,
                selected: project?.question_participants || null,
              }}
              content={project?.question_participants}
              styles={{
                contentViewer: { container: contentInputStyle },
              }}
            />
          </Grid>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <ContentInput
              type="options"
              editable={project?.question_short_term !== NOT_EVALUATION_LABEL}
              optionProps={{
                list: EVALUATION_QUESTIONS[3].options,
                hasCustomOption: true,
                selected: project?.question_short_term || null,
              }}
              content={project?.question_short_term}
              styles={{
                contentViewer: { container: contentInputStyle },
              }}
            />
          </Grid>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <ContentInput
              type="options"
              editable={project?.question_intermediate !== NOT_EVALUATION_LABEL}
              optionProps={{
                list: EVALUATION_QUESTIONS[4].options,
                hasCustomOption: true,
                selected: project?.question_intermediate || null,
              }}
              content={project?.question_intermediate}
              styles={{
                contentViewer: { container: contentInputStyle },
              }}
            />
          </Grid>
          <Grid item xs={2} sx={{ ...cellBorder }}>
            <ContentInput
              type="options"
              editable={project?.question_long_term !== NOT_EVALUATION_LABEL}
              optionProps={{
                list: EVALUATION_QUESTIONS[5].options,
                hasCustomOption: true,
                selected: project?.question_long_term || null,
              }}
              content={project?.question_long_term}
              styles={{
                contentViewer: { container: contentInputStyle },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
