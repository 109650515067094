import { Box, Slider, SxProps, Typography } from '@mui/material';
import React from 'react';
import { AudioUtils } from '../../utils';

interface AudioSeekBarProps {
  position: number;
  duration: number;
  containerStyle?: SxProps;
  onSeek?: (value: number) => void;
}

export const AudioSeekBar = ({
  position,
  duration,
  containerStyle,
  onSeek,
}: AudioSeekBarProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: 1,
        mt: 1.5,
        ...containerStyle,
      }}
    >
      <Slider
        size="small"
        value={position}
        min={0}
        step={1}
        max={duration}
        onChange={(_, value) => onSeek && onSeek(value as number)}
        sx={{
          color: '#0297d0',
          height: 4,
          '& .MuiSlider-thumb': {
            width: 8,
            height: 8,
            transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
            '&:before': {
              boxShadow: '0 2px 12px 0 #0297d0',
            },
            '&:hover, &.Mui-focusVisible': {
              boxShadow: `0px 0px 0px 2px ${'#0297d0'}`,
            },
            '&.Mui-active': {
              width: 10,
              height: 10,
              boxShadow: `0px 0px 0px 4px ${'#0297d010'}`,
            },
          },
          '& .MuiSlider-rail': {
            opacity: 0.28,
          },
        }}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mt: -1,
          width: '100%',
        }}
      >
        <Typography
          variant="caption"
          sx={{ opacity: 0.38, fontWeight: 500, letterSpacing: 0.2 }}
        >
          {AudioUtils.formatDuration(position)}
        </Typography>
        <Typography
          variant="caption"
          sx={{ opacity: 0.38, fontWeight: 500, letterSpacing: 0.2 }}
        >
          -{AudioUtils.formatDuration(duration - position)}
        </Typography>
      </Box>
    </Box>
  );
};
