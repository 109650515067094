import React, { useEffect } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { Image } from 'mui-image';

import gettingYourBearingsJpeg from '../../../assets/images/jpegs/getting_your_bearings.jpeg';
import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';
import { CircleGrey } from './CircleGrey';

export const IntroGettingYourBearingsPage = () => {
  const theme = useTheme();
  const { updateContextValue } = useMainLayoutContext();

  useEffect(() => {
    updateContextValue({
      title: 'Getting Your Bearings',
      pageContainerSx: { maxWidth: theme.breakpoints.values.md },
    });

    return () => {
      updateContextValue({ title: undefined, pageContainerSx: undefined });
    };
    // eslint-disable-next-line
  }, []);

  const options = [
    'Team members and any relevant information',
    'Documents that detail goals, objective and activites',
    'Grant application and evaluation guidance',
    'State, regional and local data',
  ];

  return (
    <Grid container spacing={4} sx={{ alignItems: 'flex-start' }}>
      <Grid item xs={12} sm={6} container spacing={4}>
        <Grid item xs={12}>
          <Typography color="textSecondary">
            In order to successfully navigate this module, you should have a few
            important items on hand:
          </Typography>
        </Grid>

        <Grid item xs={12} container spacing={2}>
          {options.map((val, index) => (
            <Grid key={`option-${index}`} item xs={12}>
              <Grid container spacing={2}>
                <Grid item>
                  <CircleGrey sx={{ mt: 0.5 }} />
                </Grid>

                <Grid item sx={{ flex: 1 }}>
                  <Typography>{val}</Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Image
          src={gettingYourBearingsJpeg}
          style={{ objectFit: 'contain' }}
          duration={500}
        />
      </Grid>
    </Grid>
  );
};
