import React, { useEffect } from 'react';
import { Grid, useTheme } from '@mui/material';

import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';
import { ExampleView } from './ExampleView';
import { Description } from './Description';
import { ContentInput } from '../../../components';
import { useProjectContext } from '../../../contexts';

const descriptionItems = [
  {
    text: `List `,
  },
  {
    text: 'Intervention Strategy(ies).',
    tooltip: `Evidence can range from objective to subjective. As public health practitioners, we must identify the science-based evidence in order to make good decisions. Many strategies can be found in CDC’s Community Guide and the Public Health Service’s Clinical Guidelines.`,
  },
];

const exampleTooltip = `1. Community mobilization combined with stronger local laws directed at retailers, active enforcement of retailer sales laws, and retailer education with reinforcement is recommended by the Community Guide (CDC).
  \n‎ \n2. The CDC and ADA recommend diabetes prevention programs that utilize education and training to increase physical activity and improve nutrition. These programs increase the average physical activity levels by x% and decrease overall weight by x%.`;

export const Step1CreateProjectTheoryDiagram4Page = () => {
  const theme = useTheme();
  const { updateContextValue } = useMainLayoutContext();
  const {
    project,
    projectActions: { updateProject },
  } = useProjectContext();

  useEffect(() => {
    updateContextValue({
      title: 'List Interventions Strategies',
      pageContainerSx: { maxWidth: theme.breakpoints.values.md },
    });

    return () => {
      updateContextValue({ title: undefined, pageContainerSx: undefined });
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container spacing={4} sx={{ alignItems: 'flex-start' }}>
      <Grid item xs={12} sm={7} md={8} lg={9} container spacing={4}>
        <Grid item xs={12}>
          <Description items={descriptionItems} />
        </Grid>

        <Grid item xs={12}>
          <ContentInput
            label={`4. Briefly describe the strategy(ies) you will use to achieve the results. Your strategy(ies) may be informed or innovative.
            \nProvide supporting sources for each strategy (e.g. The Community Guide, Centers for Disease Control, peer-reviewed literature, etc.)`}
            dialogTitle="Intervention Strategy"
            content={project?.intervention_strategy}
            handleSubmit={value =>
              updateProject({ id: project?.id, intervention_strategy: value })
            }
          />
        </Grid>
      </Grid>

      <Grid item xs={12} sm={5} md={4} lg={3}>
        <ExampleView tooltipTitle={exampleTooltip} />
      </Grid>
    </Grid>
  );
};
