import React from 'react';
import { Outlet } from 'react-router-dom';
import { ProjectContextProvider } from '../contexts';

export const ProjectLayout = () => {
  return (
    <ProjectContextProvider>
      <Outlet />
    </ProjectContextProvider>
  );
};
