import React, { useEffect } from 'react';
import { Box, Grid, useTheme } from '@mui/material';
import Image from 'mui-image';

import introDiagramStep1Png from '../../../assets/images/pngs/intro_diagram_step_1.png';
import introDiagramStep2Png from '../../../assets/images/pngs/intro_diagram_step_2.png';
import introDiagramStep3Png from '../../../assets/images/pngs/intro_diagram_step_3.png';
import introDiagramStep1TablePng from '../../../assets/images/pngs/intro_diagram_step_1_table.png';
import introDiagramStep2TablePng from '../../../assets/images/pngs/intro_diagram_step_2_table.png';
import introDiagramStep3TablePng from '../../../assets/images/pngs/intro_diagram_step_3_table.png';
import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';
import { ImageDialog } from '../../../components';

export const IntroDiagramsYouWillCreate2Page = () => {
  const theme = useTheme();
  const { updateContextValue } = useMainLayoutContext();
  const [selectedImage, setSelectedImage] = React.useState<string>();

  useEffect(() => {
    updateContextValue({
      title: 'A Closer Look at the Diagrams You Will Create',
      pageContainerSx: { maxWidth: theme.breakpoints.values.md },
    });

    return () => {
      updateContextValue({ title: undefined, pageContainerSx: undefined });
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Box sx={{ my: 4 }}>
      <Grid container spacing={10} sx={{ alignItems: 'flex-start' }}>
        <Grid item xs={12} sm={4}>
          <Image
            src={introDiagramStep1Png}
            style={{ objectFit: 'contain' }}
            duration={500}
          />
          <Box
            onClick={() => setSelectedImage(introDiagramStep1TablePng)}
            sx={{ cursor: 'pointer' }}
          >
            <Image
              src={introDiagramStep1TablePng}
              style={{ objectFit: 'contain', marginTop: 32 }}
              duration={500}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Image
            src={introDiagramStep2Png}
            style={{ objectFit: 'contain' }}
            duration={500}
          />
          <Box
            onClick={() => setSelectedImage(introDiagramStep2TablePng)}
            sx={{ cursor: 'pointer' }}
          >
            <Image
              src={introDiagramStep2TablePng}
              style={{ objectFit: 'contain', marginTop: 32 }}
              duration={500}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Image
            src={introDiagramStep3Png}
            style={{ objectFit: 'contain' }}
            duration={500}
          />
          <Box
            onClick={() => setSelectedImage(introDiagramStep3TablePng)}
            sx={{ cursor: 'pointer' }}
          >
            <Image
              src={introDiagramStep3TablePng}
              style={{ objectFit: 'contain', marginTop: 32 }}
              duration={500}
            />
          </Box>
        </Grid>
      </Grid>
      <ImageDialog
        open={!!selectedImage}
        src={selectedImage}
        onClose={() => setSelectedImage(undefined)}
      />
    </Box>
  );
};
