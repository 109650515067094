import React from 'react';
import { Box, SxProps, Grid, Typography, useTheme } from '@mui/material';

import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';
import { ContentInput, PDFButton } from '../../../components';
import { useProjectContext } from '../../../contexts';
import {
  EVALUATION_PLAN_HEADERS,
  STACK_HOLDER_OPTIONS,
  EVALUATION_QUESTIONS,
  EVALUATION_FINDINGS,
  GStyles,
} from '../../../consts';

const boxStyle: SxProps = { minHeight: 200, bgcolor: '#00ABD7' };

export const Step3ProjectEvaluationPlanForProjectPage = () => {
  const theme = useTheme();
  const { updateContextValue, downloadPDF } = useMainLayoutContext();
  const {
    project,
    projectActions: { updateProject },
  } = useProjectContext();

  const contentList = React.useMemo(
    () => [
      [
        {
          label: '',
          minHeight: 70,
          value: project?.contact_served_option,
          type: 'options',
          optionProps: {
            list: STACK_HOLDER_OPTIONS[0].options.map((value, index) => ({
              value: index,
              label: value,
            })),
            hasCustomOption: true,
            multiLineEdit: false,
            selected: ((project as any) || {})[
              STACK_HOLDER_OPTIONS[0].optionFieldName
            ],
          },
          submitFieldName: STACK_HOLDER_OPTIONS[0].optionFieldName,
        },
        {
          label: '',
          minHeight: 70,
          value: project?.contact_served_specific,
          type: 'input',
          submitFieldName: STACK_HOLDER_OPTIONS[0].inputFieldName,
        },
        {
          label: '',
          minHeight: 70,
          value: project?.contact_involved_option,
          type: 'options',
          optionProps: {
            list: STACK_HOLDER_OPTIONS[1].options.map((value, index) => ({
              value: index,
              label: value,
            })),
            hasCustomOption: true,
            multiLineEdit: false,
            selected: ((project as any) || {})[
              STACK_HOLDER_OPTIONS[1].optionFieldName
            ],
          },
          submitFieldName: STACK_HOLDER_OPTIONS[1].optionFieldName,
        },
        {
          label: '',
          minHeight: 70,
          value: project?.contact_involved_specific,
          type: 'input',
          submitFieldName: STACK_HOLDER_OPTIONS[1].inputFieldName,
        },
        {
          label: '',
          minHeight: 70,
          value: project?.contact_influence_option,
          type: 'options',
          optionProps: {
            list: STACK_HOLDER_OPTIONS[2].options.map((value, index) => ({
              value: index,
              label: value,
            })),
            hasCustomOption: true,
            multiLineEdit: false,
            selected: ((project as any) || {})[
              STACK_HOLDER_OPTIONS[2].optionFieldName
            ],
          },
          submitFieldName: STACK_HOLDER_OPTIONS[2].optionFieldName,
        },
        {
          label: '',
          minHeight: 70,
          value: project?.contact_influence_specific,
          type: 'input',
          submitFieldName: STACK_HOLDER_OPTIONS[2].inputFieldName,
        },
      ],
      [
        {
          label: '',
          value: project?.question_resources,
          type: 'options',
          optionProps: {
            list: EVALUATION_QUESTIONS[0].options,
            hasCustomOption: true,
            multiLineEdit: false,
            selected: ((project as any) || {})[
              EVALUATION_QUESTIONS[0].questionOptionField
            ],
          },
          submitFieldName: EVALUATION_QUESTIONS[0].questionOptionField,
        },
        {
          label: '',
          value: project?.question_activities,
          type: 'options',
          optionProps: {
            list: EVALUATION_QUESTIONS[1].options,
            hasCustomOption: true,
            multiLineEdit: false,
            selected: ((project as any) || {})[
              EVALUATION_QUESTIONS[1].questionOptionField
            ],
          },
          submitFieldName: EVALUATION_QUESTIONS[1].questionOptionField,
        },
        {
          label: '',
          value: project?.question_participants,
          type: 'options',
          optionProps: {
            list: EVALUATION_QUESTIONS[2].options,
            hasCustomOption: true,
            multiLineEdit: false,
            selected: ((project as any) || {})[
              EVALUATION_QUESTIONS[2].questionOptionField
            ],
          },
          submitFieldName: EVALUATION_QUESTIONS[2].questionOptionField,
        },
        {
          label: '',
          value: project?.question_short_term,
          type: 'options',
          optionProps: {
            list: EVALUATION_QUESTIONS[3].options,
            hasCustomOption: true,
            multiLineEdit: false,
            selected: ((project as any) || {})[
              EVALUATION_QUESTIONS[3].questionOptionField
            ],
          },
          submitFieldName: EVALUATION_QUESTIONS[3].questionOptionField,
        },
        {
          label: '',
          value: project?.question_intermediate,
          type: 'options',
          optionProps: {
            list: EVALUATION_QUESTIONS[4].options,
            hasCustomOption: true,
            multiLineEdit: false,
            selected: ((project as any) || {})[
              EVALUATION_QUESTIONS[4].questionOptionField
            ],
          },
          submitFieldName: EVALUATION_QUESTIONS[4].questionOptionField,
        },
        {
          label: '',
          value: project?.question_long_term,
          type: 'options',
          optionProps: {
            list: EVALUATION_QUESTIONS[5].options,
            hasCustomOption: true,
            multiLineEdit: false,
            selected: ((project as any) || {})[
              EVALUATION_QUESTIONS[5].questionOptionField
            ],
          },
          submitFieldName: EVALUATION_QUESTIONS[5].questionOptionField,
        },
      ],
      [
        {
          label: '',
          value: project?.indicator_resources,
          type: 'input',
          submitFieldName: 'indicator_resources',
        },
        {
          label: '',
          value: project?.indicator_activities,
          type: 'input',
          submitFieldName: 'indicator_activities',
        },
        {
          label: '',
          value: project?.indicator_participants,
          type: 'input',
          submitFieldName: 'indicator_participants',
        },
        {
          label: '',
          value: project?.indicator_short_term,
          type: 'input',
          submitFieldName: 'indicator_short_term',
        },
        {
          label: '',
          value: project?.indicator_intermediate_term,
          type: 'input',
          submitFieldName: 'indicator_intermediate_term',
        },
        {
          label: '',
          value: project?.indicator_long_term,
          type: 'input',
          submitFieldName: 'indicator_long_term',
        },
      ],
      [
        {
          label: '',
          value: project?.data_resources,
          type: 'input',
          submitFieldName: 'data_resources',
        },
        {
          label: '',
          value: project?.data_activities,
          type: 'input',
          submitFieldName: 'data_activities',
        },
        {
          label: '',
          value: project?.data_participants,
          type: 'input',
          submitFieldName: 'data_participants',
        },
        {
          label: '',
          value: project?.data_short_term,
          type: 'input',
          submitFieldName: 'data_short_term',
        },
        {
          label: '',
          value: project?.data_intermediate_term,
          type: 'input',
          submitFieldName: 'data_intermediate_term',
        },
        {
          label: '',
          value: project?.data_long_term,
          type: 'input',
          submitFieldName: 'data_long_term',
        },
      ],
      [
        {
          label: '',
          value: project?.timing_resources,
          type: 'input',
          submitFieldName: 'timing_resources',
        },
        {
          label: '',
          value: project?.timing_activities,
          type: 'input',
          submitFieldName: 'timing_activities',
        },
        {
          label: '',
          value: project?.timing_participants,
          type: 'input',
          submitFieldName: 'timing_participants',
        },
        {
          label: '',
          value: project?.timing_short_term,
          type: 'input',
          submitFieldName: 'timing_short_term',
        },
        {
          label: '',
          value: project?.timing_intermediate_term,
          type: 'input',
          submitFieldName: 'timing_intermediate_term',
        },
        {
          label: '',
          value: project?.timing_long_term,
          type: 'input',
          submitFieldName: 'timing_long_term',
        },
      ],
      [
        ...(project?.evaluation_findings?.map(value => ({
          label: '',
          value,
          type: 'multi-options',
          multiOptionProps: {
            list: EVALUATION_FINDINGS,
            selectedOptions: project?.evaluation_findings || [],
          },
          submitFieldName: 'evaluation_findings',
        })) || []),
      ],
    ],
    [project]
  );

  React.useEffect(() => {
    updateContextValue({
      title: `${project?.name} The Completed Evaluation Plan`,
      pageContainerSx: { maxWidth: theme.breakpoints.values.md },
    });

    return () => {
      updateContextValue({ title: undefined, pageContainerSx: undefined });
    };
    // eslint-disable-next-line
  }, [project?.name]);

  return (
    <Grid container alignItems="flex-start" sx={{ height: '100%' }}>
      <Grid item container sx={{ mt: 4 }}>
        {EVALUATION_PLAN_HEADERS.map((item, index) => (
          <Grid item xs={2} alignSelf="end" key={index}>
            <Typography
              textAlign="center"
              fontWeight={500}
              fontSize={{ xs: 10, sm: 14, md: 16 }}
            >
              {item}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid container columnSpacing={1} sx={{ position: 'relative' }}>
        <Box
          sx={{
            display: { xs: 'none', sm: 'block' },
            position: 'absolute',
            inset: 0,
            top: 90,
            left: 40,
            width: '98%',
            height: 20,
            bgcolor: '#00ABD7',
            zIndex: 0,
          }}
        />
        <Box
          sx={{
            display: { xs: 'none', sm: 'block' },
            position: 'absolute',
            top: 80,
            left: '102%',
            borderTop: '20px solid transparent',
            borderBottom: '20px solid transparent',
            borderLeft: '20px solid #00ABD7',
            zIndex: 0,
          }}
        />
        {contentList.map((data, index) => (
          <Grid item xs={2} key={index} sx={{ zIndex: 1 }}>
            <Box
              sx={{
                ...boxStyle,
                p: 0.5,
                borderStyle: 'solid',
                borderColor: 'white',
              }}
            >
              {data.map((item, iIndex) => (
                <ContentInput
                  key={iIndex}
                  type={(item?.type as any) || 'input'}
                  optionProps={(item as any).optionProps}
                  multiOptionProps={(item as any).multiOptionProps}
                  label={item?.label}
                  placeholder=" "
                  content={item?.value}
                  editable
                  handleSubmit={value =>
                    updateProject({
                      id: project?.id,
                      [(item as any).submitFieldName]:
                        value?.split('\n')?.length > 1
                          ? value?.split('\n')
                          : value,
                    })
                  }
                  styles={{
                    label: GStyles.tableContentText,
                    contentViewer: {
                      container: {
                        minHeight: (item as any)?.minHeight || 140,
                        bgcolor: '#99CAEC',
                        px: 1,
                        py: 1,
                        mb: 1,
                      },
                      value: { fontSize: 12, fontWeight: 500 },
                    },
                  }}
                />
              ))}
            </Box>
          </Grid>
        ))}
      </Grid>
      <Grid container>
        <Grid item xs={12} textAlign="right">
          <PDFButton className="pdf-hide" onClick={downloadPDF} />
        </Grid>
      </Grid>
    </Grid>
  );
};
