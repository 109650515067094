import React, { useEffect } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import Image from 'mui-image';

import introYourEvaluationRoadmapRightPng from '../../../assets/images/pngs/intro_your_evaluation_roadmap_right.png';
import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';

export const Step2YourEvaluationRoadMapPage = () => {
  const theme = useTheme();
  const { updateContextValue } = useMainLayoutContext();

  useEffect(() => {
    updateContextValue({
      title: 'The Evaluation Roadmap Step 2',
      pageContainerSx: { maxWidth: theme.breakpoints.values.md },
    });

    return () => {
      updateContextValue({ title: undefined, pageContainerSx: undefined });
    };
    // eslint-disable-next-line
  }, []);

  const options = React.useMemo(
    () => [
      'Project Flow Diagram = Logic Model',
      'Describes resources, activities, target population(s) and outcomes',
      'Clear visual map of how your project will work',
      'Keeps everyone moving in the right direction',
      'Work with your TA provider',
    ],
    []
  );

  return (
    <Box sx={{ py: 6 }}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} sm={6}>
          <Grid item xs={12} container spacing={2} sx={{ mb: { sm: '50%' } }}>
            {options.map((val, index) => (
              <Grid key={`option-${index}`} item xs={12}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Typography>●</Typography>
                  </Grid>
                  <Grid item sx={{ flex: 1 }}>
                    <Typography variant="subtitle2" fontWeight={500}>
                      {val}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Image
            src={introYourEvaluationRoadmapRightPng}
            style={{ objectFit: 'contain', maxWidth: 420 }}
            duration={500}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
