import React from 'react';
import { Box, SxProps, Theme, useTheme } from '@mui/material';

type CircleGreyProps = {
  sx?: SxProps<Theme>;
};

export const CircleGrey = ({ sx = {} }: CircleGreyProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: 16,
        height: 16,
        bgcolor: theme.palette.divider,
        borderRadius: '100%',
        ...sx,
      }}
    />
  );
};
