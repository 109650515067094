import { Container, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';
import { useProjectContext } from '../../../contexts';
import { ContentOptions } from '../../../components';
import { EVALUATION_QUESTIONS } from '../../../consts';

const tableBorder: React.CSSProperties = {
  borderCollapse: 'collapse',
  borderSpacing: 0,
  borderColor: 'gray',
  borderWidth: 1,
  borderStyle: 'solid',
  padding: 10,
};

export const Step3ProcessEvaluationQuestions = () => {
  const theme = useTheme();
  const { updateContextValue } = useMainLayoutContext();
  const {
    project,
    projectActions: { updateProject },
  } = useProjectContext();

  React.useEffect(() => {
    updateContextValue({
      pageContainerSx: { maxWidth: theme.breakpoints.values.md, width: '100%' },
    });

    return () => {
      updateContextValue({ title: undefined, pageContainerSx: undefined });
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Container disableGutters>
      <table style={{ ...tableBorder, width: '100%' }}>
        <thead>
          <tr>
            <th style={{ ...tableBorder, width: '30%', textAlign: 'left' }}>
              Type of Questions
            </th>
            <th style={{ ...tableBorder, textAlign: 'left' }}>Choose One</th>
          </tr>
        </thead>
        <tbody>
          {EVALUATION_QUESTIONS.map((row, index) => (
            <tr key={index}>
              <td style={{ ...tableBorder, verticalAlign: 'top' }}>
                <Typography
                  component="span"
                  color={row.color}
                  sx={{ fontWeight: 500, fontSize: 16 }}
                >
                  {row.highlight}
                </Typography>
                <Typography
                  component="span"
                  sx={{ fontWeight: 500, fontSize: 16 }}
                >
                  {' '}
                  Questions:
                </Typography>
                <br />
                <Typography
                  component="span"
                  sx={{ fontWeight: 500, fontSize: 16 }}
                >
                  {row.questionType}
                </Typography>
              </td>
              <td style={{ ...tableBorder }}>
                <ContentOptions
                  list={row.options}
                  hasCustomOption
                  selected={((project as any) || {})[row.questionOptionField]}
                  onChange={value =>
                    updateProject({
                      id: project?.id,
                      [row.questionOptionField]: value,
                    })
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
};
