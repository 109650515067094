import { Box, Grid, Typography, useTheme } from '@mui/material';
import Image from 'mui-image';
import React from 'react';

import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';
import likeImage from '../../../assets/images/pngs/green_like.png';

export const Step3DecidingOnIndicators1Page = () => {
  const theme = useTheme();
  const { updateContextValue } = useMainLayoutContext();

  React.useEffect(() => {
    updateContextValue({
      title: 'Two Rules of Thumb with Indicators',
      pageContainerSx: { maxWidth: theme.breakpoints.values.md },
    });

    return () => {
      updateContextValue({ title: undefined, pageContainerSx: undefined });
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={5.5}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            px: 8,
          }}
        >
          <Image
            src={likeImage}
            style={{ maxWidth: 165, objectFit: 'contain' }}
          />
          <Typography sx={{ mt: 6, fontWeight: 500 }}>
            Don’t over-promise and create a long list of indicators. For every
            indicator you list, you are committing your team to creating a plan
            to collect that information. Be realistic.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={5.5} mt={{ xs: 4, sm: 0 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            px: 8,
          }}
        >
          <Image
            src={likeImage}
            style={{ maxWidth: 165, objectFit: 'contain' }}
          />
          <Typography sx={{ mt: 6, fontWeight: 500 }}>
            Your indicator has to be measureable. Don’t use vague indicators
            like “participants are happier.” This will make the data capture and
            analysis very challenging or potentially impossible
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
