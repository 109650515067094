const sessionAuthKey = 'evaluation-auth-key';
const sessionAuthRefreshKey = 'evaluation-refresh-auth-key';

export const setAuthToken = (token: string): void => {
  localStorage.setItem(sessionAuthKey, token);
};

export const getAuthToken = (): string => {
  return localStorage.getItem(sessionAuthKey) || '';
};

export const removeAuthToken = (): void => {
  localStorage.removeItem(sessionAuthKey);
};

export const setRefreshToken = (token: string): void => {
  localStorage.setItem(sessionAuthRefreshKey, token);
};

export const getRefreshToken = (): string => {
  return localStorage.getItem(sessionAuthRefreshKey) || '';
};

export const removeRefreshToken = (): void => {
  localStorage.removeItem(sessionAuthRefreshKey);
};
