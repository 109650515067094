import { ROUTES } from './route.const';

// const purpleColor = '#9900ff';
// const purpleSpan = `<span style="color: ${purpleColor};">`;
// const closeSpan = `</span>`;

export const scripts = {
  [ROUTES.MODULE_OBJECTIVES]:
    'Welcome to the Project Planning & Evaluation training module. In this “product oriented” module, you and your team will create three printable diagrams that represent an evaluation roadmap. Along the way, we will provide important information about each page and what you will be asked to do. We encourage you and your team to listen closely on your first time through. To begin, click the down arrow in each color bar to reveal the objectives for this module.',
  [ROUTES.GETTING_YOUR_BEARINGS]: `In order to successfully navigate this module, you should have a few important items on hand:<br /><br />
    ● Your team members and information learned from those <strong>who have been</strong> impacted by the problem<br />
    ● Any documents that detail goals, objectives and activities; for example your statement or scope of work or implementation plan<br />
    ● Your completed grant application and any evaluation guidance provided with the application<br />
    ● State, regional, and local data that are relevant to the problem your project will address<br />`,
  [ROUTES.THE_EVALUATION_ROADMAP]: `We’re using a map analogy to help you develop your evaluation strategy. The map represents the entire landscape of your Scope of work or Implementation Plan.<br />
    On that landscape, your organization may be working on several projects. Together, these projects make up your entire program. You will create an evaluation plan for <strong>one project at a time</strong>. You can come back and use the module to create evaluation plans for as many projects as you like.`,
  [ROUTES.DIAGRAMS_YOU_WILL_CREATE_1]: `Let’s take a closer look at the three diagrams you will create.<br /><br />
    It is important that your team utilizes a collaborative, inclusive planning process that engages representative stakeholders such as community members and partner organizations. Together, review your Scope of Work or Implementation Plan and select the project you want to focus on for this module. Remember that you are looking at just <strong>one project</strong> at a time.<br /><br />
    In Step 1 you will create a project theory diagram, which clearly <strong>defines</strong> the problem or concern your project addresses, <strong>describes</strong> the data and rationale for the strategies you will employ, and <strong>specifies</strong> your desired results.<br /><br />
    In Step 2 you will develop a project flow diagram, also known as a logic model, which details how <strong>your project is intended to work</strong> and creates a graphic of the relationship between the project’s resources, activities, and intended effects.<br /><br />
    In Step 3 you will create a project evaluation plan that incorporates elements of Steps 1 and 2, along with new information about your evaluation focus, audience, questions, indicators, data, and how results will be used.<br /><br />
    If you aren’t sure where to focus your efforts, contact a Technical Assistance, or TA provider by clicking “TA Contact” in the top navigation bar. Someone from that team will reply to your email within 3 business days.<br /><br />`,
  [ROUTES.DIAGRAMS_YOU_WILL_CREATE_2]:
    'Click on <strong>any</strong> thumbnail image to see a larger version <strong>of the diagram</strong> and the information it will include.',
  [ROUTES.PROJECT_PROFILE]: `If you are <strong><u>starting</u></strong> a new project, please create a project name.<br /><br />
    If you are <strong><u>returning to work on a project</u></strong> you have already started, double check that you are re-visting the correct project. As you navigate the module, please contact your TA provider or CDPHE contract monitor if you have questions. Click on the “TA CONTACT” in the top navigation bar for contact information.`,
  [ROUTES.PROJECT_THEORY_DIAGRAM_1]: `The main goal of completing a Project Theory Diagram is to map out what your project is <strong><i>doing</i></strong> and <strong><i>why</i></strong>.<br /><br />
    The journey begins with a core problem. Identify the community need that <strong>motivated</strong> your team and collaborators to choose <strong>this problem</strong> as your focus. Were you motivated by state, regional, or local data? Were you motivated by prevalence rates, regional health disparities or perhaps by key informant interview data? As you plan how you will address the problem, you must consider influential factors in your community that will make the journey easier —such as <strong>strong community partnerships or support of key decision makers</strong>. Likewise be aware of influential <strong>factors</strong> that could make it more difficult, <strong>such as a rocky political climate, competing interests or structural inequalities</strong>.<br /><br />
    <strong>Other organizations or coalitions may</strong> have already been down this road and addressed a very similar issue to yours. It is always helpful to find out how their journey ended up. What lessons can you learn from others?<br /><br />
    And there are some <strong>proven</strong> routes that have been traveled by others resulting in a <strong>recommended</strong> path for ending up at the right place. These routes are the “evidence-based or evidence-informed” strategies for success. Together, the influential factors and the strategies create a well-paved road on which to travel.<br /><br />
    Finally, consider your final destination, or project <strong>outcome</strong>. What endpoints do you plan to reach in the short, intermediate and long term? These endpoints describe your desired results.<br /><br />`,
  [ROUTES.PROJECT_THEORY_DIAGRAM_2]: `Over the next several screens you will provide information that will build your Project Theory Diagram. We recommend that you do not complete these diagrams on your own. The products you create will be most relevant, translatable and sustainable if you engage other staff, coalition members and a diversity of community voices in the process. Authentic collaboration will lead to richer data, increased capacity of everyone who is involved in this project, and stronger relationships with community members.<br /><br />
    As you wind your way through this section, remember that “less is more.” In the interest of limited word space within the diagram <strong>and</strong> a need to focus, please follow the word limits for each section. We have provided examples to illustrate what each section could look like.<br /><br />
    Once you have completed the five parts of the Project Theory diagram, you will be able to review and edit your responses. Move your cursor over the components of the diagram to better understand the information your team will need to gather and provide.`,
  [ROUTES.CREATE_PROJECT_THEORY_DIAGRAM_1]: `In no more than three sentences, explain the problem or concern your project addresses. Use these guiding prompts:<br /><br />
    ● What is wrong<br />
    ● Why it matters (and)<br />
    ● What you plan to do about it<br /></br />
    Click on the pencil icon to fill in the content. Roll over the Examples Marker to see sample text. For the examples, we provide a policy-focused project and a systems change project.`,
  [ROUTES.CREATE_PROJECT_THEORY_DIAGRAM_2]: `In four sentences or less, make your case stronger and more specific by referencing the <strong>community need</strong> that influences or impacts the problem. Then, list the <strong>data sources</strong> you plan to use, such as a community needs assessment, or state, regional and/or local data.<br /><br />
    Click on the pencil icon to fill in the content for these two pieces of information.`,
  [ROUTES.CREATE_PROJECT_THEORY_DIAGRAM_3]: `In no more than five sentences, describe <strong>influential factors</strong> that could impact the implementation and desired results of your project. Think about what could really support your work. On the other hand, what are potential barriers to reaching your desired results? Think about what might get in your way: this will help you anticipate potential problems.<br /><br />
    Click on the pencil icon to fill in the content.`,
  [ROUTES.CREATE_PROJECT_THEORY_DIAGRAM_4]: `Briefly describe the strategy or strategies that your work will use to achieve the desired results, and provide the supporting evidence or guidelines for each strategy. Your strategies may be evidence-based, evidence -informed or even innovative. For a list of strategies and resources categorized by health topic, click on the ‘SOURCE’ button found on the bottom menu bar.<br /><br />
    When you are ready to add your strategies, click on the pencil icon to fill in the content.`,
  [ROUTES.CREATE_PROJECT_THEORY_DIAGRAM_5]: `What do you expect your project to achieve at short, intermediate and long-term time intervals? Reference the project objectives in the Scope of Work or Implementation Plan associated with this project. The time frames presented in this module are rough estimates and your timeline could look very different depending on the project you choose to evaluate.<br /><br />
    Click on the pencil icon in short, intermediate and long-term to fill in the content. The long-term outcome may already be determined by your funder. If not, create your own.`,
  [ROUTES.PROJECT_THEORY_DIAGRAM_FOR_PROJECT]:
    'Here is your Project Theory Diagram. Click on the pencil icons if there is something you would like to edit. Click to download a PDF of this diagram.',
  [ROUTES.YOUR_EVALUATION_ROADMAP2]: `Now that you’ve completed Step 1, you’re ready to move on to Step 2 where you’ll create a Project Flow Diagram. This diagram is also called a logic model.<br /><br />
    The Project Flow Diagram will give you, your team, and your stakeholders a visual map of how your project will work. It details resources required to implement your planned activities and how those activities should lead to your desired outcomes.<br /><br />
    It is <strong>very important</strong> that you engage your team and community partners to complete this step. If you need further assistance, contact your evaluation TA.`,
  [ROUTES.BENEFIT_OF_PORJECT_FLOW_DIAGRAM]: `The Project Flow Diagram can be used to improve your project’s performance because it details a planned path to outcomes. It serves as a framework for planning, implementing, and evaluating.<br /><br />
    Click through the photo gallery to see some ways that a Project Flow Diagram can benefit your project.`,
  [ROUTES.CREATE_PROJECT_FLOW_DIAGRAM_1]: `In this step, you’ll add additional information about your project’s Inputs and Outputs.<br /><br />
    ● Inputs are resources that you will invest in the project or resources available to you such as evidence-based trainings or community partnerships,<br />
    ● Outputs describe specific activities, or “what” you will do, such as coalition-building, training or providing education,<br />
    ● Outputs also describe the target populations, participants or those “who” you will reach. These may include community members, healthcare providers in a local clinic or city council members.<br /><br />
      And you’ve already completed other parts of the Project Flow Diagram—In Step 1 you described intervention strategies, influential factors important to project success, and your outcomes. That information will also populate into your Project Flow Diagram.`,
  [ROUTES.CREATE_PROJECT_FLOW_DIAGRAM_2]: `Once you have completed Step 2, you will generate a diagram that looks something like this—but with content added into the various boxes.<br /><br />
    Often, a logic model is started with a long list of resources that, in the end, don’t have a connection to the project activities and outcomes. Therefore, in this module, you will create the Project Flow Diagram starting with the END in mind - working from “right to left.” You will begin with your expected outcomes or results and end with the list of resources that will help get you there.`,
  [ROUTES.CREATE_PROJECT_FLOW_DIAGRAM_3]: `Reference your Step 1 Project Theory Diagram to help you think about who you want to impact with your project. The participants are those individuals who you will reach and/or engage with your activities.<br /><br />
    Given your short, intermediate, and long-term outcomes, who do you plan to or need to reach in order to achieve those outcomes? And where will you reach them? Provide as much specificity as you can to better reflect the identified need. For example, you may plan to reach under-resourced patients in a specific geographic region, youth leaders at a high school, medical professionals in rural community clinics, or perhaps parents in a community struggling with affordable housing. Most likely you will list more than one participant or type of participant.<br /><br />
    Click on the pencil icon to list your Participants now.`,
  [ROUTES.CREATE_PROJECT_FLOW_DIAGRAM_4]: `Considering your project’s outcomes, what are the specific activities listed in your Scope of Work or Implementation Plan that are directly connected to these outcomes?<br /><br />
    Make sure you focus and choose the specific activities you plan to do in order to achieve your desired outcomes, not everything listed in your Scope or Implementation Plan. As you describe the activities, make sure to include the location or setting where the activity will occur.<br /><br />
    Click on the pencil to complete the Activities area of the diagram now.`,
  [ROUTES.CREATE_PROJECT_FLOW_DIAGRAM_5]: `The last question you need to answer is “what resources are available to your project that support the specific activities you have planned?” These may include staff, money, volunteers, <strong>strong partnerships, expertise,</strong> or in-kind contributions from partners.<br /><br />
    Remember to connect your resources specifically to the outcomes, participants, and activities you have just described.<br /><br />
    Click on the pencil to complete the Resources area of the diagram now.`,
  [ROUTES.PROJECT_FLOW_DIAGRAM_FOR_PROJECT]:
    'Here is your completed Project Flow Diagram. Click on the pencil icons if there is something you would like to edit. You can print a PDF of the finished diagram.',
  [ROUTES.YOUR_EVALUATION_ROADMAP3]: `Now we’re moving to Step 3 where you’ll create a Project Evaluation Plan.<br /><br />
    The Project Evaluation Plan will help you to:<br /><br />
    ● Add evaluation focus<br />
    ● Define audiences<br />
    ● Draft evaluation questions<br />
    ● Select indicators of change<br />
    ● Determine what data need to be collected and when, and finally,<br />
    ● Describe how evaluation results will be used<br /><br />
    Remember that you don’t have to struggle through this alone. A TA provider can assist you if you need additional guidance.`,
  [ROUTES.CREATE_PROJECT_EVALUATION_PLAN]: `The strongest project evaluations are those that are developed at the <strong>beginning</strong> of a project.<br />
    Evaluation plans are living documents that are continually updated as you make progress and decisions about where you will focus.<br /><br />
    A project evaluation plan captures all the relevant information across a project’s lifespan, from its focus, to how the information will be used and disseminated. As you see here, there are a few key aspects of the evaluation plan. It is important to identify your audience and understand what is important to them. The evaluation questions should align with the project’s goals and outcomes and frame the entire evaluation by informing the indicators of change, the data you will need to collect and how you will analyze the data.`,
  [ROUTES.KNOW_YOUR_EVALUATION_FOCUS]: `All evaluation plans have a focus. Some evaluations focus on <strong>the process</strong> while most focus on <strong>outcomes</strong>.<br /><br />
    A <strong>process evaluation</strong> is done while the work is on-going to provide real-time feedback on how well strategies are being implemented and if the expected short-term results are being achieved.<br />
    <strong>Process evaluations</strong> help you identify any needed adjustments or improvements by looking closely at resources, types of activities or services delivered, and beneficiaries of those activities—the participants. A process evaluation documents the implementation and management of your project and answers the questions such as, “What kinds of problems were encountered in delivering the project?” or “Was the project well managed?” or “Are we reaching our intended audience”?<br /><br />
    <strong>Outcome evaluation</strong> looks at project results and effectiveness. It helps you understand whether your intended change actually happened. In order to measure outcomes, you must first get a <strong>baseline measure—</strong>that is, a measurement of the state of the problem or issue <strong>before</strong> you implement your project.<br />
    Outcome evaluations answer like, “Did the project succeed in integrating a new cessation service?” “Was the program more successful with certain groups of people than with others?” or “Did the activities succeed in changing the participants' behaviors in a meaningful and sustainable way?”<br /><br />
    <u>This module</u> will help you document both the <i>process</i> of your project’s implementation and assess the effectiveness of your project in producing change, the <i>outcome</i>.`,
  [ROUTES.IDENITIFY_STAKEHOLDERS_1]: `Your next step is to identify your stakeholders or audience for your evaluation findings. Understanding and taking into account the priorities and concerns of a stakeholder informs your planning, communication strategies, and supports how you will use your evaluation findings.<br /><br />
    There are three main categories of stakeholders:<br />
    ● Those served, or directly affected, by the project<br />
    ● Those involved in the project’s operations and<br />
    ● Those you want to motivate or influence with your project findings.<br /><br />
    Click on the images to see a more detailed description of these three stakeholder groups.`,
  [ROUTES.IDENITIFY_STAKEHOLDERS_2]: `Let’s identify your stakeholders by responding to these three questions.<br /><br />
    For each stakeholder category, select <strong>one</strong> group or individual you will work with and describe them in a bit more detail in the box to the right. Stakeholders can provide perspective on what will make an evaluation useful, help frame evaluation questions, and assist with data collection, utilization and reporting.`,
  [ROUTES.CHOOSE_EVALUATION_QUESTIONS]: `You understand your evaluation focus, and you’ve identified important stakeholders. Now you need to think about the <strong>evaluation questions</strong> you will ask in order to gather the information you need.<br /><br />
    Why do you need evaluation questions? Evaluation questions define and confirm what your project is trying to achieve or change. Evaluation questions that are linked to your project goals will provide clarity of project purpose and guidance for the data required to answer the questions. Evaluation questions should focus on critical aspects of the project flow diagram, or logic model, that you created in Step 2, for instance, “Are the activities bringing about change in people’s attitudes?” or “Is the link between community organizations and clinics associated with increased screenings?”`,
  [ROUTES.ALIGN_EVALUATION_QUESTIONS]: `Let’s take a look at how evaluation questions align with the project flow diagram you created in Step 2. The blue area is what you will now fill in with the evaluation questions.<br /><br />
    We <strong>do not</strong> recommend that you evaluate every part of your project. We <strong>do recommend</strong> that you always talk with stakeholders. It is important to consider their values and perspectives in order to make sure that the questions you choose are accurately evaluating the part of your project that is of most interest to them.`,
  [ROUTES.PROCESS_EVALUATION_QUESTIONS]: `Here is a table of possible evaluation questions that correspond to the components of your project flow diagram. Now your team will choose an evaluation question for at least one process-focused category AND at least <u>one</u> outcome-focused category. If your team decides that you do not want to have a question for a particular section, please click “we will not evaluate as part of this project.” Within each category, you can select a prewritten question, write your own question, or note that you are not going to evaluate this category. These questions will populate your Project Flow Diagram.<br /><br />
    Don’t hesitate to reach out to technical assistance if you want to talk through your evaluation questions.`,
  [ROUTES.EVALUATION_PLAN_IN_PROGRESS_1]:
    'Here is a look at your selected evaluation questions and how they align with your Project Flow Diagram.',
  [ROUTES.EVALUATION_PLAN_IN_PROGRESS_2]:
    'Here you can see the progression of your Project Evaluation Plan in progress. Now let’s fill in the rest of the information for “Indicators of Change,” “Data Sources Needed,” “Data Collection Timing,” and “Utilization.”',
  [ROUTES.DECIDING_ON_INDICATORS_OF_CHANGE]: `“Indicators of Change” are an important link between your evaluation questions and the data needed to answer the questions. Think about what you can <strong><i>measure</i></strong> to indicate change or progress in your project. An indicator identifies exactly what data you need to gather in order to fully answer an evaluation question.<br /><br />
    Let’s consider an example of an indicator for the process evaluation question, “Do we have the right mix of activities?” An indicator that would allow us to measure this question could be, “level of participant satisfaction with available activities.”`,
  [ROUTES.DECIDING_ON_INDICATORS_1]: `There are two rules of thumb when deciding on indicators:<br /><br />
    1. First, don’t over-promise and create a long list of indicators. For every indicator you list, you are committing your team to creating a plan to collect that information. Be realistic.<br /><br />
    2. Second, your indicator has to be <strong><i>measurable</i></strong>. Don’t use vague indicators like “participants are happier.” This will make the data capture and analysis challenging or potentially impossible.`,
  [ROUTES.DECIDING_ON_INDICATORS_2]:
    'Now it is your turn to write a realistic, measurable indicator of change for each of your evaluation questions. For a table of sample indicators, click on the “sources” button in the bottom menu bar. Determining indicators can be challenging. For additional help, contact technical assistance.',
  [ROUTES.DATA_AND_DATA_COLLECTION_TIMING]: `Now you will identify the data needed to answer the evaluation questions, based on the indicators you specified.<br /><br />
    Answer the following questions:<br /><br />
    ● What data do we need to answer the evaluation questions?<br />
    ● What data are we already collecting? What data are missing?<br />
    ● If new data are required, what new sources of data do we need?<br />
    ● <strong>When</strong> will we measure or collect the data?<br />
    ● How will we store the data and<br />
    ● How will we analyze the data to answer our evaluation questions?<br /><br />
    Contact technical assistance for support in completing this part of the evaluation plan.`,
  [ROUTES.OUTPUT_OR_PROCESS_DATA_EXAMPLE]: `Let’s look at some examples to help clarify data collection a bit more. In this output, or process data example, the project team asks the question “do we have the right mix of activities?” Their indicator is “the level of participant satisfaction with available activities.”<br /><br />
    Based on that indicator of “participant satisfaction,” they have proposed three data sources to answer the question:<br /><br />
    ● Conduct satisfaction surveys with project participants<br />
    ● Complete interviews with key participants and<br />
    ● Review staff logs to look for more information or notes on participant satisfaction.<br /><br />
    The team also made decisions about the timing for collecting the data, often collecting and analyzing data at baseline and after improvements have been made.<br /><br />
    It can be challenging to figure out how to measure your indicator and analyze your data. Check in with your teammates and technical assistance for help.`,
  [ROUTES.OUTCOME_DATA_EXAMPLE]: `In this outcome data example, the team asks the evaluation question: “What is the change in the rate of tobacco retail compliance checks in our community?”<br /><br />
    Their indicator is “change in the number of compliance checks conducted by the department.”<br /><br />
    Their data includes interviews with key participants. a review of staff logs and the Tobacco Retailer and Compliance Check Map on the CDPHE web-site.<br /><br />
    They will collect baseline data before implementation, and post data six months after the project is finished.<br /><br />
    Again, this can be challenging. Technical assistance can help.`,
  [ROUTES.SELECT_YOUR_DATA_MEASURES_AND_TIMING]: `It’s time for you to describe <strong><i>how</i></strong> you will collect your data and <strong><i>the timing</i></strong> for that data collection. Scroll down to fill in the blanks under each indicator with <strong><u>how</u></strong> you will capture your data. For example, data may come from a survey conducted with clinic administrators, key informant interviews with local decision makers or enrollment and completion data collected by a community program. It is useful to think about how to store the data you will collect so that you can analyze it. You might store data in a database or excel spreadsheet.<br /><br />
    Next, fill in the timing for when you will capture the data. For example, timing may be at baseline and again 3-months post intervention.<br /><br />
    Be as specific as possible as you complete these two areas.`,
  [ROUTES.EVALUATION_PLAN_IN_PROGRESS_4]: `You can see that your Project Evaluation Plan is really moving along.<br /><br />
    <strong>Now</strong> you and your team are ready to complete the last piece of the plan that describes how you will use your evaluation findings.<br /><br />
    It’s important to not only identify <strong>how</strong> your findings can be used, but also to create an <strong>action plan</strong> for implementing what you have learned. Talk with your team to decide how you will use evaluation findings to support internal learning and how you will <strong>share</strong> the findings of your evaluation to improve the knowledge base. <strong>Consider your audience for the evaluation and</strong> engage your teammates; it’s always a good idea to get outside perspectives.`,
  [ROUTES.USING_EVALUATION_FINDINGS]:
    'Here is a list of the most common uses for evaluation findings. Select all the uses relevant to your project.. As you think about how to use your findings, revisit the <strong><i>purpose</i></strong> of your evaluation. Make sure your selections align with your evaluation focus, questions, and stakeholder perspectives.',
  [ROUTES.PROJECT_EVALUATION_PLAN_FOR_PROJECT]: `Now that <strong>you have developed</strong> all the pieces of the Project Evaluation Plan, review <strong>your final product</strong>. You may identify pieces that are not aligned or other parts you may want to tweak. Click on the pencil to edit or go back to that section in the module and add revisions.<br />
    <strong><u>Once complete, you can download a PDF of the final plan.</u></strong>`,
  [ROUTES.GOOD_WORK]: `Congratulations! You’ve done some great work by completing this training module. Now you need to move beyond the module and take your work to into the real world.<br /><br />
    We recommend that you:<br /><br />
    1. Engage teammates and community members Meet with your team and community partners to share the outputs, timelines, and brainstorm next steps. Consider how you will reflect on the evaluation plan with the community and make it meaningful to them. If something doesn’t quite make sense to your team or your community partners, you may need to make some adjustments and;<br />
    2. Once you are satisfied with your final outputs, work with your team to identify clear roles and responsibilities around the evaluation process, next steps, and reporting.<br /><br />
    We hope that this module made your journey into the world of project planning and evaluation a little easier to navigate. Use the module to complete an evaluation plan for other projects in your program, or to review and refine plans for projects across their lifespans. Safe travels!<br /><br />
    Results and evaluation products have relevance and utility for not just for your funders, but for diverse communities. The products, while jointly disseminated by funders, the evaluator, and the stakeholders, should also be presented to the community of focus in accessible and nontraditional formats.`,
  [ROUTES.THANK_YOU]: '',
  [ROUTES.EVALUATION_FORM]: '',
};

export const getScriptContent = (pathname: string) => {
  const formattedPath = pathname?.endsWith('/')
    ? pathname.slice(0, -1)
    : pathname;
  const lastPath = formattedPath?.split('/')?.at(-1);
  if (lastPath) {
    return scripts[lastPath] || '';
  } else {
    return '';
  }
};
