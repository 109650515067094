import React from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';

import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';
import dataImage from '../../../assets/images/pngs/data_collection_timing.png';
import Image from 'mui-image';

const list = [
  'What data do we need to answer our evaluation questions?',
  'What data are we already collecting?',
  'What new sources of data do we need?',
  'When will we collect these data?',
  'Where and how will data be stored?',
  'How will we analyze data?',
];

export const Step3DataAndDataCollectionTimingPage = () => {
  const theme = useTheme();
  const { updateContextValue } = useMainLayoutContext();

  React.useEffect(() => {
    updateContextValue({
      title: 'Questions to Answer When Thinking About Data',
      pageContainerSx: { maxWidth: theme.breakpoints.values.md },
    });

    return () => {
      updateContextValue({ title: undefined, pageContainerSx: undefined });
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Grid container spacing={5}>
      <Grid item xs={12} sm={5}>
        <Image src={dataImage} style={{ maxWidth: 338 }} />
      </Grid>
      <Grid xs={1} />
      <Grid item xs={12} sm={5}>
        {list.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              mb: 3,
            }}
          >
            <Box
              sx={{
                width: 14,
                height: 14,
                borderRadius: 14,
                bgcolor: 'lightgrey',
                flexShrink: 0,
                mr: 2,
              }}
            />
            <Typography sx={{ fontWeight: 500 }}>{item}</Typography>
          </Box>
        ))}
      </Grid>
    </Grid>
  );
};
