import React from 'react';
import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogActionsProps,
  DialogContent,
  DialogContentProps,
  DialogProps,
  DialogTitle,
  DialogTitleProps,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import { Close } from '@mui/icons-material';

interface Props extends DialogProps {
  title?: string;
  description?: string;
  confirmTitle?: string;
  cancelTitle?: string;
  hideCancelButton?: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  titlelProps?: DialogTitleProps;
  contentProps?: DialogContentProps;
  actionProps?: DialogActionsProps;
  confirmButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
}

export const ConfirmDialog = ({
  title = 'Confirm',
  description = 'Are you sure?',
  confirmTitle = 'Confirm',
  cancelTitle = 'Cancel',
  hideCancelButton = false,
  titlelProps,
  contentProps,
  actionProps,
  confirmButtonProps,
  cancelButtonProps,
  onClose,
  onConfirm = () => {},
  ...props
}: Props) => {
  const theme = useTheme();
  return (
    <Dialog fullWidth maxWidth={'xs'} onClose={onClose} {...props}>
      <DialogTitle
        component="div"
        {...titlelProps}
        sx={{
          bgcolor: theme.palette.primary.main,
          display: 'flex',
          alignItems: 'center',
          py: 1,
          ...titlelProps?.sx,
        }}
      >
        <Typography sx={{ color: 'white', fontSize: 14, fontWeight: 600 }}>
          {title}
        </Typography>

        <IconButton
          size="small"
          sx={{ ml: 'auto' }}
          edge="end"
          onClick={onClose}
        >
          <Close sx={{ fontSize: 'inherit', color: 'white' }} />
        </IconButton>
      </DialogTitle>

      <DialogContent {...contentProps} sx={{ mt: 2.5, ...contentProps?.sx }}>
        <Typography>{description}</Typography>
      </DialogContent>

      <DialogActions {...actionProps} disableSpacing>
        {!hideCancelButton && <Button onClick={onClose}>{cancelTitle}</Button>}
        <Button onClick={onConfirm}>{confirmTitle}</Button>
      </DialogActions>
    </Dialog>
  );
};
