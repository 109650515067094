import React from 'react';
import { Box, IconButton, IconButtonProps } from '@mui/material';
import VolumeUpRounded from '@mui/icons-material/VolumeUpRounded';
import VolumeDownRounded from '@mui/icons-material/VolumeDownRounded';
import VolumeOffRoundedIcon from '@mui/icons-material/VolumeOffRounded';
import { VolumeSlider } from './AudioVolumeSlider';

interface AudioVolumeButtonProps extends IconButtonProps {
  muted: boolean;
  amount: number;
  onChangeVolume: (value: number) => void;
}

export const AudioVolumeButton = ({
  muted,
  amount,
  onChangeVolume,
  ...rest
}: AudioVolumeButtonProps) => {
  return (
    <Box
      sx={{
        position: 'relative',
        '&:hover .volume-slider': { display: 'block' },
      }}
    >
      <IconButton {...rest}>
        {muted ? (
          <VolumeOffRoundedIcon />
        ) : amount > 0.5 ? (
          <VolumeUpRounded />
        ) : (
          <VolumeDownRounded />
        )}
      </IconButton>
      <VolumeSlider volumeAmount={amount} onSeek={onChangeVolume} />
    </Box>
  );
};
