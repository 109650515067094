import { ROUTES } from '../../consts';

export const navConfig = (projectId: number) => [
  {
    subheader: '',
    items: [
      {
        title: 'Introduction',
        path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.INTRO}`,
        children: [
          {
            title: 'Module Objectives',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.INTRO}/${ROUTES.MODULE_OBJECTIVES}`,
          },
          {
            title: 'Get Your Bearings',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.INTRO}/${ROUTES.GETTING_YOUR_BEARINGS}`,
          },
          {
            title: 'Roadmap 1',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.INTRO}/${ROUTES.THE_EVALUATION_ROADMAP}`,
          },
          {
            title: 'Diagrams 1',
            caption: 'A closer look at the diagrams you will create',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.INTRO}/${ROUTES.DIAGRAMS_YOU_WILL_CREATE_1}`,
            tooltip: true,
          },
          {
            title: 'Diagrams 2',
            caption: 'A closer look at the diagrams you will create',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.INTRO}/${ROUTES.DIAGRAMS_YOU_WILL_CREATE_2}`,
            tooltip: true,
          },
        ],
      },
      {
        title: 'Step 1. The Project Theory Diagram',
        path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_1}`,
        children: [
          {
            title: 'Project Profile',
            caption: 'Complete a project profile form',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_1}/${ROUTES.PROJECT_PROFILE}`,
          },
          {
            title: 'Before You Begin the Journey',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_1}/${ROUTES.PROJECT_THEORY_DIAGRAM_1}`,
          },
          {
            title: 'Basic components of the Project Theory Diagram',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_1}/${ROUTES.PROJECT_THEORY_DIAGRAM_2}`,
          },
          {
            title: 'Describe the Core Problem',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_1}/${ROUTES.CREATE_PROJECT_THEORY_DIAGRAM_1}`,
          },
          {
            title: 'Describe Community Need and Data Sources',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_1}/${ROUTES.CREATE_PROJECT_THEORY_DIAGRAM_2}`,
          },
          {
            title: 'List Influential Factors',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_1}/${ROUTES.CREATE_PROJECT_THEORY_DIAGRAM_3}`,
          },
          {
            title: 'List Interventions Strategies',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_1}/${ROUTES.CREATE_PROJECT_THEORY_DIAGRAM_4}`,
          },
          {
            title: 'Identify Desired Results',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_1}/${ROUTES.CREATE_PROJECT_THEORY_DIAGRAM_5}`,
          },
          {
            title: 'The Completed Project Theory Diagram',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_1}/${ROUTES.PROJECT_THEORY_DIAGRAM_FOR_PROJECT}`,
          },
        ],
      },
      {
        title: 'Step 2. The Project Flow Diagram',
        path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_2}`,
        children: [
          {
            title: 'The Evaluation Roadmap Step 2',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_2}/${ROUTES.YOUR_EVALUATION_ROADMAP2}`,
          },
          {
            title: 'Benefits of a project flow diagram',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_2}/${ROUTES.BENEFIT_OF_PORJECT_FLOW_DIAGRAM}`,
          },
          {
            title: 'Basic components of the Project Flow Diagram',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_2}/${ROUTES.CREATE_PROJECT_FLOW_DIAGRAM_1}`,
          },
          {
            title: 'The Project Flow Diagram from Right to Left',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_2}/${ROUTES.CREATE_PROJECT_FLOW_DIAGRAM_2}`,
          },
          {
            title: 'Describe the Project Participants',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_2}/${ROUTES.CREATE_PROJECT_FLOW_DIAGRAM_3}`,
          },
          {
            title: 'Describe the Project Activities',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_2}/${ROUTES.CREATE_PROJECT_FLOW_DIAGRAM_4}`,
          },
          {
            title: 'Describe the Project Resources',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_2}/${ROUTES.CREATE_PROJECT_FLOW_DIAGRAM_5}`,
          },
          {
            title: 'The Completed Project Flow Diagram',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_2}/${ROUTES.PROJECT_FLOW_DIAGRAM_FOR_PROJECT}`,
          },
        ],
      },
      {
        title: 'Step 3. The Project Evaluation Plan',
        path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}`,
        children: [
          {
            title: 'Step 3 - Design The Evaluation Plan',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.YOUR_EVALUATION_ROADMAP3}`,
          },
          {
            title: 'Basic Components of the Project Evaluation Plan',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.CREATE_PROJECT_EVALUATION_PLAN}`,
          },
          {
            title: 'Know the focus: Process and Outcome Evaluation',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.KNOW_YOUR_EVALUATION_FOCUS}`,
          },
          {
            title: 'Identifying Stakeholders for the Evaluation Plan',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.IDENITIFY_STAKEHOLDERS_1}`,
            tooltip: true,
          },
          {
            title: 'Identifying and Describing the Evaluation Stakeholders',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.IDENITIFY_STAKEHOLDERS_2}`,
            tooltip: true,
          },
          {
            title: 'Choose Questions',
            caption: 'Choose the right Evaluation Questions',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.CHOOSE_EVALUATION_QUESTIONS}`,
            tooltip: true,
          },
          {
            title: 'Align Questions',
            caption:
              'Alignment of evaluation questions with the project flow diagram',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.ALIGN_EVALUATION_QUESTIONS}`,
            tooltip: true,
          },
          {
            title: 'Process Questions',
            caption: 'Process the evaluation questions',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.PROCESS_EVALUATION_QUESTIONS}`,
          },
          {
            title: 'Do Evaluation Questions Align?',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.EVALUATION_PLAN_IN_PROGRESS_1}`,
          },
          {
            title: 'The Project Evaluation Plan in Progress',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.EVALUATION_PLAN_IN_PROGRESS_2}`,
          },
          {
            title: 'Deciding on Indicators of Change',
            caption: 'Deciding on Indicators of Change',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.DECIDING_ON_INDICATORS_OF_CHANGE}`,
          },
          {
            title: 'Two Rules of Thumb with Indicators',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.DECIDING_ON_INDICATORS_1}`,
          },
          {
            title: 'Draft the Indicators of Change',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.DECIDING_ON_INDICATORS_2}`,
          },
          {
            title: 'Questions to Answer When Thinking About Data',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.DATA_AND_DATA_COLLECTION_TIMING}`,
          },
          {
            title: 'An Example to Clarify Process Data Collection',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.OUTPUT_OR_PROCESS_DATA_EXAMPLE}`,
          },
          {
            title: 'Clarifying Outcome Data Collection',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.OUTCOME_DATA_EXAMPLE}`,
          },
          {
            title: 'Select the Data Measures and Timing',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.SELECT_YOUR_DATA_MEASURES_AND_TIMING}`,
            tooltip: true,
          },
          {
            title: 'The Project Evaluation Plan - one more step',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.EVALUATION_PLAN_IN_PROGRESS_4}`,
          },
          {
            title: 'Using Evaluation Findings',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.USING_EVALUATION_FINDINGS}`,
          },
          {
            title: 'The Completed Evaluation Plan',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.PROJECT_EVALUATION_PLAN_FOR_PROJECT}`,
          },
          {
            title: 'Next Steps',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.GOOD_WORK}`,
          },
          {
            title: 'Thank you',
            path: `/${ROUTES.PROJECTS}/${projectId}/${ROUTES.STEP_3}/${ROUTES.THANK_YOU}`,
          },
          {
            title: 'Evaluation Form',
            path: `/${ROUTES.EVALUATION_FORM}`,
          },
        ],
      },
    ],
  },
];
