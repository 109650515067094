import { Grid, Link } from '@mui/material';
import React from 'react';

export const DownloadExamples = () => {
  return (
    <Grid container>
      <Link
        sx={{ fontWeight: 500, textDecoration: 'none' }}
        target="_blank"
        href="https://evaluationco.org/ckfinder/userfiles/files/SampleIndicators.pdf"
      >
        Download this helpful table of example sample indicators.
      </Link>
    </Grid>
  );
};
