import React, { useState } from 'react';
import { Alert, Button, Grid, TextField } from '@mui/material';
import { useProjectContext } from '../contexts';
import { LoadingView } from '../components';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../consts';

export const ProjectProfileForm = () => {
  const {
    project,
    projectId,
    projectActions,
    loadings: { createProject: loading },
    errors: { createProject: error },
  } = useProjectContext();
  const navigate = useNavigate();
  const options = React.useMemo(
    () => [
      {
        value: 0,
        label: `State Tobacco Education and Prevention Partnership (STEPP)`,
      },
      {
        value: 1,
        label: `Cancer, Cardiovascular and Pulmonary Disease (CCPD)`,
      },
      { value: 2, label: `Office of Health Equity (OHE)` },
      { value: 3, label: `Other...` },
    ],
    []
  );

  const [name, setName] = useState('');

  React.useEffect(() => {
    if (!project) {
      return;
    }
    setName(project?.name || '');
  }, [options, project]);

  const onCreateProject = React.useCallback(async () => {
    if (!name?.trim()) {
      return;
    }
    if (!project?.id) {
      const newProject = await projectActions.createProject({ name });
      if (newProject) {
        navigate(
          `/${ROUTES.PROJECTS}/${newProject.id}/${ROUTES.STEP_1}/${ROUTES.PROJECT_THEORY_DIAGRAM_1}`
        );
      }
    } else {
      const updatedProject = await projectActions.updateProject({
        id: project.id,
        name,
      });
      if (updatedProject) {
        navigate(
          `/${ROUTES.PROJECTS}/${updatedProject.id}/${ROUTES.STEP_1}/${ROUTES.PROJECT_THEORY_DIAGRAM_1}`
        );
      }
    }
  }, [name, projectActions, navigate, project]);

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Project Name"
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </Grid>
      {!!error && (
        <Grid item xs={12}>
          <Alert variant="outlined" severity="error">
            {error?.message}
          </Alert>
        </Grid>
      )}
      {!!projectId && (
        <Grid item xs={12}>
          <Alert severity="info">
            If you chose the wrong project, click MY PROJECTS in the top bar
          </Alert>
        </Grid>
      )}

      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={onCreateProject}
          sx={{ textTransform: 'capitalize' }}
        >
          {projectId ? 'Continue' : 'Save'}
          <LoadingView visible={loading} />
        </Button>
      </Grid>
    </Grid>
  );
};
