import React from 'react';
import { EvaluationError, Parameters, Question, Settings } from '../@types';
import { AxiosPublicClient, Formatter, mapError } from '../utils';
import { CONTACT_EMAIL } from '../consts';

type SettingsContextType = {
  parameters: Parameters;
  questions: Question[];
  settings: Settings;
  updateContextValue?: (
    payload: Partial<Omit<SettingsContextType, 'updateContextValue'>>
  ) => void;
  loadings: {
    syncParameters: boolean;
    syncQuestions: boolean;
    syncSettings: boolean;
  };
  errors: {
    syncParameters?: EvaluationError;
    syncQuestions?: EvaluationError;
    syncSettings?: EvaluationError;
  };
};

const defaultSettingsContextValue: SettingsContextType = {
  parameters: {
    user_occupation: [],
    user_skill_level: [],
  },
  settings: {
    help_page_email: CONTACT_EMAIL,
    contact_page_email: CONTACT_EMAIL,
  },
  questions: [],
  updateContextValue: () => {},
  loadings: {
    syncParameters: false,
    syncQuestions: false,
    syncSettings: false,
  },
  errors: {
    syncParameters: undefined,
    syncQuestions: undefined,
    syncSettings: undefined,
  },
};

export const SettingsContext = React.createContext(defaultSettingsContextValue);

export const SettingsContextProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [contextValue, setContextValue] = React.useState<SettingsContextType>(
    defaultSettingsContextValue
  );

  const syncParameters = React.useCallback(async () => {
    try {
      setContextValue(prev => ({
        ...prev,
        loadings: { ...prev.loadings, syncParameters: true },
        error: { ...prev.errors, syncParameters: undefined },
      }));
      const { data } = await AxiosPublicClient.get(`/information/parameters`);
      setContextValue(prev => ({
        ...prev,
        parameters: Formatter.mapParameters(data),
        loadings: { ...prev.loadings, syncParameters: false },
      }));
    } catch (error) {
      setContextValue(prev => ({
        ...prev,
        error: { ...prev.errors, syncParameters: mapError(error) },
        loading: { ...prev.loadings, syncParameters: false },
      }));
    }
  }, []);

  const syncQuestions = React.useCallback(async () => {
    setContextValue(prev => ({
      ...prev,
      loadings: { ...prev.loadings, syncQuestions: true },
      error: { ...prev.errors, syncQuestions: undefined },
    }));
    try {
      const { data } = await AxiosPublicClient.get(`/information/questions`);
      setContextValue(prev => ({
        ...prev,
        questions: Formatter.mapQuestions(data),
        loadings: { ...prev.loadings, syncQuestions: false },
      }));
    } catch (error) {
      setContextValue(prev => ({
        ...prev,
        error: { ...prev.errors, syncQuestions: mapError(error) },
        loading: { ...prev.loadings, syncQuestions: false },
      }));
    }
  }, []);

  const syncSettings = React.useCallback(async () => {
    try {
      setContextValue(prev => ({
        ...prev,
        error: { ...prev.errors, syncSettings: undefined },
        loading: { ...prev.loadings, syncSettings: true },
      }));
      const { data } = await AxiosPublicClient.get(`/information/settings`);
      setContextValue(prev => ({
        ...prev,
        settings: data?.items,
        loadings: { ...prev.loadings, syncSettings: false },
      }));
    } catch (error) {
      setContextValue(prev => ({
        ...prev,
        error: { ...prev.errors, syncSettings: mapError(error) },
        loading: { ...prev.loadings, syncSettings: false },
      }));
    }
  }, []);

  React.useEffect(() => {
    syncParameters();
    syncQuestions();
    syncSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateContextValue = (
    payload: Partial<
      Omit<SettingsContextType, 'updateContextValue' | 'projectActions'>
    >
  ) => setContextValue(prev => ({ ...prev, ...payload }));

  return (
    <SettingsContext.Provider
      value={{
        ...contextValue,
        updateContextValue,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettingsContext = () => {
  return React.useContext(SettingsContext);
};
