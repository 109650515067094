import { Grid, Link, Typography } from '@mui/material';
import React from 'react';

export const GettingYourBearing = () => {
  return (
    <Grid container rowGap={1}>
      <Grid item xs={12}>
        <Typography sx={{ textDecoration: 'underline', fontWeight: 500 }}>
          DATA
        </Typography>
      </Grid>
      <Grid item xs={12} paddingLeft={1}>
        <Typography sx={{ fontWeight: 500 }}>
          • Colorado Data and Statistics:{' '}
          <Link
            target="_blank"
            href="https://cdphe.colorado.gov/colorado-data-and-statistics"
          >
            https://cdphe.colorado.gov/colorado-data-and-statistics
          </Link>
        </Typography>
        <Typography sx={{ fontWeight: 500 }}>
          • County Health Rankings:{' '}
          <Link
            target="_blank"
            href="https://www.countyhealthrankings.org/explore-health-rankings"
          >
            https://www.countyhealthrankings.org/explore-health-rankings
          </Link>
        </Typography>
        <Typography sx={{ fontWeight: 500 }}>
          • Colorado Health Access:{' '}
          <Link
            target="_blank"
            href="https://www.coloradohealthinstitute.org/programs/colorado-health-access-survey"
          >
            https://www.coloradohealthinstitute.org/programs/colorado-health-access-survey
          </Link>
        </Typography>
        <Typography sx={{ fontWeight: 500 }}>
          • Colorado Department of Local Affairs, Demography Office:{' '}
          <Link target="_blank" href="https://demography.dola.colorado.gov/">
            https://demography.dola.colorado.gov/
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ textDecoration: 'underline', fontWeight: 500 }}>
          BEST PRACTICE/EVIDENCE-BASED
        </Typography>
      </Grid>
      <Grid item xs={12} paddingLeft={1}>
        <Typography sx={{ fontWeight: 500 }}>• General Topics:</Typography>
        <Grid container paddingLeft={3}>
          <Typography sx={{ fontWeight: 500 }}>
            ○ The Guide to Community Prevention Services{' ('}
            <Link
              target="_blank"
              href="https://www.thecommunityguide.org/index.html"
            >
              The Guide to Community Preventive Services (The Community Guide)
            </Link>
            {')'}
          </Typography>
          <Typography sx={{ fontWeight: 500 }}>
            ○ National Guideline Clearinghouse{' '}
            <Link
              target="_blank"
              href="https://www.ahrq.gov/prevention/guidelines/index.html"
            >
              Clinical Guidelines and Recommendations | Agency for Healthcare
              Research and Quality (ahrq.gov)
            </Link>
          </Typography>
        </Grid>
        <Typography sx={{ fontWeight: 500 }}>• Tobacco</Typography>
        <Grid container paddingLeft={3}>
          <Typography sx={{ fontWeight: 500 }}>
            ○ Treating Tobacco Use and Dependence:{' '}
            <Link
              target="_blank"
              href="https://nida.nih.gov/publications/research-reports/tobacco-nicotine-e-cigarettes/what-are-treatments-tobacco-dependence"
            >
              https://nida.nih.gov/publications/research-reports/tobacco-nicotine-e-cigarettes/what-are-treatments-tobacco-dependence
            </Link>
          </Typography>
          <Typography sx={{ fontWeight: 500 }}>
            ○ Evidence-based resources related to Tobacco Use:{' '}
            <Link
              target="_blank"
              href="https://health.gov/healthypeople/objectives-and-data/browse-objectives/tobacco-use/evidence-based-resources"
            >
              https://health.gov/healthypeople/objectives-and-data/browse-objectives/tobacco-use/evidence-based-resources
            </Link>
          </Typography>
        </Grid>
        <Typography sx={{ fontWeight: 500 }}>
          • Chronic Disease Prevention
        </Typography>
        <Grid container paddingLeft={3}>
          <Typography sx={{ fontWeight: 500 }}>
            ○ American Heart Association:{' '}
            <Link
              target="_blank"
              href="https://professional.heart.org/en/science-news/2022-guideline-for-the-management-of-heart-failure"
            >
              2022 AHA/ACC/HFSA Guideline for the Management of Heart Failure -
              Professional Heart Daily | American Heart Association
            </Link>
          </Typography>
          <Typography sx={{ fontWeight: 500 }}>
            ○ Hypertension Clinical Guidelines:{' '}
            <Link
              target="_blank"
              href="https://jamanetwork.com/channels/hypertension/pages/jnc8"
            >
              Hypertension Guidelines: 2021 USPSTF Recommendation, 2018 ESC/ESH,
              2017 ACC/AHA, and the 2014 JNC8 guideline | Hypertension | JAMA
              Network
            </Link>
          </Typography>
          <Typography sx={{ fontWeight: 500 }}>
            ○ Million Hearts Toolkit:{' '}
            <Link
              target="_blank"
              href="https://millionhearts.hhs.gov/tools-protocols/tools.html"
            >
              Tools | Million Hearts® (hhs.gov)
            </Link>
          </Typography>
          <Typography sx={{ fontWeight: 500 }}>
            ○ Diabetes Standards of Care:{' '}
            <Link
              target="_blank"
              href="https://professional.diabetes.org/content-page/practice-guidelines-resources"
            >
              Practice Guidelines Resources | American Diabetes Association
            </Link>
          </Typography>
          <Typography sx={{ fontWeight: 500 }}>
            ○ National Diabetes Prevention Programs:{' '}
            <Link
              target="_blank"
              href="https://www.niddk.nih.gov/health-information/community-health-outreach/information-clearinghouses?dkrd=lgdmw0001"
            >
              Clearinghouses & Health Information Center - NIDDK (nih.gov)
            </Link>
          </Typography>
          <Typography sx={{ fontWeight: 500 }}>
            ○ Asthma Management Guidelines:{' '}
            <Link
              target="_blank"
              href="https://www.nhlbi.nih.gov/health-topics/asthma-management-guidelines-2020-updates"
            >
              2020 Focused Updates to the Asthma Management Guidelines | NHLBI,
              NIH
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
