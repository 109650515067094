import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  getAudioFile,
  getCurrentRoute,
  getNextRoute,
  getPrevRoute,
  getScriptContent,
  isInFooterBlackListPath,
  isInIntroPath,
} from '../consts';
import { ScriptModal, SourceModal } from '../components';
import { getSourceContent } from '../consts/source.const';
import { useMainLayoutContext } from '../layouts/MainLayoutContext';
import { Toast, validateProjectStepFields } from '../utils';

type ProjectStepContextType = {
  showStepBar: boolean;
  showScriptModal: boolean;
  showSourceModal: boolean;
  showNarration: boolean;
  hideNextButton?: boolean;
  hidePrevButton?: boolean;
  audioUrl?: string;
  audioPaused?: boolean;
  scriptContent?: string;
  sourceContent?: React.ReactNode;
  onNext: () => void;
  onPrev: () => void;
  updateContextValue: (
    payload: Partial<Omit<ProjectStepContextType, 'updateContextValue'>>
  ) => void;
};

const defaultProjectStepContextValue: ProjectStepContextType = {
  showStepBar: false,
  showScriptModal: false,
  showSourceModal: false,
  showNarration: false,
  scriptContent: '',
  sourceContent: undefined,
  audioPaused: false,
  onNext: () => {},
  onPrev: () => {},
  updateContextValue: () => {},
};

export const ProjectStepContext = React.createContext(
  defaultProjectStepContextValue
);

export const ProjectStepContextProvider = ({
  children,
}: React.PropsWithChildren) => {
  const { id: projectId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { sharedProject: currentProject } = useMainLayoutContext();
  const [contextValue, setContextValue] =
    React.useState<ProjectStepContextType>(defaultProjectStepContextValue);

  const updateContextValue = (
    payload: Partial<Omit<ProjectStepContextType, 'updateContextValue'>>
  ) => setContextValue(prev => ({ ...prev, ...payload }));

  React.useEffect(() => {
    if (!location?.pathname) {
      return;
    }
    const showStepBar =
      isInIntroPath(location?.pathname) ||
      (!!projectId && !isInFooterBlackListPath(projectId, location.pathname));
    setContextValue(prev => ({
      ...prev,
      showStepBar,
    }));
  }, [projectId, location?.pathname]);

  React.useEffect(() => {
    const script = getScriptContent(location?.pathname);
    const source = getSourceContent(location?.pathname);
    setContextValue(prev => ({
      ...prev,
      scriptContent: script,
      sourceContent: source,
    }));
  }, [location?.pathname]);

  React.useEffect(() => {
    const audio = getAudioFile(location?.pathname, !!projectId);
    setContextValue(prev => ({ ...prev, audioUrl: undefined }));
    setTimeout(() => {
      setContextValue(prev => ({ ...prev, audioUrl: audio }));
    }, 10);
  }, [location?.pathname, projectId]);

  const onNext = React.useCallback(() => {
    const nextRoute = getNextRoute(Number(projectId!), location?.pathname!);
    if (nextRoute) {
      if (
        validateProjectStepFields(
          getCurrentRoute(Number(projectId), location?.pathname) || '',
          currentProject!
        )
      ) {
        navigate(nextRoute);
      } else {
        Toast.showErrorMessage('Please fill the required fields.');
        return;
      }
    } else {
      // navigate next
    }
  }, [location?.pathname, navigate, projectId, currentProject]);

  const onPrev = React.useCallback(() => {
    const prevRoute = getPrevRoute(Number(projectId!), location?.pathname!);
    if (prevRoute) {
      navigate(prevRoute);
    } else {
      // navigate to home or landing
    }
  }, [location?.pathname, navigate, projectId]);

  const hideNextButton = React.useMemo(() => {
    return !getNextRoute(Number(projectId!), location?.pathname);
  }, [projectId, location?.pathname]);

  const hidePrevButton = React.useMemo(() => {
    return !getPrevRoute(Number(projectId!), location?.pathname);
  }, [projectId, location?.pathname]);

  return (
    <ProjectStepContext.Provider
      value={{
        ...contextValue,
        hideNextButton,
        hidePrevButton,
        updateContextValue,
        onNext,
        onPrev,
      }}
    >
      {contextValue.showStepBar ? children : null}
      <ScriptModal
        visible={contextValue.showScriptModal}
        content={contextValue.scriptContent}
        onClose={() =>
          setContextValue(prev => ({ ...prev, showScriptModal: false }))
        }
      />
      <SourceModal
        visible={contextValue.showSourceModal}
        content={contextValue.sourceContent}
        onClose={() =>
          setContextValue(prev => ({ ...prev, showSourceModal: false }))
        }
      />
    </ProjectStepContext.Provider>
  );
};

export const useProjectStepContext = () => {
  return React.useContext(ProjectStepContext);
};
