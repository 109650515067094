import React from 'react';
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  LinearProgress,
  Toolbar,
} from '@mui/material';
import { Outlet } from 'react-router-dom';

import bodyBgJpeg from '../assets/images/jpegs/body_bg.jpeg';
import { Header } from './Header';
import { HEADER_HEIGHT } from './consts';
import { BannerContainer } from './BannerContainer';
import { PageContentContainer } from './PageContentContainer';
import {
  MainLayoutContext,
  MainLayoutContextProvider,
} from './MainLayoutContext';
import { PageTitle } from '../components';
import { ProjectStepFooter } from './ProjectStepFooter';
import { ProjectStepContextProvider } from '../contexts';

export const MainLayout = () => {
  return (
    <MainLayoutContextProvider>
      <MainLayoutContext.Consumer>
        {({ title, loading, updatingProject, pageContentRef }) => (
          <Box
            sx={{
              backgroundImage: `url(${bodyBgJpeg})`,
              backgroundRepeat: 'repeat',
            }}
          >
            <Header />

            <Container
              sx={{
                minHeight: '100vh',
                bgcolor: 'white',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
              }}
            >
              <Toolbar
                sx={{ height: HEADER_HEIGHT, minHeight: { xs: 'unset' } }}
              />

              <BannerContainer />
              {!!updatingProject && (
                <LinearProgress sx={{ mx: { xs: -2, sm: -3 } }} />
              )}

              <PageContentContainer>
                <Grid container spacing={4} ref={pageContentRef}>
                  {!!title && (
                    <Grid item xs={12}>
                      <PageTitle title={title} />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Outlet />
                  </Grid>
                </Grid>
              </PageContentContainer>
              <ProjectStepContextProvider>
                <ProjectStepFooter />
              </ProjectStepContextProvider>
              {loading && (
                <Box
                  sx={{
                    position: 'absolute',
                    inset: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 100000,
                    backgroundColor: '#00000020',
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </Container>
          </Box>
        )}
      </MainLayoutContext.Consumer>
    </MainLayoutContextProvider>
  );
};
