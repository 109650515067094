import React, { useEffect } from 'react';
import { Grid, Link } from '@mui/material';

import { useMainLayoutContext } from '../layouts/MainLayoutContext';
import { GTypography } from '../components';

export const TaContactPage = () => {
  const { updateContextValue } = useMainLayoutContext();
  useEffect(() => {
    updateContextValue({ title: 'Technical Assistance Contacts' });

    return () => {
      updateContextValue({ title: undefined });
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={12}>
          <GTypography color="textSecondary" lineHeight={2} fontWeight={500}>
            For STEPP grantees:{' '}
            <Link
              component="a"
              underline="hover"
              href={`mailto:EvaluationTA@UCDenver.edu`}
              sx={{ fontWeight: 600, cursor: 'pointer' }}
            >
              EvaluationTA@UCDenver.edu
            </Link>
          </GTypography>
        </Grid>
        <Grid item xs={12}>
          <GTypography color="textSecondary" lineHeight={2} fontWeight={500}>
            For CCPD grantees:{' '}
            <Link
              component="a"
              underline="hover"
              href={`mailto:CEPEGEvaluationTA@CUAnschutz.edu`}
              sx={{ fontWeight: 600, cursor: 'pointer' }}
            >
              CEPEGEvaluationTA@CUAnschutz.edu
            </Link>
          </GTypography>
        </Grid>
        <Grid item xs={12}>
          <GTypography color="textSecondary" lineHeight={2} fontWeight={500}>
            {`You will receive a response within 3 business days.`}
          </GTypography>
        </Grid>
      </Grid>
    </Grid>
  );
};
