import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Alert, Fade, Snackbar } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import {
  EvaluationError,
  Project,
  ProjectCreatePayload,
  ProjectUpdatePayload,
} from '../@types';
import { AxiosClient, mapError } from '../utils';
import { useAuth } from './AuthContext';
import { useMainLayoutContext } from '../layouts/MainLayoutContext';

type Transition = React.ComponentType<
  TransitionProps & {
    children: React.ReactElement<any, any>;
  }
>;

type ProjectContextType = {
  projectId?: number;
  project?: Project;
  projects: Project[];
  updateContextValue?: (
    payload: Partial<
      Omit<ProjectContextType, 'updateContextValue' | 'projectActions'>
    >
  ) => void;
  projectActions: {
    createProject: (
      payload: ProjectCreatePayload
    ) => Promise<Project | undefined>;
    updateProject: (
      payload: ProjectUpdatePayload
    ) => Promise<Project | undefined>;
    deleteProject: (id: number) => Promise<void>;
  };
  loadings: {
    createProject: boolean;
    updateProject: boolean;
    deleteProject: boolean;
    loadProjects: boolean;
    loadProject: boolean;
  };
  errors: {
    createProject?: EvaluationError;
    updateProject?: EvaluationError;
    deleteProject?: EvaluationError;
  };
  snackBar: {
    visible: boolean;
    message: string;
    transition: Transition;
  };
};

const defaultProjectContextValue: ProjectContextType = {
  projectId: undefined,
  project: undefined,
  projects: [],
  updateContextValue: () => {},
  projectActions: {
    createProject: async () => undefined,
    updateProject: async () => undefined,
    deleteProject: async () => undefined,
  },
  loadings: {
    createProject: false,
    updateProject: false,
    deleteProject: false,
    loadProjects: false,
    loadProject: false,
  },
  errors: {
    createProject: undefined,
    updateProject: undefined,
    deleteProject: undefined,
  },
  snackBar: {
    visible: false,
    message: '',
    transition: Fade,
  },
};

export const ProjectContext = React.createContext(defaultProjectContextValue);

export const ProjectContextProvider = ({
  children,
}: React.PropsWithChildren) => {
  const { id: projectId } = useParams();
  const location = useLocation();
  const { user } = useAuth();
  const [contextValue, setContextValue] = React.useState<ProjectContextType>(
    defaultProjectContextValue
  );
  const { updateContextValue: updateMainLayout } = useMainLayoutContext();

  const syncProject = React.useCallback(async (projectId: number) => {
    try {
      setContextValue(prev => ({
        ...prev,
        loadings: { ...prev.loadings, loadProject: true },
      }));
      updateMainLayout({ updatingProject: true });
      const { data } = await AxiosClient.get(`/projects/${projectId}`);
      setContextValue(prev => ({
        ...prev,
        project: data,
        loadings: { ...prev.loadings, loadProject: false },
      }));
      updateMainLayout({ updatingProject: false });
    } catch (error) {
      setContextValue(prev => ({
        ...prev,
        loadings: { ...prev.loadings, loadProject: false },
      }));
      updateMainLayout({ updatingProject: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadProjects = React.useCallback(async () => {
    if (user?.id) {
      try {
        setContextValue(prev => ({
          ...prev,
          loadings: { ...prev.loadings, loadProjects: true },
        }));
        const { data } = await AxiosClient.get('/projects');
        setContextValue(prev => ({
          ...prev,
          projects: data.map((item: Project) => ({
            ...item,
            funding_source: item?.funding_source || 'Default',
          })),
          loadings: { ...prev.loadings, loadProjects: false },
        }));
      } catch (error) {
        setContextValue(prev => ({
          ...prev,
          loadings: { ...prev.loadings, loadProjects: false },
        }));
      }
    }
  }, [user?.id]);

  React.useEffect(() => {
    loadProjects();
  }, [loadProjects]);

  React.useEffect(() => {
    updateMainLayout({ sharedProject: contextValue?.project });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextValue?.project]);

  React.useEffect(() => {
    if (projectId) {
      syncProject(Number(projectId));
    } else {
      setContextValue(prev => ({ ...prev, project: undefined }));
    }
  }, [syncProject, projectId]);

  const createProject = React.useCallback(
    async (payload: ProjectCreatePayload) => {
      setContextValue(prev => ({
        ...prev,
        loadings: { ...prev.loadings, createProject: true },
        errors: defaultProjectContextValue.errors,
      }));
      try {
        const { data } = await AxiosClient.post('/projects', payload);
        setContextValue(prev => ({
          ...prev,
          loadings: { ...prev.loadings, createProject: false },
          project: data,
        }));
        loadProjects();
        return data;
      } catch (error) {
        setContextValue(prev => ({
          ...prev,
          loadings: { ...prev.loadings, createProject: false },
          errors: { ...prev.errors, createProject: mapError(error) },
        }));
        return undefined;
      }
    },
    [loadProjects]
  );

  const updateProject = React.useCallback(
    async (payload: ProjectUpdatePayload) => {
      setContextValue(prev => ({
        ...prev,
        loadings: { ...prev.loadings, updateProject: true },
        errors: defaultProjectContextValue.errors,
      }));
      try {
        updateMainLayout({ updatingProject: true });
        const { data } = await AxiosClient.put(
          `/projects/${payload.id}`,
          payload
        );
        setContextValue(prev => ({
          ...prev,
          loadings: { ...prev.loadings, updateProject: false },
          project: data,
          snackBar: {
            ...prev.snackBar,
            visible: true,
            message: 'Project updated',
          },
        }));
        updateMainLayout({ updatingProject: false });
        return data;
      } catch (error) {
        setContextValue(prev => ({
          ...prev,
          loadings: { ...prev.loadings, updateProject: false },
          errors: { ...prev.errors, updateProject: mapError(error) },
        }));
        updateMainLayout({ updatingProject: false });
        return undefined;
      }
    },
    [updateMainLayout]
  );

  const deleteProject = React.useCallback(async (id: number) => {
    setContextValue(prev => ({
      ...prev,
      loadings: { ...prev.loadings, deleteProject: true },
      errors: defaultProjectContextValue.errors,
    }));
    try {
      await AxiosClient.delete(`/projects/${id}`);
      setContextValue(prev => ({
        ...prev,
        loadings: { ...prev.loadings, deleteProject: false },
        projects: prev.projects?.filter(item => item.id !== id),
      }));
    } catch (error) {
      setContextValue(prev => ({
        ...prev,
        loadings: { ...prev.loadings, deleteProject: false },
        errors: { ...prev.errors, deleteProject: mapError(error) },
      }));
      return undefined;
    }
  }, []);

  const updateContextValue = (
    payload: Partial<
      Omit<ProjectContextType, 'updateContextValue' | 'projectActions'>
    >
  ) => setContextValue(prev => ({ ...prev, ...payload }));

  React.useEffect(() => {
    if (projectId && user && location.pathname) {
      AxiosClient.post('/profile/last-visit', {
        last_visit_url: location.pathname,
      });
    }
  }, [projectId, user, location]);

  return (
    <ProjectContext.Provider
      value={{
        ...contextValue,
        projectId: Number(projectId),
        updateContextValue,
        projectActions: { createProject, updateProject, deleteProject },
      }}
    >
      {children}
      <Snackbar
        open={contextValue.snackBar.visible}
        onClose={() =>
          setContextValue(prev => ({
            ...prev,
            snackBar: { ...prev.snackBar, visible: false },
          }))
        }
        TransitionComponent={contextValue.snackBar.transition}
        message={contextValue.snackBar.message}
        key={contextValue.snackBar.transition.displayName}
        autoHideDuration={2000}
      >
        <Alert
          variant="filled"
          severity="success"
          sx={{ width: '100%', backgroundColor: 'primary.main' }}
        >
          {contextValue.snackBar.message}
        </Alert>
      </Snackbar>
    </ProjectContext.Provider>
  );
};

export const useProjectContext = () => {
  return React.useContext(ProjectContext);
};
