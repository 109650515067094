import { Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useMainLayoutContext } from '../../../layouts/MainLayoutContext';
import { useProjectContext } from '../../../contexts';
import { EVALUATION_FINDINGS } from '../../../consts';
import { ContentMultiOptions } from '../../../components';

export const Step3UsingEvaluationFindingsPage = () => {
  const theme = useTheme();
  const { updateContextValue } = useMainLayoutContext();
  const {
    project,
    projectActions: { updateProject },
  } = useProjectContext();

  React.useEffect(() => {
    updateContextValue({
      title: 'Using Evaluation Findings',
      pageContainerSx: { maxWidth: theme.breakpoints.values.md },
    });

    return () => {
      updateContextValue({ title: undefined, pageContainerSx: undefined });
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography sx={{ color: '#66cc00', fontWeight: 600 }}>
          Select up to three ways you will use evaluation findings?
        </Typography>
      </Grid>
      <Grid item container sx={{ mt: 4 }}>
        <Grid item xs={12} bgcolor="lightgray" sx={{ p: 1 }}>
          <Typography>Using Evaluation Findings</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            borderStyle: 'solid',
            borderColor: 'gray',
            borderWidth: 2,
            px: 1,
          }}
        >
          <ContentMultiOptions
            list={EVALUATION_FINDINGS}
            maxLimit={3}
            selectedOptions={project?.evaluation_findings || []}
            onChange={values =>
              updateProject({
                id: project?.id,
                evaluation_findings: values,
              })
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
